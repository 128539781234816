import React from "react";

import { ArrowFirst, ArrowLast, ArrowLeft, ArrowRight } from "@jsluna/icons";

class PagedTable extends React.Component {
  render() {
    const { pagingNavigator, page, items, totalPages } = this.props;

    return (
      <div>
        <ArrowFirst
          size="large"
          name="fast backward"
          onClick={() => pagingNavigator(-page)}
          disabled={!!!page}
        />
        <ArrowLeft
          size="large"
          name="backward"
          onClick={() => pagingNavigator(-1)}
          disabled={!!!page}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {!!items.length && (
          <span>
            Page {page + 1} of {totalPages}{" "}
          </span>
        )}
        <ArrowRight
          size="large"
          name="forward"
          onClick={() => pagingNavigator(1)}
          disabled={totalPages <= page + 1}
          style={{ marginLeft: "20px" }}
        />
        <ArrowLast
          size="large"
          name="fast forward"
          onClick={() => pagingNavigator(totalPages - (page + 1))}
          disabled={totalPages <= page + 1}
          style={{ marginRight: "20px" }}
        />
        <div style={{ float: "right" }}>{this.props.children[0]}</div>
        {this.props.children.slice(1)}
      </div>
    );
  }
}

export default PagedTable;
