import { PropTypes } from "prop-types";
import React from "react";

import ProductRow from "./ProductRow";

export class ProductTableView extends React.Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    onProductSelect: PropTypes.func.isRequired,
  };

  handleOnProductSelected = (product) => {
    this.props.onProductSelect(product);
  };

  productSelected = (prod) => {
    for (var x of this.props.selectedProducts) {
      if (x.ean === prod.ean && x.sku === prod.sku) return true;
    }
    return false;
  };

  render() {
    const tableBody = this.props.products.map((product, i) => {
      return (
        <ProductRow
          key={i}
          product={product}
          onProductSelected={this.handleOnProductSelected}
          isSelected={this.productSelected(product)}
        />
      );
    });

    return (
      <div class="ln-c-table-container ln-u-margin-top*4">
        <table class="ln-c-table">
          <thead class="ln-c-table__header">
            <tr class="ln-c-table__row ln-c-table__header-row">
              <th class="ln-c-table__header-cell customCol">Select</th>
              <th class="ln-c-table__header-cell customCol">Ean Description</th>
              <th class="ln-c-table__header-cell customCol">SKU</th>
              <th class="ln-c-table__header-cell customCol">Super Category</th>
              <th class="ln-c-table__header-cell customCol">Category</th>
              <th class="ln-c-table__header-cell customCol">Sub Category</th>
              <th class="ln-c-table__header-cell customCol">Segment</th>
            </tr>
          </thead>
          <tbody class="ln-c-table__body">{tableBody}</tbody>
        </table>
      </div>
    );
  }
}

export default ProductTableView;
