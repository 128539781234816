import { useEffect, useState } from "react";

export const useCampaignsListSelection = (
  items,
  idSelector,
  prevData,
  setData,
  requestedPage,
  clearData
) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [noneSelected, setNoneSelected] = useState();

  const selectedItems = (items ?? []).filter((item) =>
    prevData.includes(idSelector(item))
  );

  useEffect(() => {
    setNoneSelected(true);
    setSelectedIds(prevData);
    // eslint-disable-next-line
  }, [requestedPage]);

  const resetSelection = () => {
    setSelectedIds([]);
    clearData();
    setNoneSelected(true);
  };

  const setSelectedData = (newData) => {
    let selectedItemsArr = [...prevData, ...newData];
    setData(selectedItemsArr);
  };

  const removeSelectedData = (newData) => {
    let updatedArray = prevData.filter((item) => !newData.includes(item));
    setData(updatedArray);
  };

  const selectAll = () => {
    if (!noneSelected) {
      setSelectedIds([]);
      removeSelectedData(items.map(idSelector));
      setNoneSelected(true);
    } else {
      setSelectedIds(items.map(idSelector));
      setSelectedData(items.map(idSelector));
      setNoneSelected(false);
    }
  };

  const selectItem = (item) => {
    const itemId = idSelector(item);
    const index = selectedIds.findIndex((row) => row === itemId);
    if (index === -1) {
      setSelectedIds([...selectedIds, itemId]);
      setData([...prevData, itemId]);
      setNoneSelected(false);
    } else {
      setSelectedIds(selectedIds.filter((row) => row !== itemId));
      setData(prevData.filter((row) => row !== itemId));
      setNoneSelected(true);
    }
  };

  const itemSelected = (item) =>
    selectedIds.some((id) => id === idSelector(item));
  const allSelected = items.every((item) =>
    selectedIds.includes(idSelector(item))
  );

  return {
    selectedIds,
    selectedItems,
    resetSelection,
    selectAll,
    selectItem,
    itemSelected,
    noneSelected,
    allSelected,
    setSelectedIds,
  };
};
