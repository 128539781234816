import { toast } from "react-toastify";

export const ErrorToastNotification = (error) =>
  toast.error(error, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 7500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
