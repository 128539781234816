import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { DisplayType } from "../OfferCode";

export const OfferCodeDisplayTypeSelect = ({ offerCode, handleChange }) => (
  <GridWrapper>
    <GridItem style={{ marginTop: "1rem" }}>
      <Label>Display Type</Label>
      <Form.Select
        style={{
          width: "100%",
          backgroundColor: "transparent",
          paddingTop: "16px",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #dedfe0",
          borderRadius: "2px",
          minHeight: "48px",
        }}
        value={offerCode ? offerCode.displayType : "NORMAL" || ""}
        name="displayType"
        options={DisplayType}
        placeholder="display type"
        type="text"
        onChange={handleChange}
        required
      />
    </GridItem>
  </GridWrapper>
);
