import React from "react";
import { DateTimePicker } from "react-widgets";

import { FormGroup } from "@jsluna/form";

import { toApiFormat } from "../../dates";
import "./DateTimeField.scss";

const DateTimeField = ({
  value,
  onChange,
  className,
  element,
  error,
  hideLabel,
  info,
  label,
  labelElement,
  name,
  optional,
  required,
  validationFirst,
  warning,
  defaultTime = "23:59:59",
  ...rest
}) => {
  const handleDateTimeChange = (newValue) => {
    if (newValue == null) {
      onChange(null);
      return;
    }
    if (!value && defaultTime) {
      const parts = defaultTime.split(":");
      if (parts.length > 0) newValue.setHours(parts[0]);
      if (parts.length > 1) newValue.setMinutes(parts[1]);
      if (parts.length > 2) newValue.setSeconds(parts[2]);
    }
    onChange(toApiFormat(newValue));
  };

  return (
    <FormGroup
      className={className}
      element={element}
      error={error}
      hideLabel={hideLabel}
      info={info}
      label={label}
      labelElement={labelElement}
      name={name + "_input"} // The child component adds this suffix to the ID we pass
      optional={optional}
      required={required}
      validationFirst={validationFirst}
      warning={warning}
    >
      {(inputProps) => (
        <DateTimePicker
          {...inputProps}
          id={name}
          name={name}
          value={value ? new Date(value) : null}
          onChange={handleDateTimeChange}
          containerClassName="management_portal_date_time_field"
          {...rest}
        />
      )}
    </FormGroup>
  );
};

export default DateTimeField;
