import React from "react";
import { toast } from "react-toastify";

import { OutlinedButton } from "@jsluna/button";
import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";

import OfferAllocateClient from "../../services/OfferAllocateClient";

const client = new OfferAllocateClient();

class NectarPricesOfferAllocateView extends React.Component {
  state = {
    externalHandle: this.props.eHandle ? this.props.eHandle : "",
    campaignId: "",
    fromPrice: "",
    nectarPrice: "",
    startDate: "",
    endDate: "",
  };
  handleExtHandleChange = (e) => {
    this.setState({ externalHandle: e.target.value });
  };

  handleCampaignIdChange = (e) => {
    this.setState({ campaignId: e.target.value });
  };

  handleFromPriceChange = (e) => {
    this.setState({ fromPrice: e.target.value });
  };

  handleNectarPriceChange = (e) => {
    this.setState({ nectarPrice: e.target.value });
  };

  handleStartDateChange = (e) => {
    this.setState({ startDate: e.target.value });
  };

  handleEndDateChange = (e) => {
    this.setState({ endDate: e.target.value });
  };

  submitZ = () => {
    client
      .allocate({
        externalHandle: this.state.externalHandle,
        pianoCampaignId: this.state.campaignId,
        state: "UNLOADED",
        offerType: "PRICES",
        fromPrice: this.state.fromPrice,
        nectarPrice: this.state.nectarPrice,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(() => toast.success("Done."))
      .catch((e) => toast.error(e?.response?.data?.description));
  };

  isButtonEnabled = () => {
    let enable = true;
    if (
      this.state.campaignId !== "" &&
      this.state.externalHandle !== "" &&
      this.state.fromPrice !== "" &&
      this.state.nectarPrice !== "" &&
      this.state.startDate !== "" &&
      this.state.endDate !== ""
    ) {
      enable = false;
    }
    return enable;
  };

  render() {
    const { eHandle } = this.props;
    return (
      <Card>
        <GridWrapper>
          <GridItem>
            <div class="ln-c-form-group">
              <label for="text-input-field-1" class="ln-c-label">
                External Handle
              </label>
              <div class="ln-c-input-group">
                <input
                  id="text-input-field-1"
                  name="externalHandle"
                  type="text"
                  class="ln-c-text-input ln-c-input-group__control"
                  value={eHandle ? eHandle : this.state.externalHandle}
                  onChange={this.handleExtHandleChange}
                />
              </div>
            </div>
          </GridItem>
          <GridItem>
            <div class="ln-c-form-group">
              <label for="text-input-field-1" class="ln-c-label">
                Piano Campaign Id (UUID)
              </label>
              <div class="ln-c-input-group">
                <input
                  id="text-input-field-1"
                  name="campaignId"
                  type="text"
                  class="ln-c-text-input ln-c-input-group__control"
                  value={this.state.campaignId}
                  onChange={this.handleCampaignIdChange}
                />
              </div>
            </div>
          </GridItem>
          <GridItem>
            <div class="ln-c-form-group">
              <label for="text-input-field-1" class="ln-c-label">
                Regular Price (in pence)
              </label>
              <div class="ln-c-input-group">
                <input
                  id="text-input-field-1"
                  name="fromPrice"
                  type="text"
                  class="ln-c-text-input ln-c-input-group__control"
                  value={this.state.fromPrice}
                  onChange={this.handleFromPriceChange}
                />
              </div>
            </div>
          </GridItem>
          <GridItem>
            <div class="ln-c-form-group">
              <label for="text-input-field-1" class="ln-c-label">
                Nectar Price (in pence)
              </label>
              <div class="ln-c-input-group">
                <input
                  id="text-input-field-1"
                  name="nectarPrice"
                  type="text"
                  class="ln-c-text-input ln-c-input-group__control"
                  value={this.state.nectarPrice}
                  onChange={this.handleNectarPriceChange}
                />
              </div>
            </div>
          </GridItem>
          <GridItem>
            <div class="ln-c-form-group">
              <label for="text-input-field-1" class="ln-c-label">
                Start Date (E.g : 2021-12-22T00:00:01+01:00)
              </label>
              <div class="ln-c-input-group">
                <input
                  id="text-input-field-1"
                  name="startDate"
                  type="text"
                  class="ln-c-text-input ln-c-input-group__control"
                  value={this.state.startDate}
                  onChange={this.handleStartDateChange}
                />
              </div>
            </div>
          </GridItem>

          <GridItem>
            <div class="ln-c-form-group">
              <label for="text-input-field-1" class="ln-c-label">
                End Date (E.g : 2021-12-22T00:00:01+01:00)
              </label>
              <div class="ln-c-input-group">
                <input
                  id="text-input-field-1"
                  name="endDate"
                  type="text"
                  class="ln-c-text-input ln-c-input-group__control"
                  value={this.state.endDate}
                  onChange={this.handleEndDateChange}
                />
              </div>
            </div>
          </GridItem>

          <GridItem>
            <OutlinedButton
              type="button"
              size="small"
              onClick={this.submitZ}
              disabled={this.isButtonEnabled()}
            >
              Submit
            </OutlinedButton>
          </GridItem>
        </GridWrapper>
      </Card>
    );
  }
}

export default NectarPricesOfferAllocateView;
