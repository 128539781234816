export const faqs = {
  faq: [
    {
      question: "What is Management Portal RBAC?",
      answer:
        "The Management Portal, initially created during a Tech Hackathon, has gained widespread use across various internal teams at Nectar, including marketing, engineering, and campaign delivery. Due to its rapid adoption and lack of centralized management, the portal currently faces challenges with secure access controls. We are implementing Role-Based Access Control (RBAC) to establish clear user permissions, enhance security, and ensure that access aligns with users' responsibilities. This will lead to improved operational efficiency and better tracking of user activity within the platform.",
    },
    {
      question: "How do I request access?",
      answer: `To request access, please submit a service request with a business justification using <a target="_blank" rel="noopener noreferrer" href="https://sainsburys.service-now.com/assist?id=sc_cat_item&table=sc_cat_item&sys_id=e35fc7f01b7d2d54e43040c3b24bcb7a" style="font-weight: bold; text-decoration: underline; color: #f06c02;" data-testid="service-request-link">this link</a>.`,
    },
    {
      question: "What happens after I submit a request?",
      answer:
        "Once your request is submitted, it requires C6 approval. Upon approval, you will be added to user groups with defined roles, which will determine the menus and features you can access in the app.",
    },
    {
      question: "What roles are available in the Management Portal?",
      answer:
        "The following roles are available: <ul><li>CustomerDataViewer</li><li>MarketingEventEditor</li><li>CampaignEditor</li><li>OfferAllocator</li><li>OfferCodeEditor</li><li>HomepagePodEditor</li></ul> ",
    },
    {
      question: "Can I have multiple roles assigned to my account?",
      answer:
        "Yes, based on your business justification, the role manager can grant you multiple roles, providing access to various functionalities within the app.",
    },
    {
      question: "Where can I find the user roles definitions and capabilities?",
      answer: `You can find the definitions and capabilities of user roles at the following Confluence link: <a target="_blank" rel="noopener noreferrer" href="https://sainsburys-tech.atlassian.net/wiki/spaces/CM1/pages/545882322/M.P-+Roles+Permissions#1.-Overview-[inlineExtension]" style="font-weight: bold; text-decoration: underline; color: #f06c02;" data-testid="service-request-link">this link</a>.`,
    },
    {
      question: "Who should I contact if I have questions?",
      answer:
        "For any queries, please reach out to the Campaign Management Team via email [nectar_campaigneng@sainsburys.co.uk]. A developer will be available to assist you.",
    },
    {
      question:
        "What should I do if I encounter an error with any menu or its functions?",
      answer:
        "If you experience any issues, please contact the Campaign Management Team via email [nectar_campaigneng@sainsburys.co.uk]. A developer will be ready to help resolve the problem.",
    },
  ],
};
