import classnames from "classnames";
import React from "react";

import "./InlineGroup.scss";

export const InlineGroup = ({ children, className, alignEnd }) => (
  <div
    className={classnames(
      "management_portal_inline_group",
      { management_portal_inline_group_align_end: alignEnd },
      { management_portal_inline_group_align_start: !alignEnd },
      className
    )}
  >
    {children}
  </div>
);
