import React from "react";

import { SelectField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { featureStates } from "../pod";

export const PodFeatureStateGroup = ({ pod, handleChange }) => (
  <GridWrapper>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
      <SelectField
        name="featureState"
        label="Pod State"
        options={featureStates}
        onChange={(e) => {
          handleChange(e, null);
        }}
        required={true}
        value={pod.featureState}
      />
    </GridItem>
  </GridWrapper>
);
