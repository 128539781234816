import React from "react";

import { FormGroup, Input, SelectField, TextInputField } from "@jsluna/form";
import { ToggleButton, ToggleButtonGroup } from "@jsluna/toggle-button";

export const NumberField = (props) => (
  <TextField
    inputMode="numeric"
    pattern="[0-9]*"
    inputFilter={/[^0-9]/g}
    {...props}
  />
);

export const CurrencyField = ({ onBlur, value, onChange, ...rest }) => (
  <TextField
    inputMode="numeric"
    pattern="[0-9.]*"
    inputFilter={/[^0-9.]/g}
    prefix="£"
    onBlur={(...args) => {
      if (/^[0-9]+$/.test(value)) {
        // Add a .00 suffix when the user doesn't enter one
        onChange?.(value + ".00");
      }
      onBlur?.(...args);
    }}
    value={value}
    onChange={onChange}
    {...rest}
  />
);

export const TextField = ({
  inputFilter,
  onChange,
  prefix,
  value,
  ...rest
}) => (
  <TextInputField
    onChange={(e) => {
      let newValue = e.target.value;
      if (newValue && prefix && newValue.startsWith(prefix)) {
        newValue = newValue.slice(prefix.length);
      }
      if (inputFilter) {
        newValue = newValue.replace(inputFilter, "");
      }
      onChange(newValue);
    }}
    value={value && prefix ? prefix + value : value}
    {...rest}
  />
);

export const SingleSelectField = ({ onChange, ...rest }) => (
  <SelectField onChange={(e) => onChange(e.target.value)} {...rest} />
);

export const YesNoField = ({
  value,
  onChange,
  disabled,
  action,
  actionFirst,
  button,
  className,
  element,
  error,
  hideLabel,
  icon,
  info,
  label,
  labelElement,
  name,
  optional,
  required,
  validationFirst,
  warning,
  ...rest
}) => (
  <FormGroup
    className={className}
    element={element}
    error={error}
    hideLabel={hideLabel}
    info={info}
    label={label}
    labelElement={labelElement}
    name={name}
    optional={optional}
    required={required}
    validationFirst={validationFirst}
    warning={warning}
  >
    {(inputProps) => (
      <>
        <Input
          {...inputProps}
          hidden
          value={value.toString()}
          disabled={disabled}
          onChange={() => onChange(!value)}
          id={name}
        />
        <ToggleButtonGroup
          {...rest}
          alpha
          value={value.toString()}
          onChange={(_, val) => onChange(val === true.toString())}
          disabled={disabled}
        >
          <ToggleButton value={true.toString()}>Yes</ToggleButton>
          <ToggleButton value={false.toString()}>No</ToggleButton>
        </ToggleButtonGroup>
      </>
    )}
  </FormGroup>
);
