import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Alert, AlertIcon } from "@jsluna/alert";
import { OutlinedButton } from "@jsluna/button";
import { GridItem } from "@jsluna/grid";
import { ErrorCircle, Measurement } from "@jsluna/icons";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";
import { Table } from "@jsluna/table";

import { InlineGroup } from "../../common/components/InlineGroup";
import PageContainer from "../../common/components/PageContainer";
import { PageHeader } from "../../common/components/PageHeader";
import Pagination from "../../common/components/Pagination";
import { GS1SupplierCodesClient } from "../../services/GS1SupplierCodesClient";
import { LoadingState } from "../../services/http";

const apiClient = new GS1SupplierCodesClient();

const listLoading = () => ({ type: "listLoading" });
const listSuccess = (data) => ({ type: "listSuccess", data: data });
const listError = (message) => ({ type: "listError", data: message });

const createInitialState = () => ({
  listRequestState: LoadingState.PENDING,
  listResults: {},
  listError: null,
});

function reducer(state, action) {
  switch (action.type) {
    case "listLoading":
      return {
        ...state,
        listRequestState: LoadingState.IN_PROGRESS,
        listError: null,
      };
    case "listSuccess":
      return {
        ...state,
        listRequestState: LoadingState.SUCCESS,
        listResults: action.data,
      };
    case "listError":
      return {
        ...state,
        listRequestState: LoadingState.FAILED,
        listResults: {},
        listError: action.data,
      };
    default:
      return state;
  }
}

const loadUsage = async (gs1Code, dispatch) => {
  dispatch(listLoading());
  try {
    const response = await apiClient.fetchUsage(gs1Code);
    if (response.status !== 200) {
      throw new Error(response);
    }
    dispatch(listSuccess(response.data));
  } catch (err) {
    console.error(err);
    const message = err?.response?.data?.description ?? "";
    dispatch(listError("Failed to load GS1 usage. " + message));
  }
};

const GS1SupplierCodesUsage = () => {
  const [{ listRequestState, listResults, listError }, dispatch] = useReducer(
    reducer,
    createInitialState()
  );
  const { gs1Code } = useParams();

  const showResults = listResults.gs1Usage;
  const showLoading = listRequestState === LoadingState.IN_PROGRESS;
  const navigate = useNavigate();

  useEffect(() => {
    loadUsage(gs1Code, dispatch);
  }, [gs1Code]);

  const [currentPage, setCurrentPage] = useState(1);

  const sortData = () => {
    // Check listResults.gs1Usage is an array before sorting
    if (!Array.isArray(listResults.gs1Usage)) {
      return [];
    }

    // Sort the array based on currentUsedNumber
    const sortedResult = [...listResults.gs1Usage].sort(
      (a, b) => b.currentUsedNumber / 999 - a.currentUsedNumber / 999
    );
    return sortedResult;
  };

  // Call the sortData
  const sortedResult = sortData();

  const paginatedResults =
    showResults && sortedResult.slice((currentPage - 1) * 25, currentPage * 25);

  return (
    <>
      <PageContainer>
        <GridItem size={{ md: "1/2" }}>
          <PageHeader>
            <Measurement />
            GS1 supplier codes usage
          </PageHeader>
        </GridItem>
        <GridItem size={{ md: "1/2" }}>
          <InlineGroup alignEnd>
            <OutlinedButton onClick={() => navigate("/gs1suppliercodes")}>
              Close
            </OutlinedButton>
          </InlineGroup>
        </GridItem>
        {listError && (
          <GridItem size="1/1">
            <Alert variant="error">
              <AlertIcon>
                <ErrorCircle aria-label="Error" role="img" />
              </AlertIcon>
              {listError}
            </Alert>
          </GridItem>
        )}

        {showResults && (
          <>
            <GridItem size="1/1">
              <Table
                data={paginatedResults}
                rowKey="id"
                caption="GS1 Code usage results"
                visuallyHiddenCaption
                columns={[
                  {
                    name: "Value",
                    accessor: (item) => ({ value: item.redemptionValue }),
                  },
                  {
                    name: "Usage count",
                    accessor: (item) => ({ value: item.currentUsedNumber }),
                  },
                  {
                    name: "Usage %",
                    accessor: (item) => ({
                      value: (item.currentUsedNumber / 999) // 999 is used here is count of times this value was used. Refer here for more info https://sainsburys-confluence.valiantys.net/x/yUFnCg
                        .toLocaleString(undefined, {
                          style: "percent",
                          maximumFractionDigits:
                            item.currentUsedNumber > 999 ? 0 : 2,
                        }),
                    }),
                  },
                ]}
              />
            </GridItem>
            {showResults && listResults.gs1Usage.length > 0 && (
              <GridItem size="1/1">
                <Pagination
                  currentPage={currentPage}
                  totalPages={(listResults.gs1Usage.length + 1) / 25}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </GridItem>
            )}
          </>
        )}
      </PageContainer>
      {showLoading && (
        <ProgressIndicator page loading preventFocus>
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      )}
    </>
  );
};

export default GS1SupplierCodesUsage;
