import { useEffect, useState } from "react";

import { LoadingState } from "../../services/http";

export const useSearch = (getData, fetchOnMount = true) => {
  const [listState, setListState] = useState(LoadingState.PENDING);
  const [requestedPage, setRequestedPage] = useState(1);
  const [listResults, setListResults] = useState({});
  const [lastSearch, setLastSearch] = useState({});

  const loadPage = async (page, filters) => {
    setListState(LoadingState.IN_PROGRESS);
    setRequestedPage(page);
    setLastSearch(filters);
    try {
      const data = await getData(page, filters);
      setListResults(data);
      setListState(LoadingState.SUCCESS);
    } catch (err) {
      setListResults({});
      setListState(LoadingState.FAILED);
      console.error(err);
    }
  };
  const reloadPage = () => loadPage(requestedPage, lastSearch);
  const changePage = (page) => loadPage(page, lastSearch);
  const newSearch = (filters) => loadPage(1, filters);
  const listReady =
    listState === LoadingState.SUCCESS || listState === LoadingState.PENDING;
  useEffect(() => {
    if (fetchOnMount) {
      newSearch(lastSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    requestedPage,
    listResults,
    listState,
    lastSearch,
    listReady,
    reloadPage,
    changePage,
    newSearch,
  };
};
