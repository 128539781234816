import React from "react";

import { TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

export const PodImagesInput = ({ pod, handleChange }) => {
  return (
    <div style={{ padding: "10px 0" }}>
      <GridWrapper>
        <GridItem>
          <TextInputField
            name="imageUrl"
            label="Image URL"
            placeholder="Image URL"
            onChange={(e) => {
              handleChange(e, "body");
            }}
            value={pod.body && pod.body.data && pod.body.data.imageUrl}
          />
        </GridItem>
      </GridWrapper>
    </div>
  );
};
