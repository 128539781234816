import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Icon, Message } from "semantic-ui-react";

import { Card } from "@jsluna/card";
import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import WelcomeLogo from "../../assets_V2/WelcomeLogo.png";
import { ManagementUserClient } from "../../services/ManagementUserClient";
import { LoadingState } from "../../services/http.js";
import UsersTableView from "./UsersTableView";

const managementUserClient = new ManagementUserClient();

const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [loadingState, setLoadingState] = useState(LoadingState.PENDING);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data } = await managementUserClient.fetchUsers();
      setUsers(data.content);
      setLoadingState(LoadingState.SUCCESS);
    } catch (err) {
      console.error(err);
      setLoadingState(LoadingState.FAILED);
    }
  };

  return (
    <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
      <GridWrapper>
        <GridItem style={{ marginBottom: "1rem" }}>
          <span>
            <Icon circular name="users" />
            &nbsp;&nbsp;<Label htmlFor="">Management Portal Users</Label>
          </span>
        </GridItem>
        <GridItem>
          {loadingState === LoadingState.PENDING && (
            <div
              style={{
                position: "absolute",
                display: "flex",
                width: "100%",
                height: "85vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GridWrapper
                className="ln-o-grid ln-o-grid--middle"
                horizontalAlign="center"
                verticalAlign="middle"
              >
                <GridItem className="ln-u-text-align-center" size={{ md: "1" }}>
                  <div className="ln-u-soft">
                    <img
                      src={WelcomeLogo}
                      className="logoAnimate"
                      alt=""
                      width="100"
                      height="100"
                    />
                  </div>
                </GridItem>
                <GridItem
                  className="welcomeMsgTxt ln-u-text-align-center"
                  size={{ md: "1" }}
                >
                  <h3>Loading...</h3>
                </GridItem>
              </GridWrapper>
            </div>
          )}
          {users && <UsersTableView users={users} />}
          {loadingState === LoadingState.FAILED && (
            <Message negative>
              <Message.Header>Error loading users</Message.Header>
            </Message>
          )}
        </GridItem>
      </GridWrapper>
    </Card>
  );
};

UsersManagement.propTypes = {
  users: PropTypes.array,
};

export default UsersManagement;
