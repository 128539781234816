import { HttpClient, Method, encodeQuery, managementApi } from "./http";

export class ProductClient extends HttpClient {
  fetchProductsBySku(sku) {
    return this.request(
      Method.GET,
      managementApi(`/products${encodeQuery({ sku })}`)
    );
  }

  searchProducts(name, page, categoryFilters) {
    let url = "/products/";
    url += categoryFilters.super_category
      ? "superCategory/" + categoryFilters.super_category + "/"
      : "";
    url += categoryFilters.category
      ? "category/" + categoryFilters.category + "/"
      : "";
    url += categoryFilters.sub_category
      ? "subCategory/" + categoryFilters.sub_category + "/"
      : "";
    url += categoryFilters.segment
      ? "segment/" + categoryFilters.segment + "/"
      : "";

    return this.request(
      Method.GET,
      managementApi(`${url}search?name=${name}&page=${page}`)
    );
  }

  searchProductHierarchy(categoryFilters) {
    let url = "/products/category-hierarchy/superCategory";
    url += categoryFilters.super_category
      ? "/" + categoryFilters.super_category + "/category"
      : "";
    url += categoryFilters.category
      ? "/" + categoryFilters.category + "/subCategory"
      : "";
    url += categoryFilters.sub_category
      ? "/" + categoryFilters.sub_category + "/segment"
      : "";

    return this.request(Method.GET, managementApi(`${url}`)).then((x) => {
      return x.data ? x.data.elements : [];
    });
  }

  listGroups(page = 0) {
    let url = `/products/group?page=${page}`;
    return this.request(Method.GET, managementApi(`${url}`)).then((x) => {
      return x.data;
    });
  }

  getGroup(id) {
    let url = `/products/group/${id}`;
    return this.request(Method.GET, managementApi(`${url}`)).then((x) => {
      return x.data;
    });
  }

  getGroupProducts(id, page = 0) {
    let url = `/products/group/${id}/products?page=${page}`;
    return this.request(Method.GET, managementApi(`${url}`)).then((x) => {
      return x.data;
    });
  }

  createGroup(name) {
    let url = "/products/group";
    return this.request(Method.POST, managementApi(`${url}`), {
      name: `${name}`,
    }).then((x) => {});
  }

  addProductToGroup(groupId, ean, sku) {
    let url = `/products/group/${groupId}/products/add`;
    return this.request(Method.POST, managementApi(`${url}`), {
      products: [{ ean: ean, sku: sku }],
    });
  }

  lockGroup(id) {
    let url = `/products/group/${id}`;
    return this.request(Method.PUT, managementApi(`${url}`), {
      status: "LOCKED",
    });
  }
}

export default ProductClient;
