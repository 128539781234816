import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FilledButton, OutlinedButton } from "@jsluna/button";
import { Delete } from "@jsluna/icons";
import { Pagination } from "@jsluna/pagination";

import { defaultDateTimeFormat } from "../../common/dates";
import "./HomepagePodsManagementView.scss";

const HomepagePodsList = ({ homepagePods, deletePod, isHPEditor }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const ROWS_PER_PAGE = 5;

  const PodTable = (props) => (
    <div className="homepagePodList__table">
      <table className="ln-c-table" celled striped>
        {props.children}
      </table>
    </div>
  );

  const PodTableHeader = () => (
    <thead className="ln-c-table__header">
      <tr className="ln-c-table__row ln-c-table__header-row">
        <th>Title</th>
        <th>State</th>
        <th>Starts</th>
        <th>Ends</th>
        <th>id</th>
        <th>Remove</th>
      </tr>
    </thead>
  );

  const handlePageChange = (page, e) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const pagination = (page, ROWS_PER_PAGE) => [
    (page - 1) * ROWS_PER_PAGE,
    (page - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE,
  ];

  const totalElements = homepagePods && homepagePods.length;
  const numOfPages =
    homepagePods &&
    totalElements > 0 &&
    Math.ceil(totalElements / ROWS_PER_PAGE);

  return (
    <>
      <PodTable>
        <PodTableHeader />
        <tbody className="ln-c-table__body">
          {homepagePods &&
            homepagePods
              .slice(...pagination(currentPage, ROWS_PER_PAGE))
              .map((pod, i) => (
                <tr className="ln-c-table__row ln-c-table__header-row" key={i}>
                  <td className="ln-c-table__cell">
                    <OutlinedButton
                      style={{ width: "20rem" }}
                      onClick={() => navigate(`edit/${pod.id}`)}
                      disabled={!isHPEditor}
                    >
                      <b>
                        {pod.header?.data?.title
                          ? pod.header.data.title
                          : "No title available"}
                      </b>
                    </OutlinedButton>
                  </td>
                  <td className="ln-c-table__cell">
                    <span
                      className={
                        pod.featureState === "ENABLED" ? "fontGreen" : "fontRed"
                      }
                    >
                      {pod.featureState}
                    </span>
                  </td>
                  <td className="ln-c-table__cell">
                    {defaultDateTimeFormat(
                      pod.startDateTime
                        ? pod.startDateTime
                        : "No startDateTime available"
                    )}
                  </td>
                  <td className="ln-c-table__cell">
                    {defaultDateTimeFormat(
                      pod.endDateTime
                        ? pod.endDateTime
                        : "No endDateTime available"
                    )}
                  </td>
                  <td className="ln-c-table__cell">{pod.id}</td>
                  <td className="ln-c-table__cell" collapsing>
                    <FilledButton
                      onClick={() => deletePod(pod.id, pod.header?.data?.title)}
                      disabled={!isHPEditor}
                    >
                      <Delete />
                    </FilledButton>
                  </td>
                </tr>
              ))}
        </tbody>
      </PodTable>
      {numOfPages > 1 && (
        <Pagination
          showFirstAndLast
          showPages
          current={currentPage}
          total={numOfPages}
          onChange={handlePageChange}
          className="ln-u-text-align-center ln-u-margin-bottom*3 ln-u-margin-top*3"
        />
      )}
    </>
  );
};

export default HomepagePodsList;
