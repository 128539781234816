import PropTypes from "prop-types";
import React, { Component } from "react";

import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { formatNectarNumber } from "../../common/formatNectarNumber";
import CustomerPointsManager from "./CustomerPointsManager";

class CustomerBrief extends Component {
  static propTypes = {
    customer: PropTypes.shape({
      cardNumber: PropTypes.string.isRequired,
      postcode: PropTypes.string.isRequired,
      externalHandle: PropTypes.string.isRequired,
      identityId: PropTypes.string.isRequired,
    }),
  };

  state = {
    cardNumberChanged: false,
  };

  baseStyle = {
    transition: "all 0.5s ease, all 0.5s ease-out",
  };

  updatedStyle = {
    ...this.baseStyle,
    border: "2px solid green",
    background: "#caffbf",
  };

  componentDidUpdate(prevProps) {
    if (this.props.customer.cardNumber !== prevProps.customer.cardNumber) {
      this.setState({ cardNumberChanged: true });
      setTimeout(() => this.setState({ cardNumberChanged: false }), 2000);
    }
  }

  render() {
    const { customer } = this.props;

    return (
      <GridWrapper>
        <GridItem>
          <span
            style={
              this.state.cardNumberChanged ? this.updatedStyle : this.baseStyle
            }
          >
            <h3>
              Nectar Card Number: {formatNectarNumber(customer.cardNumber)}
            </h3>
          </span>
          {customer.accountStatus === "locked" && (
            <span style={{ color: "#c00" }}>
              {" "}
              Locked in Identity (Trigger password reset to unlock)
            </span>
          )}
        </GridItem>
        <GridItem>
          <CustomerPointsManager customerBalance={customer.balance} />
        </GridItem>
        <GridItem>
          <b>Postcode:</b> {customer.postcode}
        </GridItem>
        <GridItem>
          <Card
            style={{
              border: "1px solid #d8d8d8",
              borderRadius: "3px",
              margin: "1rem 0 1rem 0",
            }}
          >
            <div class="ln-c-table-container">
              <table class="ln-c-table">
                <tbody class="ln-c-table__body">
                  <tr class="ln-c-table__row">
                    <td class="customCol ln-c-table__cell">Customer Id:</td>
                    <td class="ln-c-table__cell">{customer.id}</td>
                  </tr>
                  <tr class="ln-c-table__row">
                    <td class="customCol ln-c-table__cell">External Handle:</td>
                    <td class="ln-c-table__cell">{customer.externalHandle}</td>
                  </tr>
                  <tr class="ln-c-table__row">
                    <td class="customCol ln-c-table__cell">JS Identity Id:</td>
                    <td class="ln-c-table__cell">{customer.identityId}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </GridItem>
      </GridWrapper>
    );
  }
}

export default CustomerBrief;
