import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label, SelectField, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { TabLink, TabPanel, Tabs } from "@jsluna/tabs";

import MarketingEventClient from "../../services/MarketingEventClient";
import {
  UiOptionsTabs,
  initialHomepageUIOptions,
  initialOverviewUIOptions,
  initialSummaryUIOptions,
} from "./Constants";

const marketingEventClient = new MarketingEventClient();

const MarketingEventUIOptions = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("homepage");
  const [uiOptions, setUiOptions] = useState(initialHomepageUIOptions);
  const [uiContext, setUiContext] = useState(activeTab);

  const handleUiOptions = (event) => {
    const { name, value } = event.target;
    setUiOptions((prevState) => ({
      ...prevState,
      [name]: value,
      uiContext: activeTab,
    }));
  };

  const handleSummaryUIFields = (event) => {
    const { name, value } = event.target;
    const summaryUIFields = {
      [name]: value,
    };
    setUiOptions({
      ...uiOptions,
      summaryUIFields,
    });
  };

  const handleHomepageUIFields = (event) => {
    const { name, value } = event.target;
    const homepageUIFields = {
      [name]: value,
    };
    setUiOptions({
      ...uiOptions,
      homepageUIFields,
    });
  };

  const handleOverviewUIFields = (event) => {
    const { name, value } = event.target;
    const overviewUIFields = {
      [name]: value,
    };
    setUiOptions({
      ...uiOptions,
      overviewUIFields,
    });
  };

  const changeTabs = (key) => {
    setActiveTab(key);
    setUiContext(key);
    if (key === "homepage") {
      setUiOptions(initialHomepageUIOptions);
    } else if (key === "summary") {
      setUiOptions(initialSummaryUIOptions);
    } else if (key === "overview") {
      setUiOptions(initialOverviewUIOptions);
    }
  };

  const handleCreate = async () => {
    await marketingEventClient
      .createUIOptions(uiContext, uiOptions)
      .then((res) => {
        if (res.status === 200) {
          navigate("/marketing-events");
          toast.success(uiOptions.id + " UI option creted sucessfully");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    setUiOptions({
      ...uiOptions,
      uiContext: activeTab,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
      <GridWrapper matrix className="ln-u-margin-top*3">
        <GridItem>
          <Label htmlFor="marketing-events" className="ln-u-margin-left">
            Marketing Event - UI Options
          </Label>
          <br />
          <br />
          <GridItem size={"4/5"}>
            <GridItem>
              <Tabs type="pill">
                {UiOptionsTabs.map((tab) => (
                  <TabLink
                    key={tab.key}
                    onClick={() => changeTabs(tab.key)}
                    active={activeTab === tab.key}
                  >
                    {tab.name}
                  </TabLink>
                ))}
              </Tabs>
            </GridItem>
            <TabPanel className="ln-u-margin-top*3">
              <GridItem size="1/2">
                <TextInputField
                  name="id"
                  label="Ui option id"
                  placeholder="eg:- CFC-2023"
                  value={uiOptions.id}
                  onChange={(e) => handleUiOptions(e)}
                />
              </GridItem>
              <GridItem size="1/2">
                <TextInputField
                  name="name"
                  label="Ui option name"
                  placeholder="Eg: 123"
                  value={uiOptions.name}
                  onChange={(e) => handleUiOptions(e)}
                />
              </GridItem>
              <GridItem size="1/2">
                <TextInputField
                  name="uiContext"
                  label="Ui Context"
                  value={uiContext}
                  disabled
                />
              </GridItem>
              {activeTab === "homepage" && (
                <GridItem size="1/2">
                  <SelectField
                    label="Pod Type"
                    name="podType"
                    options={[
                      { label: "STATIC", value: "Static" },
                      { label: "PROGRESS", value: "Progress" },
                    ]}
                    value={uiOptions.homepageUIFields.podType}
                    onChange={(e) => handleHomepageUIFields(e)}
                  />
                </GridItem>
              )}
              {activeTab === "overview" && (
                <React.Fragment>
                  <GridItem size="1/2">
                    <SelectField
                      label="Pod Type"
                      name="podType"
                      options={[
                        { label: "STATIC", value: "Static" },
                        { label: "PROGRESS", value: "Progress" },
                      ]}
                      value={uiOptions.overviewUIFields.podType}
                      onChange={(e) => handleOverviewUIFields(e)}
                    />
                  </GridItem>
                  <GridItem size="1/2">
                    <SelectField
                      label="Progress Separator"
                      name="progressSeparator"
                      options={[
                        { label: "True", value: "True" },
                        { label: "False", value: "False" },
                      ]}
                      value={uiOptions.overviewUIFields.progressSeparator}
                      onChange={(e) => handleOverviewUIFields(e)}
                    />
                  </GridItem>
                  <GridItem size="1/2">
                    <SelectField
                      label="Show Active Challenge ProgressBar"
                      name="showActiveChallengeProgressBar"
                      options={[
                        { label: "True", value: "True" },
                        { label: "False", value: "False" },
                      ]}
                      value={
                        uiOptions.overviewUIFields
                          .showActiveChallengeProgressBar
                      }
                      onChange={(e) => handleOverviewUIFields(e)}
                    />
                  </GridItem>
                  <GridItem size="1/2">
                    <SelectField
                      label="Show Used Challenge ProgressBar"
                      name="showUsedChallengeProgressBar"
                      options={[
                        { label: "True", value: "True" },
                        { label: "False", value: "False" },
                      ]}
                      value={
                        uiOptions.overviewUIFields.showUsedChallengeProgressBar
                      }
                      onChange={(e) => handleOverviewUIFields(e)}
                    />
                  </GridItem>
                  <GridItem size="1/2">
                    <SelectField
                      label="Show Locked Challenge Progress Bar"
                      name="showLockedChallengeProgressBar"
                      options={[
                        { label: "True", value: "True" },
                        { label: "False", value: "False" },
                      ]}
                      value={
                        uiOptions.overviewUIFields
                          .showLockedChallengeProgressBar
                      }
                      onChange={(e) => handleOverviewUIFields(e)}
                    />
                  </GridItem>
                  <GridItem size="1/2">
                    <SelectField
                      label="Show Active Challenge Details ProgressBar"
                      name="showActiveChallengeDetailsProgressBar"
                      options={[
                        { label: "True", value: "True" },
                        { label: "False", value: "False" },
                      ]}
                      value={
                        uiOptions.overviewUIFields
                          .showActiveChallengeDetailsProgressBar
                      }
                      onChange={(e) => handleOverviewUIFields(e)}
                    />
                  </GridItem>
                  <GridItem size="1/2" />
                </React.Fragment>
              )}
              {activeTab === "summary" && (
                <GridItem size="1/2">
                  <SelectField
                    label="Pod Type"
                    name="podType"
                    options={[
                      { label: "STATIC", value: "Static" },
                      { label: "PROGRESS", value: "Progress" },
                    ]}
                    value={uiOptions.summaryUIFields.podType}
                    onChange={(e) => handleSummaryUIFields(e)}
                  />
                </GridItem>
              )}
              <GridItem size="1/2">
                <ButtonGroupWrapper>
                  <ButtonGroupSecondary>
                    <OutlinedButton
                      type="button"
                      size="small"
                      onClick={() => navigate("/marketing-events")}
                    >
                      Cancel
                    </OutlinedButton>
                    &nbsp;&nbsp;
                    <FilledButton
                      primary
                      size="small"
                      type="submit"
                      onClick={() => handleCreate()}
                    >
                      Create
                    </FilledButton>
                  </ButtonGroupSecondary>
                </ButtonGroupWrapper>
              </GridItem>
            </TabPanel>
          </GridItem>
        </GridItem>
      </GridWrapper>
    </Card>
  );
};

export default MarketingEventUIOptions;
