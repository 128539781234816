import PropTypes from "prop-types";
import React, { useState } from "react";
import { Checkbox } from "semantic-ui-react";

const ProductRow = ({ product, onProductSelected, isSelected }) => {
  const [hover, setHover] = useState(false);

  const handleOnCheckboxClick = () => {
    onProductSelected(product);
  };

  return (
    <tr
      className="ln-c-table__row"
      style={{
        backgroundColor: isSelected ? "silver" : hover ? "#FFFAF7" : "white",
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={handleOnCheckboxClick}
    >
      <td className="ln-c-table__cell" collapsing>
        <Checkbox checked={isSelected} />
      </td>
      <td className="ln-c-table__cell">{product.eanDescription}</td>
      <td className="ln-c-table__cell">{product.sku}</td>
      <td className="ln-c-table__cell">{product.superCategoryName}</td>
      <td className="ln-c-table__cell">{product.categoryName}</td>
      <td className="ln-c-table__cell">{product.subCategoryName}</td>
      <td className="ln-c-table__cell">{product.segmentName}</td>
    </tr>
  );
};

ProductRow.propTypes = {
  product: PropTypes.shape({
    eanDescription: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    segmentName: PropTypes.string.isRequired,
    subCategoryName: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    superCategoryName: PropTypes.string.isRequired,
  }).isRequired,
  onProductSelected: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default ProductRow;
