import React from "react";

import { SelectField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import "../PodEditor.scss";
import { colorTypes, findColorType } from "../pod";

export const PodColorsGroup = ({ pod, handleColorChange }) => {
  return (
    <span className="podColorsGroup">
      <GridWrapper>
        <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
          <SelectField
            name="colors"
            label="Color Theme Type"
            options={colorTypes}
            onChange={handleColorChange}
            required={true}
            value={findColorType(pod.body && pod.body.colors)}
          />
        </GridItem>
      </GridWrapper>
    </span>
  );
};
