import React, { useRef } from "react";
import { toast } from "react-toastify";

import { IconButton } from "@jsluna/button";
import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Upload } from "@jsluna/icons";

import OfferAllocateClient from "../../services/OfferAllocateClient";

const client = new OfferAllocateClient();

const GolOccOfferAllocateView = () => {
  const assetUploadInputRef = useRef(null);

  const onUploadFileChange = async (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      client
        .bulkUploadOcc(e.target.files[0])
        .then(() => toast.success("Done."))
        .catch((e) => toast.error(e?.response?.data?.description));
    }
  };

  const onUploadClick = (e) => {
    assetUploadInputRef.current.value = null;
    assetUploadInputRef.current.click();
  };

  return (
    <Card>
      <GridWrapper matrix className="ln-u-margin-top*3">
        <GridItem>
          <input
            id="bulk-upload-asset"
            data-testid="bulk-upload-asset"
            type="file"
            hidden
            name="asset"
            onChange={onUploadFileChange}
            ref={assetUploadInputRef}
            accept=".csv"
          />
          <IconButton
            onClick={onUploadClick}
            size="small"
            variant="filled"
            circle={false}
            element="button"
            label="Upload File"
            className="ln-u-margin-right"
          >
            <Upload />
          </IconButton>
        </GridItem>
      </GridWrapper>
    </Card>
  );
};

export default GolOccOfferAllocateView;
