import { HttpClient, Method, managementApi } from "./http";

export class OfferCodeClient extends HttpClient {
  fetchOfferCodes() {
    return this.request(Method.GET, managementApi(`/offer-codes`));
  }

  fetchOfferCode(id) {
    return this.request(Method.GET, managementApi(`/offer-codes/${id}`));
  }

  createOrUpdateOfferCode(offerCodeDetails) {
    return this.request(
      Method.PUT,
      managementApi(`/offer-codes`),
      offerCodeDetails
    );
  }

  deleteOfferCode(id) {
    return this.request(Method.DELETE, managementApi(`/offer-codes/${id}`));
  }
}

export default OfferCodeClient;
