import React from "react";

import { ProgressBar, ProgressIndicator } from "@jsluna/progress";

const Loader = () => {
  return (
    <ProgressIndicator
      page
      style={{ position: "absolute", zIndex: 10 }}
      loading
      preventFocus
    >
      <ProgressBar color="light" />
      Loading...
    </ProgressIndicator>
  );
};

export default Loader;
