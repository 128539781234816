import { Header, HttpClient, MediaType, Method, managementApi } from "./http";

export class PodManagementClient extends HttpClient {
  fetchAllHomepagePods() {
    return this.request(Method.GET, managementApi("/pod-management"));
  }

  fetchAllCountdownPods() {
    return this.request(
      Method.GET,
      managementApi("/pod-management/countdown-pods")
    );
  }

  fetchHomepagePod(podId) {
    return this.request(Method.GET, managementApi(`/pod-management/${podId}`));
  }

  fetchCountdownPod(podId) {
    return this.request(
      Method.GET,
      managementApi(`/pod-management/countdown-pods/${podId}`)
    );
  }

  fetchPodsAssets(page, size, prefix) {
    return this.request(
      Method.GET,
      managementApi(
        `/pod-management/assets?page=${page}&size=${size}&prefix=${
          `images/megapod/` + prefix
        }`
      )
    );
  }

  createOrUpdateHomepagePod(podDefinition) {
    return this.request(
      Method.POST,
      managementApi("/pod-management"),
      podDefinition
    );
  }

  createOrUpdateCountdownPod(countdownPodPayload) {
    return this.request(
      Method.PUT,
      managementApi("/pod-management/countdown-pods"),
      countdownPodPayload
    );
  }

  deletePod(podId) {
    return this.request(
      Method.DELETE,
      managementApi(`/pod-management/${podId}`)
    );
  }

  deleteCountdownPod(podId) {
    return this.request(
      Method.DELETE,
      managementApi(`/pod-management/countdown-pods/${podId}`)
    );
  }

  reOrderHomepagePods(podList) {
    return this.request(
      Method.PUT,
      managementApi("/pod-management/pod-order"),
      podList
    );
  }

  uploadAssetHomePagePod(imageName, file) {
    return this.request(
      Method.POST,
      managementApi(`/pod-management/upload/${imageName}`),
      file,
      {
        [Header.CONTENT_TYPE]: MediaType.MultipartFormData,
      }
    );
  }
}

export default PodManagementClient;
