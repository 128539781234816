import React from "react";

import { SkuDetailsGroup } from "./skuforminputs/SkuDetailsGroup";
import { SkuPortionsGroup } from "./skuforminputs/SkuPortionsGroup";

export const SkuEditorForm = ({ sku, handleChange, isNewSku }) => {
  return (
    <div>
      <SkuDetailsGroup
        sku={sku}
        handleChange={handleChange}
        isNewSku={isNewSku}
      />

      <SkuPortionsGroup sku={sku} handleChange={handleChange} />
    </div>
  );
};
