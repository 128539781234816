import classnames from "classnames";
import React from "react";

import "./PageHeader.scss";

export const PageHeader = ({ children, className }) => (
  <h3 className={classnames("management_portal_page_header", className)}>
    {children}
  </h3>
);
