import { isEmpty } from "lodash";
import React from "react";
import { Card, Grid, Icon, Image } from "semantic-ui-react";

import { Label } from "@jsluna/form";

import { PlaceholderImage } from "../../pod";

const PodPreview = ({ pod }) => {
  const getFirstFooterImage = () => {
    const footerImages = pod.footer?.data?.brands;
    if (!isEmpty(footerImages))
      return (
        <img
          src={footerImages[0].imageUrl}
          style={{ maxHeight: "3.9rem", width: "6rem", objectFit: "contain" }}
          alt={footerImages[0].altText}
        />
      );
  };

  return (
    <Grid.Column verticalAlign="middle" width={4}>
      <Grid columns={2} divided stackable centered>
        <Grid.Column width={12}>
          <Card.Content>
            <span>
              <strong>
                <Icon circular name="tv" />
                &nbsp;&nbsp;<Label>Preview</Label>
              </strong>
            </span>
          </Card.Content>
          <br />
          <Grid.Row>
            <div
              className="podPreview"
              style={{
                background: pod.body?.colors?.dark?.backgroundColor,
                color:
                  pod.body?.colors?.dark?.backgroundColor === "#8223FA" &&
                  "white",
              }}
            >
              <div>
                <div
                  className="previewTitle"
                  style={{ color: pod.header?.colors?.dark?.foregroundColor }}
                >
                  {pod.header?.data?.title}
                </div>
                <div
                  className="previewSubText"
                  style={{ color: pod.header?.colors?.dark?.foregroundColor }}
                >
                  {pod.header?.data?.subtitle}
                </div>
              </div>
              <div>
                {pod.body && pod.body.type && pod.body.type === "IMAGE" ? (
                  <div>
                    <img
                      src={
                        pod.body?.data?.imageUrl
                          ? pod.body.data.imageUrl
                          : PlaceholderImage
                      }
                      alt={pod.body.data.accessibilityText}
                      style={{ width: "100%" }}
                    />
                  </div>
                ) : pod.images && pod.images.length > 0 ? (
                  pod.images.map((image) => (
                    <div className="previewTitledLayout">
                      <img
                        src={image.imageUrl}
                        alt={image.name}
                        style={{ width: "100%" }}
                      />
                    </div>
                  ))
                ) : (
                  <img
                    src={PlaceholderImage}
                    alt={"placeholder"}
                    style={{ width: "100%" }}
                  />
                )}
                {!!pod.footer?.data?.brands &&
                pod.footer.data.brands.length > 1 ? (
                  <div className="previewMultiFooter">
                    {pod.footer.data.brands.slice(0, 3).map((image) => (
                      <Image
                        src={image.imageUrl}
                        style={{ height: "2.25rem" }}
                        alt={image.altText}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="previewFooter">{getFirstFooterImage()}</div>
                )}
              </div>
            </div>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </Grid.Column>
  );
};

export default PodPreview;
