import React from "react";

import ProductClient from "../../services/ProductClient";
import { LoadingState } from "../../services/http.js";
import PagedTable from "./PagedTable";
import ProductTable from "./ProductTable";

const productClient = new ProductClient();
const WAIT_INTERVAL = 500;

class ProductSearchAspect extends React.Component {
  state = {
    page: 0,
    totalPages: 0,
    products: [],
    searchTerm: "",
    searchFilters: {},
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchTerm !== this.state.searchTerm) {
      this.setState({ searchTerm: nextProps.searchTerm });

      setTimeout(
        (oldTerms) => {
          if (oldTerms === this.state.searchTerm) {
            this._searchProducts(0);
          }
        },
        WAIT_INTERVAL,
        nextProps.searchTerm + ""
      );
    } else if (nextProps.searchFilters !== this.props.searchFilters) {
      this.setState({ searchFilters: nextProps.searchFilters }, () => {
        this._searchProducts(0);
      });
    }
  }

  _searchProducts = (page) => {
    const { searchTerm, searchFilters } = this.state;

    if (searchTerm.length > 1 || searchFilters.category) {
      productClient
        .searchProducts(searchTerm, page, searchFilters)
        .then(({ data }) => {
          this.setState({
            products: data.products,
            totalPages: data.totalPages,
            page: data.currentPage,
          });
        });
    }
  };

  pagingNavigator = (amount) => {
    const { page, totalPages } = this.state;
    clearTimeout(this._searchTimeout);

    if (page + amount < totalPages && page + amount >= 0) {
      this._searchTimeout = setTimeout(() => {
        this.setState({ loadingState: LoadingState.PENDING });
        this._searchProducts(page + amount);
      }, WAIT_INTERVAL);
    }
  };

  handleProductCheck = (product) => {
    this.props.onProductSelect(product);
  };

  render() {
    const { page, totalPages, products } = this.state;
    const { selectedProducts } = this.props;

    return (
      <PagedTable
        page={page}
        totalPages={totalPages}
        items={products}
        pagingNavigator={this.pagingNavigator}
      >
        {this.props.children}
        <ProductTable
          products={products}
          onProductSelect={this.handleProductCheck}
          selectedProducts={selectedProducts}
        />
      </PagedTable>
    );
  }
}

export default ProductSearchAspect;
