import React from "react";
import { useNavigate } from "react-router-dom";

import { Checkbox } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Link, Table } from "@jsluna/react";
import { Pagination } from "@jsluna/react";
import { Display2 } from "@jsluna/typography";

import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import { formattedDate } from "./Constants";
import StatusDot from "./StatusDot";

const IterationsTable = ({
  marketingEventsIterationsList,
  selectedRowMap,
  handleSelectRow,
  onPaginationChange,
  currentPage,
}) => {
  const isSelected = (id) => {
    const selectedRow = selectedRowMap && selectedRowMap.get(id);
    return selectedRow
      ? selectedRowMap && selectedRowMap.get(id).selected
      : false;
  };

  const isMEEditor = HasRequiredRoleAccess("marketing-events");
  const navigate = useNavigate();
  return (
    <React.Fragment>
      {marketingEventsIterationsList &&
      marketingEventsIterationsList.content &&
      marketingEventsIterationsList.content.length > 0 ? (
        <GridWrapper>
          <GridItem>
            <Table
              sortable
              responsive
              data={marketingEventsIterationsList.content}
              visuallyHiddenCaption
              columns={[
                {
                  name: "Select",
                  sortable: false,
                  accessor: (rowData) => ({ value: rowData }),
                  hideLabel: true,
                  render: ({ value }) => (
                    <Checkbox
                      className="custom-checkbox"
                      name={value && value.id}
                      onChange={(e) => handleSelectRow(e.target.checked, value)}
                      checked={isSelected(value.id)}
                      disabled={!isMEEditor}
                    />
                  ),
                },
                {
                  name: "Iteration Id",
                  accessor: (rowData) => ({
                    value: rowData,
                    displayName: rowData.id,
                  }),
                  render: ({ value }) => (
                    <React.Fragment>
                      <Link
                        style={
                          isMEEditor
                            ? { cursor: "pointer" }
                            : { pointerEvents: "none" }
                        }
                        color="alpha"
                        bare
                        onClick={() =>
                          navigate("/marketing-events/iterations", {
                            state: {
                              editIterations: {
                                enableEdit: true,
                                isDuplicate: false,
                                rowData:
                                  marketingEventsIterationsList.content.filter(
                                    (data) => data.id.includes(value.id)
                                  ),
                              },
                            },
                          })
                        }
                      >
                        <p className="highlighted-text">{value.id}</p>
                      </Link>
                    </React.Fragment>
                  ),
                  sortable: false,
                },
                {
                  name: "Marketing Event Id",
                  accessor: (rowData) => ({ value: rowData.marketingEvent }),
                  render: ({ value }) => (
                    <p className="highlighted-text">{value}</p>
                  ),
                  sortable: false,
                },
                {
                  name: "Start date",
                  accessor: (rowData) => ({
                    value: formattedDate(rowData.startDate),
                  }),
                  sortable: false,
                },
                {
                  name: "End date",
                  accessor: (rowData) => ({
                    value: formattedDate(rowData.endDate),
                  }),
                  sortable: false,
                },
                {
                  name: "Status",
                  accessor: (rowData) => ({ value: rowData.ingestionState }),
                  render: ({ value }) => <StatusDot value={value} />,
                  sortable: false,
                },
              ]}
            />
          </GridItem>
          <GridItem size={{ xs: "1/1" }} className="ln-u-margin-top*3">
            <div className="ln-u-text-align-center">
              <Pagination
                className="marketing-pagination"
                showFirstAndLast
                showPages
                current={currentPage}
                total={
                  marketingEventsIterationsList &&
                  marketingEventsIterationsList.totalPages
                    ? marketingEventsIterationsList.totalPages
                    : 1
                }
                onChange={(page, e) => {
                  onPaginationChange(page, e);
                }}
              />
            </div>
          </GridItem>
        </GridWrapper>
      ) : (
        <Display2>No Marketing Events Iterations to display</Display2>
      )}
    </React.Fragment>
  );
};

export default IterationsTable;
