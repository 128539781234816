import React from "react";

import { StatusBgColor } from "./Constants";

const StatusDot = ({ value }) => {
  const returnStatusBgColor = (clr) => {
    switch (clr) {
      case "NOT_ASSIGNED": {
        return StatusBgColor.orange;
      }
      case "NOT_STARTED": {
        return StatusBgColor.blue;
      }
      case "DONE": {
        return StatusBgColor.green;
      }
      default: {
        return StatusBgColor.blue;
      }
    }
  };

  return (
    <React.Fragment>
      <span className="status-container">
        <span
          style={{ backgroundColor: returnStatusBgColor(value) }}
          className="status-circle"
        />
        <span className="ln-u-margin-left*2">{value}</span>
      </span>
    </React.Fragment>
  );
};

export default StatusDot;
