import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

const UsersTableView = ({ users }) => {
  const sortedUsers = users.sort((a, b) =>
    a.username.toLowerCase() > b.username.toLowerCase()
      ? 1
      : b.username.toLowerCase() > a.username.toLowerCase()
      ? -1
      : 0
  );

  const tableBody = sortedUsers.map((user, i) => (
    <ManagementUserSummaryRow key={i} user={user} />
  ));

  return (
    <div className="ln-c-table-container">
      <table className="ln-c-table">
        <caption className="ln-c-table__caption ln-u-visually-hidden">
          List of Users.
        </caption>
        <thead className="ln-c-table__header">
          <tr className="ln-c-table__row ln-c-table__header-row">
            <th className="ln-c-table__header-cell customCol">Role</th>
            <th className="ln-c-table__header-cell customCol">Username</th>
            <th className="ln-c-table__header-cell customCol">Email</th>
            <th className="ln-c-table__header-cell customHead">Last Updated</th>
          </tr>
        </thead>
        <tbody className="ln-c-table__body">{tableBody}</tbody>
      </table>
    </div>
  );
};

UsersTableView.propTypes = {
  users: PropTypes.array.isRequired,
};

const ManagementUserSummaryRow = ({ user }) => {
  const navigate = useNavigate();

  const viewUser = (id) => {
    navigate(`/management-user/${id}`);
  };

  const getTagColor = () => {
    switch (user.userType) {
      case "God":
        return "red";
      case "Developer":
        return "green";
      case "Careline":
        return "violet";
      case "Campaign Editor":
        return "olive";
      case "Administrator":
        return "orange";
      default:
        return "purple";
    }
  };

  return (
    <tr
      className="ln-c-table__row"
      onClick={() => viewUser(user.id)}
      style={{ cursor: "pointer" }}
    >
      <td className="ln-c-table__cell">
        {user.userType && (
          <span
            style={{
              backgroundColor: getTagColor(),
              borderRadius: "3px",
              color: "#fff",
              padding: "5px",
            }}
          >
            {user.userType}
          </span>
        )}
      </td>
      <td className="ln-c-table__cell">{user.username}</td>
      <td className="ln-c-table__cell">{user.email}</td>
      <td className="ln-c-table__cell">{user.updated}</td>
    </tr>
  );
};

ManagementUserSummaryRow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    updated: PropTypes.string.isRequired,
    userType: PropTypes.string,
  }).isRequired,
};

export default UsersTableView;
