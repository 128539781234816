import { HttpClient, Method, encodeQuery, managementApi } from "./http";

export class BarcodeBlocklistsClient extends HttpClient {
  blockBarcode(body) {
    return this.request(
      Method.POST,
      managementApi("/barcode/blocked-barcode"),
      body
    );
  }

  fetchBarcodes(page, size, name, barcode) {
    return this.request(
      Method.GET,
      managementApi(
        `/barcode/blocked-barcode/search${encodeQuery({
          page: page,
          size: size,
          name: name,
          barcode: barcode,
        })}`
      )
    );
  }

  getBarcodeDetails(barcode) {
    return this.request(
      Method.GET,
      managementApi(`/barcode/blocked-barcode/${barcode}`)
    );
  }

  editBarcode(barcode, data) {
    return this.request(
      Method.PUT,
      managementApi(`/barcode/blocked-barcode/${barcode}`),
      data
    );
  }

  deleteBarcode(barcode) {
    return this.request(
      Method.DELETE,
      managementApi(`/barcode/blocked-barcode/${barcode}`)
    );
  }
}

export default BarcodeBlocklistsClient;
