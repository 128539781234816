export const isValidHttpUrl = (testString) => {
  try {
    const url = new URL(testString);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
};

// Proper email validation is a difficult problem if you can't test delivery, but this will catch most errors
export const maybeValidEmail = (testString) =>
  /^\S+@\S+\.\S+$/.test(testString);
