import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ProgressBar, ProgressIndicator } from "@jsluna/progress";

import CampaignClient from "../../services/CampaignClient";
import { getSessionCookieData } from "../../services/sessionCookie";
import CampaignEditor from "./CampaignEditor";

const campaignClient = new CampaignClient();

const CreateCampaign = () => {
  const { username } = getSessionCookieData();
  const navigate = useNavigate();
  const [apiErrorText, setApiErrorText] = useState("");
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [existingTags, setExistingTags] = useState([
    "Loading available tags...",
  ]);

  useEffect(() => {
    const loadTags = async () => {
      try {
        const response = await campaignClient.fetchCampaignTags();
        if (response.status !== 200) {
          throw new Error(response);
        }
        setExistingTags(response.data.map((n) => n.name));
      } catch (err) {
        console.error(err);
        const message = err?.response?.data?.description ?? "";
        setApiErrorText("Failed to get tags. " + message);
        setExistingTags(["Failed to get tags"]);
      }
      setInitialLoadComplete(true);
    };
    loadTags();
  }, []);

  const onClose = () => navigate("/campaigns");

  const commitOnSave = async (campaignId) => {
    try {
      const response = await campaignClient.commitCampaign(campaignId);
      if (response.status !== 200) {
        throw new Error(response);
      }
      toast.success(`Campaign ${campaignId} was successfully commited`);
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      toast.error("Failed to commit the campaign. " + message);
    }
  };

  const onSave = async (requestBody, generateBarcode) => {
    setLoading(true);
    setApiErrorText(null);

    try {
      const newTags = (requestBody.tags ?? []).filter(
        (t) => !existingTags.includes(t)
      );
      for (const tag of newTags) {
        const response = await campaignClient.createCampaignTag(tag);
        if (response.status !== 200) {
          throw new Error(response);
        }
      }
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText("Failed to save new campaign tags. " + message);
    }

    if (generateBarcode) {
      try {
        const response = await campaignClient.generateBarcode(requestBody);
        if (response.status !== 200) {
          throw new Error(response);
        }
        requestBody.barcode = response.data.barcode;
        toast.info("Created new barcode for campaign: " + requestBody.barcode);
      } catch (err) {
        console.error(err);
        const message = err?.response?.data?.description ?? "";
        setApiErrorText("Failed to generate a barcode. " + message);
      }
    }

    try {
      const response = await campaignClient.createCampaign(requestBody);
      if (response.status !== 200) {
        throw new Error(response);
      }
      toast.success("Created campaign: " + response.data.id);
      if (generateBarcode) {
        await commitOnSave(response.data.id);
      }
      setLoading(false);
      onClose();
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText("Failed to save the campaign. " + message);
      setLoading(false);
    }
  };

  if (!initialLoadComplete) {
    return (
      <ProgressIndicator page loading preventFocus>
        <ProgressBar color="light" />
        Loading...
      </ProgressIndicator>
    );
  }

  return (
    <CampaignEditor
      loading={loading}
      existingTags={existingTags}
      currentUsername={username}
      apiErrorText={apiErrorText}
      onSave={onSave}
      initialValues={{}}
    />
  );
};

export default CreateCampaign;
