import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ButtonGroupPrimary,
  ButtonGroupWrapper,
  IconButton,
  TextButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Plus } from "@jsluna/icons";
import { MobilePhone } from "@jsluna/icons";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";

import { PageHeader } from "../../common/components/PageHeader";
import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import { PodManagementClient } from "../../services/PodManagementClient";
import HomepagePodsList from "./HomepagePodsList";
import "./HomepagePodsManagementView.scss";

const podManagementClient = new PodManagementClient();

const HomepagePodsManagementView = () => {
  const [homepagePods, setHomePagePods] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const isHPEditor = HasRequiredRoleAccess("homepage-pods");

  useEffect(() => {
    GetHomePagePods().then(() => setLoading(false));
  }, []);

  const GetHomePagePods = async () => {
    await podManagementClient
      .fetchAllHomepagePods()
      .then(({ data }) => setHomePagePods(data))
      .catch((err) => {
        setError(err);
        toast.error(err);
      });
  };

  const deleteHomePagePod = (podId, name) => {
    setLoading(true);
    Promise.all([
      podManagementClient
        .deletePod(podId)
        .then(() => {
          toast.success(`Deleted pod${name ? ` '${name}'` : ""}`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          GetHomePagePods();
        })
        .catch((err) => {
          setError(err);
          toast.error(err);
        }),
    ]).then(() => setLoading(false));
  };

  if (!homepagePods && !error) return null;

  const routeToHpAssetManagement = () => {
    navigate("hp-assets");
  };

  return (
    <div className="homepagePodList__container">
      <>
        <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
          <GridWrapper matrix>
            <GridItem size="1/1">
              <PageHeader>
                <MobilePhone />
                Homepage Pods
              </PageHeader>
            </GridItem>
            <GridItem
              style={{ display: "GridWrapper", gridAutoFlow: "column" }}
            >
              <ButtonGroupWrapper>
                <ButtonGroupPrimary style={{ display: "flex" }}>
                  <TextButton
                    onClick={routeToHpAssetManagement}
                    style={{ marginRight: "10px" }}
                  >
                    Manage Assets
                  </TextButton>
                  <IconButton
                    onClick={() => navigate("edit/new")}
                    size="small"
                    variant="filled"
                    circle={false}
                    element="button"
                    label="Create New Homepage Pod"
                    disabled={!isHPEditor}
                  >
                    <Plus />
                  </IconButton>
                </ButtonGroupPrimary>
              </ButtonGroupWrapper>
            </GridItem>
            <GridItem>
              <HomepagePodsList
                homepagePods={homepagePods}
                deletePod={deleteHomePagePod}
                isHPEditor={isHPEditor}
              />
            </GridItem>
          </GridWrapper>
        </Card>
        {loading && (
          <ProgressIndicator
            page
            style={{ position: "absolute", zIndex: 10 }}
            loading
            preventFocus
          >
            <ProgressBar color="light" />
            Loading...
          </ProgressIndicator>
        )}
      </>
    </div>
  );
};

export default HomepagePodsManagementView;
