import React from "react";
import { Dropdown, Icon, Input, Transition } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label, TextInput } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Basket, Cancel, InfoCircle } from "@jsluna/icons";
import { Modal, ModalHeading } from "@jsluna/modal";

import ProductClient from "../../services/ProductClient";
import { LoadingState } from "../../services/http.js";
import GroupDetailAspect from "./GroupDetailAspect";
import GroupListAspect from "./GroupListAspect";
import ProductSearchAspect from "./ProductSearchAspect";

const productClient = new ProductClient();
const TRANSITION_DURATION = 750;

class ProductsView extends React.Component {
  state = {
    productMode: true,
    groupMode: false,
    groupDetailMode: false,
    viewingGroup: {},
    searchTerm: "",
    searchFilters: {},
    categoryDropdownOptions: {
      super_category: [],
      category: [],
      sub_category: [],
      segment: [],
    },
    createGroupInput: "",
    selectedProducts: [],
    selectedGroups: [],
    confirmOpen: false,
    confirmMessage: "",
    confirmLock: false,
    loadingState: LoadingState.PENDING,
    error: undefined,
  };

  constructor() {
    super();
    this._handleChange = this._handleChange.bind(this);
  }

  _handleChange = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchTerm: searchTerm });
  };

  clearSearchFilters = (hierarchyLevel) => {
    const keys = this.getSubHierarchy(hierarchyLevel);
    let obj = {};
    for (var item of keys) {
      obj[item] = "";
    }
    return obj;
  };

  clearCategoryOptions = (hierarchyLevel) => {
    const keys = this.getSubHierarchy(hierarchyLevel);
    let obj = {};
    for (var item of keys) {
      obj[item] = [];
    }
    return obj;
  };

  handleOnCategoryChange = (e, { name, value }) => {
    const newFilters = Object.assign(
      {},
      this.state.searchFilters,
      { [name]: value },
      this.clearSearchFilters(name)
    );
    if (value !== this.state.searchFilters[name]) {
      this.setState({
        searchFilters: newFilters,
        categoryOptions: Object.assign(
          this.state.categoryDropdownOptions,
          this.clearCategoryOptions(name)
        ),
      });
      if (value !== "") {
        const thisLevelIdx = this.hierarchy().findIndex((x) => x === name);
        const nextLevelName = this.hierarchy()[thisLevelIdx + 1];
        if (thisLevelIdx + 1 < this.hierarchy().length) {
          this.loadCategoriesForHierarchyLevel(newFilters, nextLevelName);
        }
      }
    }
  };

  loadCategoriesForHierarchyLevel = (filters, targetLevel) => {
    productClient.searchProductHierarchy(filters).then((categories) => {
      let mappedCategories = categories.map((x) => {
        return { key: x.key, value: x.key, text: x.name };
      });
      mappedCategories.unshift({ key: "", value: "", text: "none" });
      const invalidatedCategoryDropdownOptions =
        this.clearCategoryOptions(targetLevel);
      invalidatedCategoryDropdownOptions[targetLevel] = mappedCategories;
      const fullCategoryDropdownOptions = Object.assign(
        this.state.categoryDropdownOptions,
        invalidatedCategoryDropdownOptions
      );
      this.setState({
        searchFilters: filters,
        categoryOptions: fullCategoryDropdownOptions,
      });
    });
  };

  hierarchy = () => {
    return ["super_category", "category", "sub_category", "segment"];
  };

  getSubHierarchy = (hierarchyNode) => {
    const hierarchy = this.hierarchy();
    return hierarchy.slice(
      hierarchy.findIndex((x) => x === hierarchyNode) + 1,
      hierarchy.length
    );
  };

  handleProductSelection = (product) => {
    const copyOfSelectedProducts = Object.assign(this.state.selectedProducts);
    const index = copyOfSelectedProducts.indexOf(product);

    if (index !== -1) {
      copyOfSelectedProducts.splice(index, 1);
    } else {
      copyOfSelectedProducts.push(product);
    }

    this.setState({ selectedProducts: copyOfSelectedProducts });
  };

  handleGroupCheck = (group) => {
    const copyOfSelectedGroups = Object.assign(this.state.selectedGroups);
    const index = copyOfSelectedGroups.indexOf(group);

    if (index !== -1) {
      copyOfSelectedGroups.splice(index, 1);
    } else {
      copyOfSelectedGroups.push(group);
    }

    this.setState({ selectedGroups: copyOfSelectedGroups });
  };

  handleGroupSelect = (group) => {
    this.setState({ viewingGroup: group }, () =>
      this.toggleMode("groupDetailMode")
    );
  };

  handleAddProductToGroup = () => {
    if (
      !!this.state.selectedGroups.length &&
      !!this.state.selectedProducts.length
    ) {
      this.state.selectedProducts.forEach((prod) => {
        this.state.selectedGroups.forEach((grp) => {
          productClient.addProductToGroup(grp.id, prod.ean, prod.sku);
        });
      });

      this.setState({
        selectedProducts: [],
        selectedGroups: [],
        confirmOpen: true,
        confirmMessage: `Attempted to add ${this.state.selectedProducts.length} products to ${this.state.selectedGroups.length} groups.`,
      });
    }
  };

  componentDidMount = () => {
    this.loadCategoriesForHierarchyLevel({}, "super_category");
  };

  handleConfirm = () => {
    this.setState({ confirmOpen: false });
  };

  toggleMode = (mode) => {
    this.setState({
      productMode: false,
      groupMode: false,
      groupDetailMode: false,
    });
    setTimeout(() => {
      this.setState({ [mode]: true });
    }, TRANSITION_DURATION);
  };

  handleOnCreateGroupInputChange = (e) => {
    this.setState({ createGroupInput: e.target.value });
  };

  handleOnCreateGroupInputSubmit = () => {
    productClient.createGroup(this.state.createGroupInput);
    this.setState({ createGroupInput: "" });
  };

  lockGroup = () => {
    productClient
      .lockGroup(this.state.viewingGroup.id)
      .then(() => productClient.getGroup(this.state.viewingGroup.id))
      .then((grp) =>
        this.setState({
          confirmLock: false,
          groupDetailMode: false,
          viewingGroup: grp,
        })
      );
    setTimeout(() => {
      this.setState({ groupDetailMode: true });
    }, 1000);
  };

  buildGroupDetails = () => {
    return (
      <div>
        <GroupDetailAspect group={this.state.viewingGroup}>
          {this.state.viewingGroup.status !== "LOCKED" && (
            <OutlinedButton
              onClick={() => this.setState({ confirmLock: true })}
            >
              Lock Group
            </OutlinedButton>
          )}
          <Modal
            open={this.state.confirmLock}
            fullScreen
            restrictClose
            alert
            headingId="dialog-modal"
            className="modal-overflow"
          >
            <ModalHeading element="h3">
              Are you sure? This cannot be undone.
            </ModalHeading>
            <ButtonGroupPrimary>
              <FilledButton onClick={this.lockGroup}>Ok</FilledButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <OutlinedButton
                onClick={() => this.setState({ confirmLock: false })}
              >
                Cancel
              </OutlinedButton>
            </ButtonGroupPrimary>
          </Modal>
          <span
            style={{ marginLeft: "80px" }}
            onClick={() => this.toggleMode("groupMode")}
          >
            Go to Group view <Icon name="file outline" size="large" />
          </span>
        </GroupDetailAspect>
      </div>
    );
  };

  buildGroupList = () => {
    const { selectedProducts, selectedGroups } = this.state;
    return (
      <div>
        <GroupListAspect
          onGroupCheck={this.handleGroupCheck}
          onGroupSelect={this.handleGroupSelect}
          selectedGroups={this.state.selectedGroups}
        >
          {selectedProducts.length > 0 && selectedGroups.length > 0 && (
            <OutlinedButton
              style={{ margin: "3px" }}
              onClick={this.handleAddProductToGroup}
            >
              {`Assign ${selectedProducts.length} selected products to ${selectedGroups.length} selected groups`}
            </OutlinedButton>
          )}
          <span
            style={{ marginLeft: "80px" }}
            onClick={() => this.toggleMode("productMode")}
          >
            Go to Product view <Icon name="file outline" size="large" />
          </span>
        </GroupListAspect>
      </div>
    );
  };

  buildproductSearch = () => {
    return (
      <div>
        <ProductSearchAspect
          searchTerm={this.state.searchTerm}
          searchFilters={this.state.searchFilters}
          selectedProducts={this.state.selectedProducts}
          onProductSelect={this.handleProductSelection}
        >
          <span onClick={() => this.toggleMode("groupMode")}>
            Go to Group view <Icon name="folder outline" size="large" />
          </span>
        </ProductSearchAspect>
      </div>
    );
  };

  buildCategorySelectors = () => {
    return (
      <div>
        <GridWrapper style={{ padding: "1.5rem 0" }}>
          <GridItem size={{ xs: "1/5" }}>
            <Input
              style={{
                width: "100%",
                backgroundColor: "transparent",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "2px",
                color: "#737373",
                minHeight: "48px",
              }}
              icon="search"
              placeholder="Search..."
              onChange={this._handleChange}
            />
          </GridItem>
          <GridItem size={{ xs: "1/5" }}>
            <Dropdown
              style={{
                width: "100%",
                backgroundColor: "transparent",
                padding: "16px 8px 16px",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "2px",
                color: "#737373",
                minHeight: "48px",
              }}
              name="super_category"
              placeholder="Super category"
              selection
              search
              options={this.state.categoryDropdownOptions.super_category}
              onChange={this.handleOnCategoryChange}
              value={this.state.searchFilters.super_category}
            />
          </GridItem>
          <GridItem size={{ xs: "1/5" }}>
            <Dropdown
              style={{
                width: "100%",
                backgroundColor: "transparent",
                padding: "16px 8px 16px",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "2px",
                color: "#737373",
                minHeight: "48px",
              }}
              name="category"
              placeholder="Category"
              selection
              search
              options={this.state.categoryDropdownOptions.category}
              onChange={this.handleOnCategoryChange}
              value={this.state.searchFilters.category}
            />
          </GridItem>
          <GridItem size={{ xs: "1/5" }}>
            <Dropdown
              style={{
                width: "100%",
                backgroundColor: "transparent",
                padding: "16px 8px 16px",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "2px",
                color: "#737373",
                minHeight: "48px",
              }}
              name="sub_category"
              placeholder="Sub category"
              selection
              search
              options={this.state.categoryDropdownOptions.sub_category}
              onChange={this.handleOnCategoryChange}
              value={this.state.searchFilters.sub_category}
            />
          </GridItem>
          <GridItem size={{ xs: "1/5" }}>
            <Dropdown
              style={{
                width: "100%",
                backgroundColor: "transparent",
                padding: "16px 8px 16px",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "2px",
                color: "#737373",
                minHeight: "48px",
              }}
              name="segment"
              placeholder="Segment"
              selection
              search
              options={this.state.categoryDropdownOptions.segment}
              onChange={this.handleOnCategoryChange}
              value={this.state.searchFilters.segment}
            />
          </GridItem>
        </GridWrapper>
      </div>
    );
  };

  buildGroupNameStatusTag = () => {
    return (
      <div style={{ float: "right" }}>
        <Label>
          <InfoCircle className="ln-u-margin-left" />{" "}
          {this.state.viewingGroup.name} [{this.state.viewingGroup.status}]
        </Label>
      </div>
    );
  };

  buildSelectedProductsWidget = () => {
    return (
      <div style={{ float: "right" }} key="selectedProducts">
        <OutlinedButton onClick={() => this.setState({ selectedProducts: [] })}>
          <Cancel /> de-select
        </OutlinedButton>
        <InfoCircle className="ln-u-margin-left" />
        <Label className="ln-u-margin-left">
          {this.state.selectedProducts.length} products selected.
        </Label>
      </div>
    );
  };

  buildSelectedGroupsWidget = () => {
    return (
      <div style={{ float: "right" }} key={"selectedGroups"}>
        <OutlinedButton onClick={() => this.setState({ selectedGroups: [] })}>
          <Cancel /> de-select
        </OutlinedButton>
        <InfoCircle className="ln-u-margin-left" />
        <Label className="ln-u-margin-left">
          {this.state.selectedGroups.length} groups selected.
        </Label>
      </div>
    );
  };

  buildCreateGroupInput = () => {
    return (
      <div style={{ float: "right", width: "30%" }} key={"createGroupInput"}>
        <GridItem style={{ display: "flex", height: "3rem" }}>
          <TextInput
            placeholder="Group name..."
            onChange={this.handleOnCreateGroupInputChange}
            value={this.state.createGroupInput}
          />
          <FilledButton
            style={{ width: "50%" }}
            className="ln-u-margin-left ln-u-margin-right*4"
            onClick={this.handleOnCreateGroupInputSubmit}
          >
            Create Group
          </FilledButton>
        </GridItem>
      </div>
    );
  };

  render() {
    const {
      buildGroupDetails,
      buildGroupList,
      buildproductSearch,
      buildCategorySelectors,
      buildGroupNameStatusTag,
      buildSelectedGroupsWidget,
      buildSelectedProductsWidget,
      buildCreateGroupInput,
    } = this;

    return (
      <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
        <GridWrapper>
          <GridItem>
            <div style={{ float: "left" }}>
              <Basket />
              &nbsp;&nbsp;
              <Label htmlFor="">
                {this.state.productMode
                  ? "Products"
                  : this.state.groupMode
                  ? "Groups"
                  : this.state.groupDetailMode
                  ? "Group detail"
                  : ""}
              </Label>
            </div>

            <Transition.Group
              animation="fade right"
              duration={TRANSITION_DURATION}
            >
              {this.state.productMode && buildSelectedProductsWidget()}{" "}
            </Transition.Group>
            <Transition.Group
              animation="fade right"
              duration={TRANSITION_DURATION}
            >
              {this.state.groupMode && [
                buildSelectedProductsWidget(),
                buildSelectedGroupsWidget(),
                buildCreateGroupInput(),
              ]}
            </Transition.Group>
            <Transition.Group
              animation="fade right"
              duration={TRANSITION_DURATION}
            >
              {this.state.groupDetailMode && buildGroupNameStatusTag()}
            </Transition.Group>
          </GridItem>
          <hr
            style={{
              border: "1px 0 0 0 solid grey",
              margin: "1rem 0rem 0rem 1rem",
            }}
          />
          <GridItem>
            <Transition.Group
              animation="fade up"
              duration={TRANSITION_DURATION}
            >
              {this.state.productMode && buildCategorySelectors()}
            </Transition.Group>
          </GridItem>

          <GridItem>
            <Modal
              open={this.state.confirmOpen}
              fullScreen
              restrictClose
              alert
              headingId="dialog-modal"
              className="modal-overflow"
            >
              <ModalHeading element="h3">
                {this.state.confirmMessage}
              </ModalHeading>
              <ButtonGroupPrimary>
                <FilledButton onClick={this.handleConfirm}>Ok</FilledButton>
              </ButtonGroupPrimary>
            </Modal>

            <Transition.Group
              animation="fade up"
              duration={TRANSITION_DURATION}
            >
              {this.state.productMode && buildproductSearch()}
            </Transition.Group>
            <Transition.Group
              animation="fade up"
              duration={TRANSITION_DURATION}
            >
              {this.state.groupMode && buildGroupList()}
            </Transition.Group>
            <Transition.Group
              animation="fade up"
              duration={TRANSITION_DURATION}
            >
              {this.state.groupDetailMode && buildGroupDetails()}
            </Transition.Group>
          </GridItem>
        </GridWrapper>
      </Card>
    );
  }
}

export default ProductsView;
