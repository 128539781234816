import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Form, Icon, Image } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Checkbox, Label, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Plus } from "@jsluna/icons";
import { Modal } from "@jsluna/modal";
import { TabLink, TabPanel, Tabs } from "@jsluna/tabs";

import { footerImageListConfig } from "../PodFooterImageList";
import { PlaceholderImage } from "../pod";
import "./PodFooterImagesInput.scss";

const PodFooterImagesInput = ({ footerImages, addNewImage, removeImage }) => {
  const [newFooterImage, setNewFooterImage] = useState({
    altText: "",
    imageUrl: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedRowMap, setSelectedRowMap] = useState(new Map());
  const numOfSelected = selectedRowMap && selectedRowMap.size;

  const tabs = [
    { name: "Choose existing", key: "existFooter" },
    { name: "Add new", key: "newFooter" },
  ];

  const [activeTab, setActiveTab] = useState("existFooter");

  useEffect(() => {
    if (activeTab) {
      numOfSelected > 0 && selectedRowMap.clear();
      setNewFooterImage({ altText: "", imageUrl: "" });
    }
    // eslint-disable-next-line
  }, [activeTab]);

  const isSCheckBoxelected = (id) => {
    const selectedRow = selectedRowMap && selectedRowMap.get(id);
    return selectedRow
      ? selectedRowMap && selectedRowMap.get(id).selected
      : false;
  };

  const handleSelectRow = (selected, row) => {
    if (selected) {
      if (numOfSelected === 0) {
        setSelectedRowMap(
          new Map().set(row.id, {
            data: row,
            selected,
          })
        );
      }
      setSelectedRowMap((prev) =>
        new Map(prev).set(row.id, {
          data: row,
          selected,
        })
      );
    } else {
      setSelectedRowMap((prev) => {
        const newState = new Map(prev);
        newState.delete(row.id);
        return newState;
      });
    }
  };

  const handleSelectAllRows = (selected) => {
    if (selected) {
      footerImageListConfig &&
        // eslint-disable-next-line
        footerImageListConfig.map((row) => {
          setSelectedRowMap((prev) =>
            new Map(prev).set(row.id, {
              data: row,
              selected,
            })
          );
        });
    } else {
      footerImageListConfig &&
        // eslint-disable-next-line
        footerImageListConfig.map((row) => {
          setSelectedRowMap((prev) => {
            const newState = new Map(prev);
            newState.delete(row.id);
            return newState;
          });
        });
    }
  };

  const isAllCheckBoxSelected = () => {
    let allSelected = false;

    allSelected =
      footerImageListConfig &&
      footerImageListConfig
        .map((row) => row.id)
        .every(function (element) {
          return Array.from(selectedRowMap.keys()).includes(element);
        });

    return allSelected;
  };

  const handleFooterAltTextChange = (event) => {
    const { value } = event.target;
    setNewFooterImage((prevState) => ({ ...prevState, altText: value }));
  };

  const handleFooterImageUrlChange = (event) => {
    const { value } = event.target;
    setNewFooterImage((prevState) => ({ ...prevState, imageUrl: value }));
  };

  const submitNewFooterImage = () => {
    addNewImage(newFooterImage);
    setNewFooterImage({ altText: "", imageUrl: "" });
    setIsOpen(false);
    setActiveTab("existFooter");
    numOfSelected > 0 && selectedRowMap.clear();
  };

  const submitExistingFooterImage = () => {
    selectedRowMap.forEach((value) => {
      addNewImage({
        altText: value.data.altText,
        imageUrl: value.data.imageUrl,
      });
    });
    setIsOpen(false);
    numOfSelected > 0 && selectedRowMap.clear();
  };

  const cancelExistingFooterImage = () => {
    setIsOpen(false);
    numOfSelected > 0 && selectedRowMap.clear();
    setActiveTab("existFooter");
  };

  const podFooterImages = footerImages.map((podFooterImage, i) => (
    <tr className="ln-c-table__row ln-c-table__header-row" key={i}>
      <td className="ln-c-table__cell">{podFooterImage.altText}</td>
      <td className="ln-c-table__cell">{podFooterImage.imageUrl}</td>
      <td className="ln-c-table__cell">
        <Image
          src={
            !isEmpty(podFooterImage.imageUrl)
              ? podFooterImage.imageUrl
              : PlaceholderImage
          }
          size="mini"
        />
      </td>
      <td className="ln-c-table__cell">
        <OutlinedButton
          color={"red"}
          onClick={() => removeImage(podFooterImage.altText, i)}
        >
          Remove Footer
        </OutlinedButton>
      </td>
    </tr>
  ));

  const footerImagesList = footerImageListConfig.map((image, i) => (
    <tr className="ln-c-table__row ln-c-table__header-row" key={i}>
      <td>
        <Checkbox
          name={`entry-${image.id}`}
          inline
          label={`Select ${image.altText}`}
          hideLabel
          checked={isSCheckBoxelected(image.id)}
          onChange={(e) => handleSelectRow(e.target.checked, image)}
        />
      </td>
      <td className="ln-c-table__cell">{image.altText}</td>
      <td className="ln-c-table__cell">
        <Image
          src={!isEmpty(image.imageUrl) ? image.imageUrl : PlaceholderImage}
          size="mini"
        />
      </td>
    </tr>
  ));

  return (
    <div>
      <span>
        <strong>
          <Icon circular name="window minimize" />
          &nbsp;&nbsp;<Label>Footer images</Label>
        </strong>
      </span>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "25px",
        }}
      >
        <FilledButton onClick={() => setIsOpen(true)}>
          <Plus />
          Select Footer Images
        </FilledButton>
      </div>
      <div>
        <table className="ln-c-table" style={{ marginBottom: "10px" }}>
          <thead className="ln-c-table__header">
            <tr className="ln-c-table__row ln-c-table__header-row">
              <th className="ln-c-table__header-cell customCol">
                Name/Alternative Text
              </th>
              <th className="ln-c-table__header-cell customCol">Image url</th>
              <th className="ln-c-table__header-cell customCol">Preview</th>
              <th className="ln-c-table__header-cell customCol">
                Remove Footer
              </th>
            </tr>
          </thead>
          <tbody className="ln-c-table__body">
            {footerImages.length >= 0 ? (
              podFooterImages
            ) : (
              <tr className="ln-c-table__row ln-c-table__header-row">
                <td className="ln-c-table__cell">No Footer Images to show</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Modal
        fullScreen
        restrictClose
        alert
        handleClose={() => setIsOpen(false)}
        open={isOpen}
        headingId="dialog-modal"
        className="footer-image-selection-modal"
      >
        <Tabs type="fill">
          {tabs.map((tab) => (
            <TabLink
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
              active={activeTab === tab.key}
            >
              {tab.name}
            </TabLink>
          ))}
        </Tabs>
        <TabPanel>
          <Card>
            {activeTab === "existFooter" ? (
              <>
                <table className="ln-c-table" style={{ marginBottom: "10px" }}>
                  <thead className="ln-c-table__header">
                    <tr className="ln-c-table__row ln-c-table__header-row">
                      <th>
                        <Checkbox
                          name="all-entries"
                          inline
                          label="Select all / none"
                          hideLabel
                          checked={isAllCheckBoxSelected()}
                          onChange={(e) =>
                            handleSelectAllRows(e.target.checked)
                          }
                        />
                      </th>
                      <th className="ln-c-table__header-cell customCol">
                        Name/Alternative Text
                      </th>
                      <th className="ln-c-table__header-cell customCol">
                        Preview
                      </th>
                    </tr>
                  </thead>
                  <tbody className="ln-c-table__body">
                    {footerImageListConfig.length >= 0 ? (
                      footerImagesList
                    ) : (
                      <tr className="ln-c-table__row ln-c-table__header-row">
                        <td className="ln-c-table__cell">
                          No Footer Images to show
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <ButtonGroupWrapper actionbar>
                  <ButtonGroupPrimary>
                    <OutlinedButton
                      className="ln-u-margin-right"
                      onClick={cancelExistingFooterImage}
                    >
                      Cancel
                    </OutlinedButton>
                    <FilledButton
                      onClick={submitExistingFooterImage}
                      disabled={numOfSelected === 0}
                    >
                      Select
                    </FilledButton>
                  </ButtonGroupPrimary>
                </ButtonGroupWrapper>
              </>
            ) : (
              <Form onSubmit={submitNewFooterImage}>
                <GridWrapper>
                  <GridItem size={{ xs: "1/3" }}>
                    <TextInputField
                      name="image-name"
                      label="Name/Alternative Text"
                      onChange={handleFooterAltTextChange}
                      value={newFooterImage.altText}
                    />
                  </GridItem>
                  <GridItem size={{ xs: "2/3" }}>
                    <TextInputField
                      name="imageUrl"
                      label="Image URL"
                      onChange={handleFooterImageUrlChange}
                      value={newFooterImage.imageUrl}
                    />
                  </GridItem>
                </GridWrapper>
                <ButtonGroupWrapper actionbar>
                  <ButtonGroupPrimary>
                    <OutlinedButton
                      className="ln-u-margin-right"
                      onClick={cancelExistingFooterImage}
                    >
                      Cancel
                    </OutlinedButton>
                    <FilledButton
                      type="submit"
                      disabled={
                        !(newFooterImage.imageUrl && newFooterImage.altText)
                      }
                    >
                      Add Footer image
                    </FilledButton>
                  </ButtonGroupPrimary>
                </ButtonGroupWrapper>
              </Form>
            )}
          </Card>
        </TabPanel>
      </Modal>
    </div>
  );
};

export default PodFooterImagesInput;
