import { HttpClient, Method, encodeQuery, managementApi } from "./http";

export class GS1SupplierCodesClient extends HttpClient {
  addCode(body) {
    return this.request(Method.POST, managementApi("/gs1/supplier"), body);
  }

  toggleStatus(gs1code) {
    return this.request(
      Method.PATCH,
      managementApi(`/gs1/suppliers/${gs1code}/active`)
    );
  }

  fetchCodes(page, size) {
    return this.request(
      Method.GET,
      managementApi(
        `/gs1/suppliers${encodeQuery({
          page: page,
          size: size,
        })}`
      )
    );
  }

  fetchUsage(gs1Code) {
    return this.request(
      Method.GET,
      managementApi(
        `/gs1/suppliers/${gs1Code}${encodeQuery({
          usageInfo: true,
        })}`
      )
    );
  }
}

export default GS1SupplierCodesClient;
