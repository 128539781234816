export const InitialState = () => {
  return {
    id: null,
    description: null,
    details: null,
    displayType: "NORMAL",
  };
};

export const DisplayType = [
  { text: "Normal", value: "NORMAL" },
  { text: "Bonus", value: "BONUS" },
  { text: "Base rate", value: "BASERATE" },
];
