import classnames from "classnames";
import React from "react";

import { Card } from "@jsluna/card";
import { GridWrapper } from "@jsluna/grid";

import "./PageContainer.scss";

const PageContainer = ({ children, className }) => (
  <Card className={classnames("management_portal_page_container", className)}>
    <GridWrapper matrix>{children}</GridWrapper>
  </Card>
);

export default PageContainer;
