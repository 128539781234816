import PropTypes from "prop-types";
import React, { Component } from "react";

export class CustomerPointsManager extends Component {
  static propTypes = {
    customerBalance: PropTypes.number,
  };

  state = {
    disabled: false,
    reasons: undefined,
  };

  render() {
    if (this.props.customerBalance === undefined) {
      return (
        <div>
          <span>Balance from Nectar unavailable</span>
        </div>
      );
    }
    return (
      <div>
        <div>
          <h4>
            <b>Points balance : {this.props.customerBalance}</b>
          </h4>
        </div>
      </div>
    );
  }
}

export default CustomerPointsManager;
