import classnames from "classnames";

import { Text } from "@jsluna/typography";

import "./StatusIndicator.scss";

const possibleVariants = [
  "error",
  "alpha",
  "disabled",
  "warning",
  "info",
  "success",
];

const StatusIndicator = ({ variant, text, className }) => {
  if (!possibleVariants.includes(variant)) {
    variant = possibleVariants[0];
  }
  return (
    <div
      className={classnames(
        "management-portal-status-indicator",
        `management-portal-status-indicator__${variant}`,
        className
      )}
    >
      <Text element="span" typeStyle="label-1">
        {text}
      </Text>
    </div>
  );
};
export default StatusIndicator;
