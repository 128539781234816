export const defaultDateTimeFormat = (dateString) => {
  return new Date(dateString).toLocaleString("en-GB");
};

export const toDisplayString = (dateString, format) => {
  const date = new Date(dateString);
  // Removes unusual comma between date and time that toLocaleString adds, and doesn't display seconds
  return (
    date.toLocaleDateString() +
    " " +
    date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
};

export const toApiFormat = (date) => {
  return date.toISOString().split(".")[0] + "Z";
};
