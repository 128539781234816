/**
 * Formats Nectar card number with spaces
 *
 * Takes both 19 digit (with prefix) and 11 digit (without prefix) numbers
 * Optionally adds second space for the last 3 digits (default true)
 */
export const formatNectarNumber = (cardNumber, secondSpace = false) => {
  let number = cardNumber.replace(/\s+/g, "");

  if (number.length === 19) {
    return (
      number.slice(0, 8) +
      " " +
      number.slice(8, 16) +
      (secondSpace ? " " : "") +
      number.slice(16)
    );
  } else if (number.length === 11) {
    return number.slice(0, 8) + (secondSpace ? " " : "") + number.slice(8);
  }

  return cardNumber;
};
