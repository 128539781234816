import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Icon, Message } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Cancel, Plus } from "@jsluna/icons";

import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import OfferCodeClient from "../../services/OfferCodeClient";
import OfferCodeEditor from "./OfferCodeEditor";

const offerCodeClient = new OfferCodeClient();

const viewStates = Object.freeze({
  EDIT: Symbol("Edit"),
  LIST: Symbol("List"),
});

const OfferCodeView = () => {
  const [offerCodes, setOfferCodes] = useState([]);
  const [error, setError] = useState(undefined);
  const [viewState, setViewState] = useState(viewStates.LIST);
  const [currentOfferCodeId, setCurrentOfferCodeId] = useState(null);
  const navigate = useNavigate();

  const isOfferCodeEditor = HasRequiredRoleAccess("offercodes");

  useEffect(() => {
    reloadOfferCodes();
  }, []);

  const reloadOfferCodes = () => {
    offerCodeClient
      .fetchOfferCodes()
      .then(({ data }) => {
        setOfferCodes(data);
      })
      .catch((err) => {
        setError("Failed to load offer codes list: " + err);
      });
  };

  const deleteOfferCode = (id) => {
    offerCodeClient.deleteOfferCode(id).then(() => {
      toast.success(`Deleted offer code ${id ? ` '${id}'` : ""}`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 2000,
      });
      reloadOfferCodes();
    });
  };

  const onEditClick = (id) => {
    setCurrentOfferCodeId(id);
    setViewState(viewStates.EDIT);
    navigate("/offercodes");
  };

  const onCreateClick = () => {
    setCurrentOfferCodeId(null);
    setViewState(viewStates.EDIT);
    navigate("/offercodes");
  };

  const currentView = () => {
    const props = {
      onEditClick,
      deleteOfferCode,
    };

    switch (viewState) {
      case viewStates.EDIT:
        return (
          <OfferCodeEditor
            key={currentOfferCodeId}
            currentOfferCodeId={currentOfferCodeId}
            close={() => {
              setViewState(viewStates.LIST);
              reloadOfferCodes();
            }}
            isOfferCodeEditor={isOfferCodeEditor}
          />
        );
      case viewStates.LIST:
        return (
          <div>
            <ButtonGroupPrimary>
              <FilledButton
                style={{ height: "100%" }}
                onClick={onCreateClick}
                disabled={!isOfferCodeEditor}
              >
                <Plus />
                &nbsp;&nbsp;Create Offer Code
              </FilledButton>
            </ButtonGroupPrimary>
            <OfferCodesList offerCodeData={offerCodes} {...props} />
          </div>
        );
      default:
        return <div />;
    }
  };

  if (!offerCodes && !error) {
    return null;
  }

  return (
    <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
      <GridWrapper>
        <GridItem>
          <span>
            <Icon circular name="microchip" />
            &nbsp;&nbsp;<Label>Offer Codes</Label>
          </span>
        </GridItem>
        <GridItem>{currentView()}</GridItem>
      </GridWrapper>
    </Card>
  );
};

const OfferCodesList = ({ offerCodeData, onEditClick, deleteOfferCode }) => {
  if (offerCodeData.error)
    return <ErrorLoadingOfferCode message={offerCodeData.error} />;

  offerCodeData.sort(compareOfferCodes);

  return (
    <OfferCodeTable>
      <OfferCodeTableHeader />
      <OfferCodeTableBody>
        {map(offerCodeData, (p) => (
          <OfferCodeRow
            deleteOfferCode={deleteOfferCode}
            offerCode={p}
            key={p.id}
            onClick={() => {
              onEditClick(p.id);
            }}
          />
        ))}
      </OfferCodeTableBody>
    </OfferCodeTable>
  );
};

function compareOfferCodes(a, b) {
  return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
}

const OfferCodeTableHeader = () => (
  <thead className="ln-c-table__header">
    <tr className="ln-c-table__row ln-c-table__header-row">
      {[
        "Offer Code Id",
        "Display type",
        "Details",
        "Description",
        "Remove",
      ].map((header, idx) => (
        <th className="ln-c-table__header-cell customCol" key={idx}>
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

const OfferCodeTable = (props) => (
  <div style={{ paddingTop: "4.5rem" }}>
    <table className="ln-c-table" celled striped>
      {props.children}
    </table>
  </div>
);

const OfferCodeTableBody = (props) => (
  <tbody className="ln-c-table__body">{props.children}</tbody>
);

const OfferCodeRow = (props) => {
  const isOfferCodeEditor = HasRequiredRoleAccess("offercodes");
  const { offerCode, onClick } = props;
  const errors = [];
  return (
    <tr className="ln-c-table__row">
      <td className="ln-c-table__cell">
        <h4>
          <Button onClick={onClick}>
            {offerCode.id ? offerCode.id : "Undefined"}
            {isEmpty(errors) ? null : (
              <span>
                &nbsp;
                <Icon
                  title="Invalid offer code"
                  name="warning sign"
                  color="orange"
                />
              </span>
            )}
          </Button>
        </h4>
      </td>
      <td className="ln-c-table__cell">{offerCode.displayType}</td>
      <td className="ln-c-table__cell">{offerCode.details}</td>
      <td className="ln-c-table__cell">{offerCode.description}</td>
      <td className="ln-c-table__cell" collapsing>
        <OutlinedButton
          disabled={!isOfferCodeEditor}
          onClick={() => {
            if (
              window.confirm("Are you sure you wish to delete this offer code?")
            ) {
              props.deleteOfferCode(offerCode.id);
            }
          }}
        >
          <Cancel />
        </OutlinedButton>
      </td>
    </tr>
  );
};

const ErrorLoadingOfferCode = (props) => (
  <Message negative>
    <p>{props.message}</p>
  </Message>
);

export default OfferCodeView;
