import { useState } from "react";

import { LoadingState } from "../../services/http";

export const useAsyncActionState = (preventConcurrent = true) => {
  const [actionState, setActionState] = useState(LoadingState.PENDING);
  const performAction = async (action) => {
    if (preventConcurrent && actionState === LoadingState.IN_PROGRESS) {
      console.log(
        "Another action is in progress, the UI for triggering this one should be blocked"
      );
      return;
    }
    setActionState(LoadingState.IN_PROGRESS);
    try {
      await action();
      setActionState(LoadingState.SUCCESS);
    } catch (err) {
      console.error(err);
      setActionState(LoadingState.FAILED);
    }
  };
  return [actionState, performAction];
};
