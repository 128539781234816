import React from "react";

import { IconButton } from "@jsluna/button";
import { ArrowLeft, ArrowRight } from "@jsluna/icons";
import { Text } from "@jsluna/typography";

import "./Pagination.scss";

const Pagination = ({ currentPage, totalPages, onPageChange, smallText }) => {
  const disableNextPage = currentPage >= totalPages;
  const disablePreviousPage = currentPage <= 1;
  const textStyle = smallText ? "label-1" : "display-1";
  return (
    <div className="management_portal_pagination">
      <IconButton
        variant="outlined"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={disablePreviousPage}
        label="Previous"
        hideLabel
      >
        <ArrowLeft />
      </IconButton>
      <Text element="span" typeStyle={textStyle}>
        Page {currentPage} of {totalPages}
      </Text>
      <IconButton
        variant="outlined"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={disableNextPage}
        label="Next"
        hideLabel
      >
        <ArrowRight />
      </IconButton>
    </div>
  );
};

export default Pagination;
