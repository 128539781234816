import { useState } from "react";

/** Convenience hook for simplifying controlled form logic and validation */
export const useForm = ({
  skipValidation = false,
  immediateValidation = false,
} = {}) => {
  const allFields = [];
  const [shouldBlockSubmit, setShouldBlockSubmit] =
    useState(immediateValidation);

  const useField = ({
    /** The initial value for the field. */
    initialValue,
    /** Determines if the field is disabled. Defaults to false. */
    disabled = false,
    /** If the field is not disabled, should there be a required validation?. Defaults to true. */
    requiredIfActive = true,
    /** Options for a select component, will be returned by the hook, and the value will be validated against this list if passed. */
    options,
    /** A list of validation rules.
     * Expects a list of tuples in the form [assertion, assertionErrorMessage]
     * where the assertion is a function that accepts the value and returns a boolean */
    validations = [],
  }) => {
    let [value, setValue] = useState(initialValue);
    const [touched, setTouched] = useState(false);
    let error = null;

    // If there's a single available option, then we can just pretend they've selected it
    if (options) {
      const enabledOptions = options.filter((o) => o.disabled !== true);
      if (enabledOptions.length === 1) {
        value = enabledOptions[0].value;
      }
    }

    // If there is a required value, and nothing set, then set an error
    if (requiredIfActive) {
      if (value === "" || value === null || value === undefined) {
        error = "This field is required";
      }

      // A nicer error message when multiple values can be added
      if (value === []) {
        error = "A value must be selected";
      }
    }

    if (!error) {
      // If the current value somehow doesn't match an option, or the selected option is disabled, show an error
      if (options && value) {
        const selected = options.find((t) => t.value === value);
        if (!selected || selected.disabled) {
          error = "Please select a valid option";
        }
      }
    }

    if (!error) {
      // Apply validation rules for the field, stopping at the first error
      for (const [assertion, assertionError] of validations) {
        if (!assertion(value)) {
          error = assertionError;
          break;
        }
      }
    }

    // If the field is disabled, then ignore validation for it
    error = disabled ? null : error;

    const props = {
      value,
      error: shouldBlockSubmit || touched ? error : null,
      onChange: (...args) => {
        setTouched(true);
        setValue(...args);
      },
      options,
      disabled,
      onBlur: () => setTouched(true),
    };
    const field = {
      changed: JSON.stringify(value) !== JSON.stringify(initialValue), // Assuming that initialValue won't change, we can track it in state if we ever do that
      value,
      disabled,
      errorMessage: error,
      props,
    };
    allFields.push(field);

    return field;
  };

  const anyErrors = () => allFields.some((f) => !!f.errorMessage);
  const anyChanges = () => allFields.some((f) => f.changed);

  return {
    /** Hook to add a form field */
    useField,
    /** Function that returns true if there are any validation errors in the form. Requires all usage of useField to be done before calling */
    anyValidationErrors: () => !skipValidation && anyErrors(),
    /** Function that returns true if any changes have been made to the form */
    anyChanges,
    /** Whether you should block submit and show an alert or not */
    shouldBlockSubmit,
    /** Accepts a callback that will be called with the original arguments if there are no form errors */
    onSubmit:
      (userFunc) =>
      (...args) => {
        setShouldBlockSubmit(true);
        if (!skipValidation && anyErrors()) {
          return;
        }
        userFunc(...args);
      },
  };
};
