import React from "react";
import { toast } from "react-toastify";

import {
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { FieldInfo, Form, FormGroup } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";
import { Table } from "@jsluna/table";
import { ToggleButton, ToggleButtonGroup } from "@jsluna/toggle-button";
import { Display1 } from "@jsluna/typography";

import { PromotionsClient } from "../../services/PromotionsClient";
import { LoadingState } from "../../services/http";
import { useForm } from "../hooks/useForm";
import { useSearch } from "../hooks/useSearch";
import { SingleSelectField, TextField } from "./FormFields";
import Pagination from "./Pagination";
import "./ProductSelector.scss";

const promotionsClient = new PromotionsClient();

const searchProducts = async (page, filters) => {
  try {
    if (filters.productType === "sku") {
      if (filters.searchType === "Name") {
        const response = await promotionsClient.searchProduct(
          filters.searchInput,
          page
        );
        if (response.status !== 200) {
          throw new Error(response);
        }
        return {
          totalPages: response.data.totalPages,
          products: response.data.products.map((c) => ({
            id: c.sku,
            description: c.skuDescription,
            type: "sku",
          })),
        };
      } else {
        const response = await promotionsClient.fetchSku(filters.searchInput);
        if (response.status !== 200) {
          throw new Error(response);
        }
        return {
          totalPages: 1,
          products: [
            {
              id: response.data.sku,
              description: response.data.shortDescription,
              type: "sku",
            },
          ],
        };
      }
    }
    if (filters.productType === "subcategory") {
      if (filters.searchType === "Name") {
        throw new Error("Name search for subcategory is not supported");
      } else {
        const response = await promotionsClient.fetchSubCategory(
          filters.searchInput
        );
        if (response.status !== 200) {
          throw new Error(response);
        }
        return {
          totalPages: 1,
          products: [
            {
              id: response.data.subCategoryLegacyID,
              description: response.data.subCategoryName,
              type: "subcategory",
            },
          ],
        };
      }
    }
    throw new Error("Unexpected product type");
  } catch (err) {
    console.error(err);
    const message = err?.response?.data?.description ?? "";
    toast.error("An error occurred when searching. " + message);
    throw err;
  }
};

export const ProductSelector = ({
  value,
  error,
  disabled,
  onChange,
  showSkuSearch = true,
  showSubcategorySearch = true,
}) => {
  const { requestedPage, listResults, listState, changePage, newSearch } =
    useSearch(searchProducts, false);
  const { useField, anyValidationErrors } = useForm();

  const productType = useField({
    initialValue: "",
    options: [
      { label: "SKU", value: "sku", disabled: !showSkuSearch },
      {
        label: "Sub-category",
        value: "subcategory",
        disabled: !showSubcategorySearch,
      },
    ],
    disabled: disabled,
  });

  const searchInput = useField({
    initialValue: "",
    disabled: disabled,
  });

  const searchType = useField({
    initialValue: "Name",
    options: [
      {
        label: "Name",
        value: "Name",
        disabled: productType.value === "subcategory",
      },
      { label: "Number", value: "Number" },
    ],
    disabled: disabled,
  });

  const onSearchSubmit = (e) => {
    e.preventDefault();
    newSearch({
      searchInput: searchInput.value,
      searchType: searchType.value,
      productType: productType.value,
    });
  };
  const showProductType = showSkuSearch && showSubcategorySearch;

  const onSelect = (item) => {
    if (value.findIndex((row) => row.id === item.id) === -1) {
      return onChange([...value, item]);
    } else {
      return onChange(value.filter((row) => row.id !== item.id));
    }
  };

  const validationErrors = anyValidationErrors();

  return (
    <GridWrapper className="management-portal-product-selector">
      <Form onSubmit={onSearchSubmit}>
        <FormGroup name="form-group-3" hideLabel label="Form group">
          {showProductType && (
            <GridItem size={{ md: "1/3" }}>
              <SingleSelectField
                label="Products"
                name="products"
                placeholder="Select product type"
                {...productType.props}
              />
            </GridItem>
          )}
          <GridItem size={{ md: showProductType ? "2/3" : "1/1" }}>
            <GridWrapper>
              <GridItem size={{ md: "2/3" }}>
                <TextField
                  label={"Search by: " + searchType.props.value}
                  name="searchInput"
                  placeholder={"Enter " + searchType.props.value}
                  {...searchInput.props}
                />
              </GridItem>
              <GridItem size={{ md: "1/3" }}>
                <ButtonGroupWrapper>
                  <ButtonGroupSecondary>
                    <ToggleButtonGroup
                      className="ln-u-margin-top"
                      value={searchType.props.value}
                      onChange={(_, value) => searchType.props.onChange(value)}
                      disabled={disabled}
                    >
                      {searchType.props.options
                        .filter((o) => !o.disabled)
                        .map((o) => (
                          <ToggleButton key={o.value} value={o.value}>
                            {o.label}
                          </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                  </ButtonGroupSecondary>
                  <ButtonGroupPrimary>
                    <FilledButton
                      className="ln-u-margin-top"
                      onClick={onSearchSubmit}
                      onSubmit={onSearchSubmit}
                      disabled={disabled || validationErrors}
                      type="submit"
                    >
                      Search
                    </FilledButton>
                  </ButtonGroupPrimary>
                </ButtonGroupWrapper>
              </GridItem>
            </GridWrapper>
          </GridItem>
        </FormGroup>
      </Form>
      <GridItem size={{ lg: "1/2" }}>
        <Display1>Search results</Display1>
        {listState === LoadingState.IN_PROGRESS ? (
          <div className="result-container">
            <ProgressIndicator
              className="loading-indicator"
              loading
              preventFocus
            >
              <ProgressBar color="light" />
              Loading...
            </ProgressIndicator>
          </div>
        ) : listState === LoadingState.FAILED ? (
          <div className="result-container">
            <div className="empty-message">
              An error occurred while searching for products
            </div>
          </div>
        ) : listState === LoadingState.PENDING ? (
          <div className="result-container">
            <div className="empty-message">Search to select</div>
          </div>
        ) : listResults.products.length < 1 ? (
          <div className="result-container">
            <div className="empty-message">No results found</div>
          </div>
        ) : (
          <>
            <div className="result-container">
              <Table
                data={listResults.products}
                rowKey="id"
                caption="Promotion Search results"
                visuallyHiddenCaption
                columns={[
                  {
                    name: "ID",
                    accessor: (item) => ({ value: item.id }),
                  },
                  {
                    name: "Description",
                    accessor: (item) => ({ value: item.description }),
                  },
                  {
                    name: "",
                    accessor: (item) => {
                      const isSelected = value.some(
                        (selection) =>
                          selection.type === item.type &&
                          selection.id === item.id
                      );
                      return {
                        value: (
                          <ToggleButtonGroup
                            alpha
                            value={isSelected ? "Unselect" : "Select"}
                            onChange={() => onSelect(item)}
                            disabled={disabled}
                          >
                            <ToggleButton value="Select">Select</ToggleButton>
                            <ToggleButton value="Unselect">
                              Unselect
                            </ToggleButton>
                          </ToggleButtonGroup>
                        ),
                      };
                    },
                  },
                ]}
              />
            </div>
            <Card>
              <Pagination
                currentPage={requestedPage}
                totalPages={listResults.totalPages}
                onPageChange={changePage}
                smallText
              />
            </Card>
          </>
        )}
      </GridItem>
      <GridItem size={{ lg: "1/2" }}>
        <Display1 className="selector-header">
          Selected products
          <span>Total: {value?.length || 0}</span>
        </Display1>
        {error && <FieldInfo error>{error}</FieldInfo>}

        <div className="result-container">
          {value.length <= 0 ? (
            <div className="empty-message">Select from search result</div>
          ) : (
            <Table
              data={value}
              rowKey="id"
              caption="Promotion selected products"
              visuallyHiddenCaption
              columns={[
                {
                  name: "ID",
                  accessor: (item) => ({ value: item.id }),
                },
                {
                  name: "Description",
                  accessor: (item) => ({ value: item.description }),
                },
                {
                  name: "",
                  accessor: (item) => {
                    return {
                      value: (
                        <ToggleButtonGroup
                          alpha
                          value="Unselect"
                          onChange={() => onSelect(item)}
                          disabled={disabled}
                        >
                          <ToggleButton value="Select">Select</ToggleButton>
                          <ToggleButton value="Unselect">Unselect</ToggleButton>
                        </ToggleButtonGroup>
                      ),
                    };
                  },
                },
              ]}
            />
          )}
        </div>
      </GridItem>
    </GridWrapper>
  );
};
