import React, { useState } from "react";

import { IconButton, TextButton } from "@jsluna/button";
import { SelectField, TextAreaField, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Plus } from "@jsluna/icons";
import { Accordion, AccordionItem } from "@jsluna/react";

import { TransactionType, stringifyNumber } from "./Constants";

const EventBuilder = ({
  addTask,
  removeTask,
  handleAddTaskVal,
  handleTransactionValueChange,
  taskArrVal,
  handleTransactionTypeValueChange,
  tranTypeInputRef,
  handleSkuValueChange,
}) => {
  const [trackerNameSelectedError, setTrackerNameSelectedError] =
    useState(false);
  const [
    transactionTargetypeSelectedError,
    setTransactionTargetypeSelectedError,
  ] = useState(false);
  const [maximumPointsSelectedError, setMaximumPointsSelectedError] =
    useState(false);

  const handleOnBlur = (event) => {
    if (event && event.target && event.target.name === "trackerName") {
      setTrackerNameSelectedError(false);
      if (event.target.value.trim() === "") setTrackerNameSelectedError(true);
    }

    if (event && event.target && event.target.name === "trackerPointsReward") {
      setMaximumPointsSelectedError(false);
      if (event.target.value.trim() === "") setMaximumPointsSelectedError(true);
    }

    if (
      event &&
      event.target &&
      event.target.name === "trackerTargetUnitType"
    ) {
      setTransactionTargetypeSelectedError(false);
      if (event.target.value.trim() === "")
        setTransactionTargetypeSelectedError(true);
    }
  };

  const tranTypeNameFinder = (name) => {
    if (name === "TRANSACTION_UNITS") return "totalTransactionUnits";
    else if (name === "TRANSACTION_COUNT") return "totalTransactionCount";
    else if (name === "TRANSACTION_SPEND") return "totalTransactionSpend";
  };

  const tranTypeValueFinder = (task) => {
    if (task && task.trackerTargetUnitType === "TRANSACTION_UNITS") {
      return task.totalTransactionUnits;
    } else if (task && task.trackerTargetUnitType === "TRANSACTION_COUNT") {
      return task.totalTransactionCount;
    } else if (task && task.trackerTargetUnitType === "TRANSACTION_SPEND") {
      return task.totalTransactionSpend;
    }
  };

  const tranTypelabelFinder = (name) => {
    if (name === "TRANSACTION_UNITS")
      return "Total transaction unit to complete task";
    else if (name === "TRANSACTION_COUNT")
      return "Total transaction count to complete task";
    else if (name === "TRANSACTION_SPEND")
      return "Total transaction point to complete task";
    else return "No label found";
  };

  return (
    <GridWrapper>
      <GridItem size="1/4">
        <IconButton
          size="small"
          variant="outlined"
          circle={false}
          element="button"
          label="Add Task"
          onClick={() => addTask()}
          disabled={taskArrVal.length >= 19}
        >
          <Plus />
        </IconButton>
      </GridItem>
      <GridItem>
        {taskArrVal &&
          taskArrVal.length > 0 &&
          taskArrVal.map((task, index) => (
            <div className="ln-u-margin-top*3">
              <Accordion titleElement="h3">
                <AccordionItem
                  defaultOpen
                  id={`accordion-item-${stringifyNumber(index)}`}
                  title={`${stringifyNumber(index)} task`}
                  data-testid={`${stringifyNumber(index)}-item`}
                  titleElement="filled"
                  className="add-task-accordion-item"
                >
                  <GridWrapper>
                    <GridItem size={"1/2"}>
                      <TextInputField
                        name="trackerName"
                        label="Target Name"
                        placeholder="eg:- Complete a shop"
                        value={task.trackerName}
                        onChange={(e) => handleAddTaskVal(e, index)}
                        onBlur={(e) => handleOnBlur(e)}
                        error={
                          trackerNameSelectedError &&
                          !task.trackerName &&
                          "Required"
                        }
                      />
                    </GridItem>
                    <GridItem size={"1/2"}>
                      <TextInputField
                        name="trackerPointsReward"
                        label="Points Reward"
                        placeholder="eg:- 100"
                        value={task.trackerPointsReward}
                        onChange={(e) => handleTransactionValueChange(e, index)}
                        onBlur={(e) => handleOnBlur(e)}
                        error={
                          maximumPointsSelectedError &&
                          !task.trackerPointsReward &&
                          "Required"
                        }
                        type="number"
                        min={0}
                      />
                    </GridItem>

                    <GridItem size={"1/2"}>
                      <SelectField
                        name="trackerTargetUnitType"
                        label="Transaction Type"
                        value={task.trackerTargetUnitType}
                        options={TransactionType}
                        onChange={(e) => {
                          handleAddTaskVal(e, index);
                          handleTransactionTypeValueChange(e, index);
                        }}
                        onBlur={(e) => handleOnBlur(e)}
                        error={
                          transactionTargetypeSelectedError &&
                          !task.trackerTargetUnitType &&
                          "Required"
                        }
                      />
                    </GridItem>
                    <GridItem size={"1/2"}>
                      {task.trackerTargetUnitType && (
                        <TextInputField
                          name={tranTypeNameFinder(task.trackerTargetUnitType)}
                          label={tranTypelabelFinder(
                            task.trackerTargetUnitType
                          )}
                          placeholder="eg:- 1"
                          value={tranTypeValueFinder(task)}
                          onChange={(e) =>
                            handleTransactionValueChange(e, index)
                          }
                          type="number"
                          min={0}
                          innerRef={tranTypeInputRef[index]}
                        />
                      )}
                    </GridItem>
                    {task &&
                      task.trackerTargetUnitType === "TRANSACTION_UNITS" && (
                        <>
                          <GridItem size={"1/2"}>
                            <TextAreaField
                              name="skus"
                              label="skus"
                              placeholder="e.g. 123,456,789 "
                              onChange={(e) => handleSkuValueChange(e, index)}
                              value={task.skus && task.skus.join(",")}
                            />
                          </GridItem>
                        </>
                      )}
                    <GridItem className="padding-left-none" size={"1"}>
                      <TextButton onClick={() => removeTask(index)}>
                        Remove Task
                      </TextButton>
                    </GridItem>
                  </GridWrapper>
                </AccordionItem>
              </Accordion>
            </div>
          ))}
      </GridItem>
    </GridWrapper>
  );
};

export default EventBuilder;
