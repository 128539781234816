import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { SuccessToastNotification } from "../../common/helpers/Notifications/SuccessToastNotification";
import { PromotionsClient } from "../../services/PromotionsClient";
import PromotionEditor from "./PromotionEditor";

const apiClient = new PromotionsClient();

const CreatePromotion = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [apiErrorText, setApiErrorText] = useState("");

  const onSave = async (requestBody) => {
    setLoading(true);

    try {
      const response = await apiClient.createPromotion(requestBody);
      if (response.status !== 200) {
        throw new Error(response);
      }
      SuccessToastNotification(
        `Created promotion "${requestBody.name}" with ID: ${response.data.id}`
      );
      navigate("/promotions");
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText("Failed to save the promotion. " + message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <PromotionEditor
      loading={loading}
      apiErrorText={apiErrorText}
      onSave={onSave}
      initialValues={{}}
    />
  );
};

export default CreatePromotion;
