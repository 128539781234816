import React from "react";

import { Label } from "@jsluna/form";

import LockImg from "../../assets_V2/lock.png";

const MarketingEventPreview = ({ taskArrVal, eventDetails }) => {
  return (
    <div className="iphone-frame">
      <div className="iphone">
        <div className="iphone-content">
          <div>
            <div className="ln-u-margin-top ln-u-margin-bottom*2">
              <Label className="custom-label">{eventDetails.name}</Label>
            </div>
            {taskArrVal &&
              taskArrVal.length > 0 &&
              taskArrVal
                .sort((a, b) => a.trackerOrder - b.trackerOrder)
                .map((task, index) => (
                  <div
                    className="task ln-u-margin-top"
                    style={{ height: "100px" }}
                  >
                    <span className="task-container">
                      <p className="custom-title1">Locked</p>
                      <p className="custom-title2">
                        {task.trackerName && task.trackerName}
                      </p>
                      <p className="custom-title2">{`${
                        task.maximumPoints && task.maximumPoints
                      } Points`}</p>
                    </span>
                    <span className="img-container">
                      <img className="lock-img" src={LockImg} alt="lock" />
                    </span>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingEventPreview;
