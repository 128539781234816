import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { IconButton } from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label, SearchField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { ArrowLeft, Download, Upload } from "@jsluna/icons";
import { Link } from "@jsluna/link";
import { Pagination } from "@jsluna/pagination";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";

import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import { PodManagementClient } from "../../services/PodManagementClient";
import PodAssetsList from "./PodAssetsList";
import "./PodAssetsList.scss";

const podManagementClient = new PodManagementClient();

const HomepagePodsAssetManagement = () => {
  const navigate = useNavigate();
  const [podsAssets, setPodsAssets] = useState([]);
  const assetUploadInputRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchItem, setSearchItem] = useState("");
  const ROWS_PER_PAGE = 10;

  const isHPEditor = HasRequiredRoleAccess("homepage-pods");

  useEffect(() => {
    GetPodsAssets(1).then(() => setLoading(false));
    // eslint-disable-next-line
  }, [searchItem]);

  const GetPodsAssets = async (page) => {
    setLoading(true);
    await podManagementClient
      .fetchPodsAssets(page - 1, ROWS_PER_PAGE, searchItem)
      .then(({ data }) => setPodsAssets(data))
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const onUploadClick = (e) => {
    assetUploadInputRef.current.value = null;
    assetUploadInputRef.current.click();
  };

  const onUploadFileChange = async (e) => {
    e.preventDefault();
    // restrict if file size is greater than 1 MB
    if (e.target.files[0].size > 1000000) {
      toast.error("File size is too large (Preferred res: 600*300");
    } else {
      uploadFile(e.target.files[0].name, e.target.files[0]);
    }
  };

  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
  };

  const uploadFile = async (imageName, fileItem) => {
    let data = new FormData();
    // const imageNameWithoutExt = imageName.replace(/\.[^/.]+$/, "");

    data.append("file", fileItem);

    await podManagementClient
      .uploadAssetHomePagePod(imageName, data)
      .then(({ data }) => {
        toast(
          <div>
            <IconButton
              onClick={copyToClipboard(data)}
              size="small"
              variant="outline"
              element="button"
            >
              <>
                <Download /> &nbsp; Click to copy the url
              </>
            </IconButton>
          </div>,
          { autoClose: false, theme: "light" }
        );
      })
      .catch((err) => {
        // setError(err);
        toast.error(err);
      });
  };

  const handlePageChange = (page, e) => {
    e.preventDefault();
    setCurrentPage(page);
    GetPodsAssets(page).then(() => setLoading(false));
  };

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
  };

  const totalElements = podsAssets && podsAssets.totalElements;
  const numOfPages = podsAssets && Math.ceil(totalElements / ROWS_PER_PAGE);

  return (
    <>
      <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
        <GridWrapper className="ln-u-margin-top*3">
          <GridItem>
            <GridItem size={"1/8"}>
              <Link
                onClick={() => navigate("/homepage-pods")}
                className="ln-u-display-inline-flex ln-u-align-items-center"
              >
                <ArrowLeft className="ln-u-margin-right" />
                <Label htmlFor="homepagepod-back" className="homepagepod-label">
                  Back
                </Label>
              </Link>
            </GridItem>
            <GridItem size={"4/8"}>
              <Label
                htmlFor="hp-pod-asset-management"
                className="ln-u-margin-left"
              >
                Homepage Pod Asset Management
              </Label>
            </GridItem>
            <GridItem size={"2/8"}>
              <SearchField
                name="search-field-homepagepod-assets"
                label="Search by assets"
                hideLabel
                placeholder="Search by assets"
                hasAction
                onChange={handleInputChange}
                value={searchItem}
              />
            </GridItem>
            <GridItem size={"1/8"}>
              <input
                id="bulk-upload-asset"
                data-testid="bulk-upload-asset"
                type="file"
                hidden
                name="asset"
                onChange={onUploadFileChange}
                ref={assetUploadInputRef}
                accept="image/*"
              />
              <IconButton
                onClick={onUploadClick}
                size="small"
                variant="filled"
                circle={false}
                element="button"
                label="Upload Assets"
                className="ln-u-margin-right"
                disabled={!isHPEditor}
              >
                <Upload />
              </IconButton>
            </GridItem>
          </GridItem>
          <GridItem style={{ marginTop: "15px" }}>
            <PodAssetsList podsAssets={podsAssets} />
            {numOfPages > 1 && (
              <Pagination
                showFirstAndLast
                showPages
                current={currentPage}
                total={numOfPages}
                onChange={handlePageChange}
                className="ln-u-text-align-center ln-u-margin-bottom*3 ln-u-margin-top*3"
              />
            )}
          </GridItem>
        </GridWrapper>
      </Card>
      {loading && (
        <ProgressIndicator
          page
          style={{ position: "absolute", zIndex: 10 }}
          loading
          preventFocus
        >
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      )}
    </>
  );
};
export default HomepagePodsAssetManagement;
