import { HttpClient, Method, encodeQuery, managementApi } from "./http";

export class CustomerClient extends HttpClient {
  findByEmail(email) {
    return this.request(
      Method.GET,
      managementApi(`/customer${encodeQuery({ email })}`),
      {}
    );
  }

  findByNectarId(nectarId) {
    return this.request(
      Method.GET,
      managementApi(`/customer${encodeQuery({ nectarId })}`),
      {}
    );
  }

  getCustomer(id) {
    return this.request(Method.GET, managementApi(`/customer/${id}`), {});
  }

  getCustomerByEH(externalHandle) {
    return this.request(
      Method.GET,
      managementApi(`/customer${encodeQuery({ externalHandle })}`),
      {}
    );
  }

  getExpiredOrUsedOffers(id) {
    return this.request(
      Method.GET,
      managementApi(`/customer/${id}/expired-offer`),
      {}
    );
  }

  replaceCard(externalHandle, replacementType) {
    return this.request(
      Method.POST,
      managementApi(
        `/replacement-card${encodeQuery({ externalHandle, replacementType })}`
      )
    );
  }

  passwordReset(email) {
    return this.request(Method.POST, managementApi("/password-reset"), {
      email,
    });
  }

  updateCustomerEmail(firstName, externalHandle, oldEmail, newEmail) {
    return this.request(Method.PUT, managementApi(`/customer/email`), {
      firstName,
      externalHandle,
      oldEmail,
      newEmail,
    });
  }

  cancelCustomerOffer(id, offerId) {
    return this.request(
      Method.POST,
      managementApi(`/customer/${id}/offer/${offerId}/cancel`)
    );
  }
}

export const ReplacementType = {
  LOST: "LOST",
  STOLEN: "STOLEN",
  DAMAGED: "DAMAGED",
  FRAUD: "FRAUD",
};

export default CustomerClient;
