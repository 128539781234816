import { HttpClient, Method, managementApi } from "./http";

export class ManagementUserClient extends HttpClient {
  fetchUsers() {
    return this.request(
      Method.GET,
      managementApi("/management-user?page=0&size=100"),
      {}
    );
  }

  fetchUser(id) {
    return this.request(
      Method.GET,
      managementApi(`/management-user/${id}`),
      {}
    );
  }

  createUser(username, password, email, userdata) {
    return this.request(Method.POST, managementApi(`/management-user`), {
      username: username,
      password: password,
      email: email,
      userdata: userdata,
    });
  }

  updateDetails(userId, newEmailAddress) {
    return this.request(
      Method.PUT,
      managementApi(`/management-user/${userId}/details`),
      { emailAddress: newEmailAddress }
    );
  }

  updateRole(userId, role) {
    return this.request(
      Method.PUT,
      managementApi(`/management-user/${userId}/${role}`)
    );
  }

  tagUser(userId, tag) {
    return this.request(
      Method.PUT,
      managementApi(`/management-user/${userId}/details`),
      { userType: tag }
    );
  }

  switchPermission(userId, permissionId) {
    return this.request(
      Method.PUT,
      managementApi(`/management-user/${userId}/permission/${permissionId}`),
      {}
    );
  }

  deactivate(userId) {
    return this.request(
      Method.DELETE,
      managementApi(`/management-user/${userId}`),
      {}
    );
  }

  getStoreMarketingPreferences(storeId) {
    return this.request(
      Method.GET,
      managementApi(`/stores/${storeId}/marketing-preferences`)
    );
  }

  updateStoreMarketingPreferences(
    storeId,
    updateStoreMarketingPreferenceRequest
  ) {
    return this.request(
      Method.PATCH,
      managementApi(`/stores/${storeId}/marketing-preferences`),
      updateStoreMarketingPreferenceRequest
    );
  }
}

export default ManagementUserClient;
