/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Icon, Message } from "semantic-ui-react";

import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { FormGroup } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";

import { toApiFormat } from "../../common/dates";
import { ErrorToastNotification } from "../../common/helpers/Notifications/ErrorToastNotifcation";
import { SuccessToastNotification } from "../../common/helpers/Notifications/SuccessToastNotification";
import { PodManagementClient } from "../../services/PodManagementClient";
import { getSessionCookieData } from "../../services/sessionCookie";
import "./PodEditor.scss";
import { PodEditorForm } from "./PodEditorForm";
import PodPreview from "./helpers/PodPreview/PodPreview";
import { InitialState, theme1, theme2 } from "./pod";
import PodFooterImagesInput from "./podforminputs/PodFooterImagesInput";

const podManagementClient = new PodManagementClient();

const PodEditor = () => {
  const { username } = getSessionCookieData();
  const [loading, setLoading] = useState(true);
  const [pod, setPod] = useState({});
  const [error, setError] = useState(false);
  const [disableSave, setDisableSave] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    checkIfNewPod()
      .then(() => setLoading(false))
      .catch(() => setError("An error occurred"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      pod.id &&
      pod.link &&
      pod.startDateTime &&
      pod.endDateTime &&
      pod.featureState &&
      pod.header?.data?.title &&
      pod.header?.data?.subtitle &&
      pod.header?.colors &&
      pod.body?.data?.imageUrl &&
      pod.body?.data?.accessibilityText
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [pod]);

  const checkIfNewPod = async () => {
    if (id === "new") {
      setPod(InitialState());
    } else {
      await podManagementClient
        .fetchHomepagePod(id)
        .then((res) => setPod(res.data))
        .catch((err) =>
          ErrorToastNotification(`Failed to load pod, ${err.message}`)
        );
    }
  };

  const handleSave = () => {
    if (pod && pod.id.match(" ")) {
      toast.error("Id should not contain any space");
      return;
    }
    setLoading(true);
    Promise.all([
      setPod((prevState) => ({
        ...prevState,
        lastModifiedBy: username,
      })),
      createOrUpdateHomepagePod(),
    ]).then(() => {
      setLoading(false);
      navigate("/homepage-pods");
    });
  };

  const createOrUpdateHomepagePod = async () => {
    await podManagementClient
      .createOrUpdateHomepagePod(pod)
      .then(() =>
        SuccessToastNotification(
          `Saved pod " ${pod.header?.data?.title} " by ${username}`
        )
      )
      .catch((err) => ErrorToastNotification(err.response.data.description));
  };

  const handleChange = (event, objKey) => {
    const { name, value } = event.target;
    if (objKey !== null) {
      setPod((prevState) => ({
        ...prevState,
        [objKey]: {
          ...prevState[objKey],
          ["data"]: {
            ...prevState[objKey].data,
            [name]: value,
          },
        },
      }));
    } else {
      setPod((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleColorChange = (event) => {
    const { value } = event.target;
    if (value === "theme1") {
      setPod((prevState) => ({
        ...prevState,
        ["header"]: {
          ...prevState["header"],
          ...theme1,
        },
        ["body"]: {
          ...prevState["body"],
          ...theme1,
        },
      }));
    } else if (value === "theme2") {
      setPod((prevState) => ({
        ...prevState,
        ["header"]: {
          ...prevState["header"],
          ...theme2,
        },
        ["body"]: {
          ...prevState["body"],
          ...theme2,
        },
      }));
    }
  };

  const handleChangeDate = (value, field) => {
    const date = toApiFormat(value);
    setPod((prevState) => ({ ...prevState, [field]: date }));
  };

  const addNewImage = (image) => {
    const imageArr =
      pod && pod.footer && pod.footer.data && pod.footer.data.brands
        ? pod.footer.data.brands
        : [];
    imageArr.push(image);
    setPod((prevState) => ({
      ...prevState,
      ["footer"]: {
        ...prevState["footer"],
        ["data"]: {
          ...prevState["footer"].data,
          ["brands"]: imageArr,
        },
      },
    }));
  };

  const removeImage = (name, index) => {
    const imageArr =
      pod && pod.footer && pod.footer.data && pod.footer.data.brands;
    const updatedImages = imageArr.filter((_, i) => i !== index);
    setPod((prevState) => ({
      ...prevState,
      ["footer"]: {
        ...prevState["footer"],
        ["data"]: {
          ...prevState["footer"].data,
          ["brands"]: updatedImages,
        },
      },
    }));
  };

  if (!pod && !error) {
    return null;
  }

  if (loading) {
    return (
      <div className="podEditorLoading">
        <ProgressIndicator
          page
          style={{ position: "absolute", zIndex: 10 }}
          loading
          preventFocus
        >
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      </div>
    );
  }

  return (
    <>
      <div>
        <FormGroup name="podForm" className="podEditorForm">
          <ButtonGroupWrapper>
            <ButtonGroupSecondary>
              <OutlinedButton
                type="button"
                size="small"
                onClick={() => navigate("/homepage-pods")}
              >
                Close
              </OutlinedButton>
              &nbsp;&nbsp;&nbsp;
              <FilledButton
                primary
                size="small"
                disabled={disableSave}
                loading={loading.toString()}
                onClick={() => handleSave()}
              >
                Save
              </FilledButton>
            </ButtonGroupSecondary>
          </ButtonGroupWrapper>

          <GridWrapper matrix>
            <GridItem size="3/4">
              <PodEditorForm
                pod={pod}
                handleChange={handleChange}
                handleChangeDate={handleChangeDate}
                handleColorChange={handleColorChange}
              />
              <br />
              <PodFooterImagesInput
                footerImages={
                  pod.footer?.data?.brands ? pod.footer.data.brands : []
                }
                addNewImage={addNewImage}
                removeImage={removeImage}
              />
            </GridItem>
            <GridItem size="1/4" style={{ position: "fixed" }}>
              <PodPreview pod={pod} />
            </GridItem>
          </GridWrapper>
        </FormGroup>

        {error ? (
          <Message attached="bottom" error>
            <Icon name="warning circle" />
            {error}
          </Message>
        ) : null}
      </div>
    </>
  );
};

export default PodEditor;
