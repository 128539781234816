import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { SelectField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import MarketingEventClient from "../../services/MarketingEventClient";

const marketingEventClient = new MarketingEventClient();

const UiOptionsContent = ({
  handleChange,
  eventDetails,
  editScreen,
  selectedCoreLogicType,
}) => {
  const [summaryContextList, setSummaryContextList] = useState([]);
  const [overviewContextList, setOverviewContextList] = useState([]);
  const [homepageContextList, setHomepageContextList] = useState([]);
  const [uiContentList, setUiContentList] = useState([]);
  const [summaryContextValue, setSummaryContextValue] = useState(undefined);
  const [overviewContextValue, setOverviewContextValue] = useState(undefined);
  const [homepageContextValue, setHomepageContextValue] = useState(undefined);

  const location = useLocation();
  const marketingEventId = location?.editMarketingEvent?.rowData[0]?.id;

  useEffect(() => {
    getUiOptions("SUMMARY");
    getUiOptions("OVERVIEW");
    getUiOptions("HOMEPAGE");
    getUiContent(selectedCoreLogicType);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (marketingEventId) {
      getUiOptionsByME("summary");
      getUiOptionsByME("overview");
      getUiOptionsByME("homepage");
    }
    // eslint-disable-next-line
  }, [marketingEventId]);

  const getUiOptions = async (uiContext) => {
    await marketingEventClient
      .getUiOptions(uiContext)
      .then(({ data }) => {
        uiContext === "SUMMARY" &&
          setSummaryContextList(
            data.map((item) => {
              return { label: item.name, value: item.id };
            })
          );

        uiContext === "OVERVIEW" &&
          setOverviewContextList(
            data.map((item) => {
              return { label: item.name, value: item.id };
            })
          );

        uiContext === "HOMEPAGE" &&
          setHomepageContextList(
            data.map((item) => {
              return { label: item.name, value: item.id };
            })
          );
      })
      .catch((err) => {
        uiContext === "SUMMARY" && setSummaryContextList([]);
        uiContext === "OVERVIEW" && setOverviewContextList([]);
        uiContext === "HOMEPAGE" && setHomepageContextList([]);
      });
  };

  const getUiContent = async (coreLogicType) => {
    await marketingEventClient
      .getUiContent(coreLogicType)
      .then(({ data }) => {
        setUiContentList(
          data.map((item) => {
            return { label: item.contentName, value: item.contentId };
          })
        );
      })
      .catch((err) => {
        setUiContentList([]);
      });
  };

  const getUiOptionsByME = async (uiOptions) => {
    await marketingEventClient
      .getUiOptionsByME(uiOptions, marketingEventId)
      .then(({ data }) => {
        uiOptions === "summary" && setSummaryContextValue(data.id);

        uiOptions === "overview" && setOverviewContextValue(data.id);

        uiOptions === "homepage" && setHomepageContextValue(data.id);
      })
      .catch((err) => {
        uiOptions === "summary" && setSummaryContextValue(undefined);
        uiOptions === "overview" && setOverviewContextValue(undefined);
        uiOptions === "homepage" && setHomepageContextValue(undefined);
      });
  };

  return (
    <GridWrapper className="ln-u-margin-top*3">
      <GridItem size="1/2">
        <SelectField
          name="homepage"
          label="Homepage *"
          options={homepageContextList}
          value={eventDetails.homepage || homepageContextValue}
          onChange={handleChange}
          // disabled={editScreen}
        />
      </GridItem>
      <GridItem size="1/2">
        <SelectField
          name="overview"
          label="Overview"
          options={overviewContextList}
          value={eventDetails.overview || overviewContextValue}
          onChange={handleChange}
          // disabled={editScreen}
        />
      </GridItem>
      <GridItem size="1/2">
        <SelectField
          name="summary"
          label="Summary"
          options={summaryContextList}
          value={eventDetails.summary || summaryContextValue}
          onChange={handleChange}
          // disabled={editScreen}
        />
      </GridItem>
      <GridItem size={"1/2"}>
        <SelectField
          name="contentId"
          label="UI Content *"
          options={uiContentList}
          value={eventDetails.contentId}
          onChange={handleChange}
          // disabled={editScreen}
        />
      </GridItem>
    </GridWrapper>
  );
};

export default UiOptionsContent;
