import { HttpClient, Method, managementApi } from "./http";

export class SkuClient extends HttpClient {
  fetchSkus() {
    return this.request(Method.GET, managementApi(`/skus`));
  }

  fetchSku(id) {
    return this.request(Method.GET, managementApi(`/skus/${id}`));
  }

  createOrUpdateSku(sku) {
    return this.request(Method.PUT, managementApi(`/skus`), sku);
  }

  deleteSku(id) {
    return this.request(Method.DELETE, managementApi(`/skus/${id}`));
  }

  getFileUploadUrl(fileName) {
    return this.request(Method.POST, managementApi("/skus/file-upload-url"), {
      fileName,
    });
  }

  uploadFile(url, file) {
    return this.request(Method.PUT, url, file);
  }
}

export default SkuClient;
