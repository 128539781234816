import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import {
  Routes as ReactRoutes,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "./common/helpers/Loader/Loader";
import {
  getToken,
  isProd,
  login,
  setAuthAccounts,
  setAuthInstance,
} from "./config";
import { useSessionContext } from "./contexts/SessionContext";
import SessionClient from "./services/SessionClient";
import { getSessionCookieData } from "./services/sessionCookie";
import NewSite from "./views/Routes";

const sessionClient = new SessionClient();

const AuthenticatedRoutes = () => {
  const [sessionLoading, setSessionLoading] = useState(true);
  const [access_token, setAccess_token] = useState();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const { setUserRoles, setIsRoleBasedAccess } = useSessionContext();

  useEffect(() => {
    setAuthInstance(instance);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setAuthAccounts(accounts);
      if (accessPermittedFlag()) {
        setUserRoles(accounts[0].idTokenClaims.roles);
        setIsRoleBasedAccess(true);
      } else {
        setUserRoles([]);
        setIsRoleBasedAccess(false);
      }
    } else {
      if (!(accounts && accounts[0])) login();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const accessPermittedFlag = () => {
    return (
      accounts?.[0]?.idTokenClaims?.roles?.includes("AccessPermitted") || false
    );
  };

  useEffect(() => {
    document.title = `${isProd() ? "" : "(Non-prod) "}Nectar management portal`;

    const createSession = async () => {
      setSessionLoading(true);
      try {
        // This call returns nothing, but it sets a non-http-only cookie
        await sessionClient.createSession(access_token);
      } catch (err) {
        alert("Failed to create session with Management Portal API");
        window.location = "/";
      }
      setSessionLoading(false);
    };
    if (!accessPermittedFlag()) {
      if (access_token) createSession();
    } else {
      setSessionLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token]);

  useEffect(() => {
    const getAzureToken = async () => {
      const response = await getToken();
      if (response) {
        setAccess_token(response.idToken);
      }
    };

    if (isAuthenticated) getAzureToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // We may already have an API session cookie but pages expect one, so don't render if we don't have one
  const sessionData = getSessionCookieData();
  if (!sessionData) {
    if (sessionLoading) {
      return <Loader />;
    } else {
      return "Failed to create a session with the management portal API";
    }
  }

  return <NewSite />;
};

const Routes = () => (
  <Router>
    <ReactRoutes>
      <Route path="*" element={<AuthenticatedRoutes />} />
    </ReactRoutes>
    <ToastContainer theme="dark" autoClose={6000} />
  </Router>
);

export default Routes;
