import React from "react";
import { Form, Grid, Icon, Message } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";

import SkuClient from "../../services/SkuClient";
import { LoadingState } from "../../services/http";
import { InitialState } from "./Sku";
import { SkuEditorForm } from "./SkuEditorForm";
import SkuTargetInput from "./skuforminputs/SkuTargetInput";

const skuClient = new SkuClient();

export default class SkuEditor extends React.Component {
  state = {
    loadingState: undefined,
    sku: {
      targetSkuIds: [],
    },
    error: undefined,
    skuUpdateError: undefined,
    skuUpdateSuccess: undefined,
    commitTrigger: undefined,
    skuForm: null,
  };

  componentDidMount() {
    this.reloadSku();
  }

  getCurrentSkuId = () => {
    return this.props.currentSkuId;
  };

  isNewSku = () => {
    return this.props.currentSkuId === null;
  };

  reloadSku = () => {
    if (this.isNewSku()) {
      this.setState({ sku: InitialState() });
    } else {
      skuClient
        .fetchSku(this.getCurrentSkuId())
        .then(({ data }) => {
          let sku = this.setDefaultsForNullValues(data);
          this.setState({ sku: sku });
        })
        .catch((err) => {
          console.log(err);
          this.clearMessages(() => {
            this.setState({ skuUpdateError: "Failed to load sku" });
          });
        });
    }
  };

  setDefaultsForNullValues = (sku) => {
    if (sku.targetSkuIds === undefined) {
      sku.targetSkuIds = [];
    }
    return sku;
  };

  clearMessages(cb) {
    this.setState(
      {
        skuUpdateError: undefined,
        skuUpdateSuccess: undefined,
      },
      cb
    );
  }

  handleSave = () => {
    this.clearMessages();
    this.createOrUpdateSku();
  };

  createOrUpdateSku = () => {
    this.setState(
      {
        sku: { ...this.state.sku },
      },
      () => {
        skuClient
          .createOrUpdateSku(this.state.sku)
          .then(() => {
            this.props.close();
          })
          .catch((err) => {
            this.clearMessages(() => {
              this.setState({
                skuUpdateError: err.response.data.description,
                loadingState: LoadingState.FAILED,
              });
            });
          });
      }
    );
  };

  handleChange = (e, { name, value }) => {
    this.clearMessages(() => {
      this.setState({
        sku: { ...this.state.sku, [name]: value },
      });
    });
  };

  deleteSku = () => {
    const id = this.state.sku.skuId;
    skuClient
      .deleteSku(id)
      .then(() => this.props.close())
      .catch((err) => {
        this.clearMessages(() => {
          this.setState({
            skuUpdateError: err.response.data.description,
            loadingState: LoadingState.FAILED,
          });
        });
      });
  };

  addNewTargetSkuId = (targetSkuId) => {
    const targetSkuIds = this.state.sku.targetSkuIds;
    const updatedTargetSkuIds = targetSkuIds.concat(targetSkuId);
    const uniqueUpdatedTargetSkuIds = [...new Set(updatedTargetSkuIds)];
    let currentSku = { ...this.state.sku };
    currentSku.targetSkuIds = uniqueUpdatedTargetSkuIds;
    this.setState({
      sku: { ...currentSku },
    });
  };

  removeTargetSkuId = (targetSkuId) => {
    const targetSkuIds = this.state.sku.targetSkuIds;
    const uniqueUpdatedTargetSkuIds = targetSkuIds.filter(
      (targetSku) => targetSku !== targetSkuId
    );
    let currentSku = { ...this.state.sku };
    currentSku.targetSkuIds = uniqueUpdatedTargetSkuIds;
    this.setState({
      sku: { ...currentSku },
    });
  };

  render() {
    const { error, sku, skuUpdateError, skuUpdateSuccess, loadingState } =
      this.state;

    if (!sku && !error) {
      return null;
    }

    return (
      <div>
        <Form onSubmit={this.handleSave} widths="equal">
          <ButtonGroupWrapper>
            <ButtonGroupSecondary>
              <OutlinedButton
                type="button"
                size="small"
                onClick={() => this.props.close()}
              >
                Close
              </OutlinedButton>
            </ButtonGroupSecondary>
            <ButtonGroupPrimary>
              <FilledButton
                primary
                size="small"
                type="submit"
                disabled={this.props.isActive}
                loading={loadingState === LoadingState.PENDING}
              >
                Save
              </FilledButton>
              &nbsp;&nbsp;&nbsp;
              {this.isNewSku() ? null : (
                <FilledButton
                  type="button"
                  size="small"
                  color="red"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you wish to delete this sku?"
                      )
                    )
                      this.deleteSku();
                  }}
                >
                  Delete
                </FilledButton>
              )}
            </ButtonGroupPrimary>
          </ButtonGroupWrapper>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <SkuEditorForm
                  sku={sku}
                  handleChange={this.handleChange}
                  isNewSku={this.getCurrentSkuId() === null}
                />
                <br />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>

        <SkuTargetInput
          targetSkuIds={sku.targetSkuIds ? sku.targetSkuIds : []}
          addNewTargetSkuId={this.addNewTargetSkuId}
          removeTargetSkuId={this.removeTargetSkuId}
        />

        {skuUpdateError ? (
          <Message attached="bottom" error>
            <Icon name="warning circle" />
            {skuUpdateError}
          </Message>
        ) : null}

        {skuUpdateSuccess ? (
          <Message attached="bottom" success>
            <Icon name="check" />
            Updated the Sku
          </Message>
        ) : null}
      </div>
    );
  }
}
