export const PlaceholderImage =
  "https://via.placeholder.com/150x150?text=placeholder";

export const InitialState = () => {
  return {
    id: "",
    header: {
      type: "TITLE",
      data: {
        title: "",
        subtitle: "",
      },
    },
    body: {
      type: "IMAGE",
      data: {
        imageUrl: "",
        accessibilityText: "",
      },
    },
    footer: {
      type: "BRANDS",
      data: {
        brands: [],
        countdownContent: {},
        colors: {
          light: {
            backgroundColor: "#FFFFFF",
            foregroundPrimaryColor: "#FFFFFF",
            foregroundSecondaryColor: "FFFFFF",
          },
          dark: {
            backgroundColor: "#FFFFFF",
            foregroundPrimaryColor: "#FFFFFF",
            foregroundSecondaryColor: "#FFFFFF",
          },
        },
      },
    },
    link: "",
    position: 5,
    minimumVersion: "0.0.1",
    maximumVersion: "99.9.9.9",
    featureState: "",
    startDateTime: formatDateForHomePod(new Date()),
    endDateTime: formatDateForHomePod(new Date()),
  };
};

export const featureStates = [
  { label: "DISABLED", value: "DISABLED" },
  { label: "ENABLED", value: "ENABLED" },
];

export const colorTypes = [
  { label: "Type 1", value: "theme1" },
  { label: "Type 2", value: "theme2" },
];

export const theme1 = {
  colors: {
    light: {
      backgroundColor: "#FFFFFF",
      foregroundPrimaryColor: "#F06C00",
      foregroundSecondaryColor: "#F06C00",
    },
    dark: {
      backgroundColor: "#FFFFFF",
      foregroundPrimaryColor: "#F06C00",
      foregroundSecondaryColor: "#F06C00",
    },
  },
};

export const theme2 = {
  colors: {
    light: {
      backgroundColor: "#8223FA",
      foregroundPrimaryColor: "#FFFFFF",
      foregroundSecondaryColor: "#FFFFFF",
    },
    dark: {
      backgroundColor: "#8223FA",
      foregroundPrimaryColor: "#FFFFFF",
      foregroundSecondaryColor: "#FFFFFF",
    },
  },
};

export const IMAGE_TYPE = {
  images: "images",
  footerImages: "footerImages",
};

export const formatDateForHomePod = (dateToFormat) => {
  return (
    new Date(dateToFormat.toString().split("GMT")[0] + " UTC")
      .toISOString()
      .split(".")[0] + "Z"
  );
};

export const areObjectsEqual = (obj1, obj2) => {
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!areObjectsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const findColorType = (colorObj) => {
  const objToCompare = colorObj;
  const isEqualtoTheme1 = areObjectsEqual(theme1.colors, objToCompare);
  const isEqualtoTheme2 = areObjectsEqual(theme2.colors, objToCompare);

  if (isEqualtoTheme1) {
    return "theme1";
  } else if (isEqualtoTheme2) {
    return "theme2";
  } else {
    return undefined;
  }
};
