import React from "react";
import { Form, Icon } from "semantic-ui-react";

import { FilledButton, OutlinedButton } from "@jsluna/button";
import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

export default class SkuTargetInput extends React.Component {
  state = {
    newTargetSkuId: "",
  };

  handleTargetSkuIdNameChange = (event) => {
    this.setState({ newTargetSkuId: event.target.value });
  };

  submitNewTargetSkuId = () => {
    this.props.addNewTargetSkuId(this.state.newTargetSkuId);
    this.setState({
      newTargetSkuId: "",
    });
  };

  render() {
    const skuTargetSkuIds = this.props.targetSkuIds.map((targetSkuId, i) => (
      <tr className="ln-c-table__row ln-c-table__header-row" key={i}>
        <td className="ln-c-table__cell">{targetSkuId}</td>
        <td className="ln-c-table__cell">
          <OutlinedButton
            onClick={() => this.props.removeTargetSkuId(targetSkuId)}
          >
            Remove
          </OutlinedButton>
        </td>
      </tr>
    ));

    return (
      <div>
        <GridWrapper>
          <GridItem style={{ marginTop: "1rem" }}>
            <span>
              <strong>
                <Icon circular name="id badge" />
                &nbsp;&nbsp;<Label>Target Sku Ids</Label>
              </strong>
            </span>
          </GridItem>
        </GridWrapper>

        <div style={{ paddingTop: "10px", width: "100%" }}>
          <Form
            onSubmit={this.submitNewTargetSkuId}
            style={{ width: "70%", display: "inline-flex" }}
          >
            <GridWrapper>
              <GridItem size={{ xs: "1/3" }}>
                <Label>Name</Label>
                <Form.Input
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    boxShadow: "0",
                    fontFamily: "sans-serif",
                    border: "1px solid #737373",
                    borderRadius: "4.571px",
                    color: "#737373",
                    minHeight: "48px",
                  }}
                  name={"TargetSkuId"}
                  onChange={this.handleTargetSkuIdNameChange}
                  value={this.state.newTargetSkuId}
                />
              </GridItem>
              <FilledButton
                style={{ margin: "25px 0 0 15px", height: "38px" }}
                primary
                size="small"
                type="submit"
              >
                Add
              </FilledButton>
            </GridWrapper>
          </Form>
        </div>
        <GridWrapper>
          <GridItem style={{ marginTop: "1rem" }}>
            <div style={{ paddingTop: "1rem" }}>
              <table className="ln-c-table" celled striped>
                <thead className="ln-c-table__header">
                  <tr className="ln-c-table__row ln-c-table__header-row">
                    <th className="ln-c-table__header-cell customCol">
                      Target Sku Id
                    </th>
                    <th className="ln-c-table__header-cell customCol">
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody className="ln-c-table__body">
                  {this.props.targetSkuIds.length >= 0 ? (
                    skuTargetSkuIds
                  ) : (
                    <tr className="ln-c-table__row ln-c-table__header-row">
                      <td className="ln-c-table__cell">
                        No TargetSkuids to show
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </GridItem>
        </GridWrapper>
      </div>
    );
  }
}
