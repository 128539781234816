import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, AlertIcon } from "@jsluna/alert";
import { FilledButton, OutlinedButton } from "@jsluna/button";
import { GridItem } from "@jsluna/grid";
import { ErrorCircle, Scan } from "@jsluna/icons";

import ConfirmationModal from "../../common/components/ConfirmationModal";
import { NumberField, TextField } from "../../common/components/FormFields";
import { InlineGroup } from "../../common/components/InlineGroup";
import PageContainer from "../../common/components/PageContainer";
import { PageHeader } from "../../common/components/PageHeader";
import Loader from "../../common/helpers/Loader/Loader";
import { useForm } from "../../common/hooks/useForm";

export const BarcodeBlocklistsEditor = ({
  loading,
  apiErrorText,
  onSave,
  initialValues,
  barcodeNumber,
  onDelete,
}) => {
  const isCreateMode = !barcodeNumber;
  const [requestedAction, setRequestedAction] = useState(null);
  const onDismiss = () => setRequestedAction(null);
  const { useField, anyValidationErrors, onSubmit, shouldBlockSubmit } =
    useForm({ immediateValidation: !isCreateMode });
  const initialName = initialValues.name ?? "";
  const navigate = useNavigate();

  const name = useField({
    initialValue: initialName,
    validations: [
      [(v) => v.length <= 70, "This field cannot exceed 70 characters"],
    ],
  });

  const description = useField({
    initialValue: initialValues.description ?? "",
    validations: [
      [(v) => v.length <= 160, "This field cannot exceed 160 characters"],
    ],
  });

  const barcode = useField({
    initialValue: initialValues.barcode ?? "",
    validations: [
      [(v) => v.length <= 15, "This field cannot exceed 15 characters"],
      [(v) => v.length >= 13, "Must be more than 12 characters"],
    ],
    disabled: !isCreateMode,
  });

  const onSaveSubmit = onSubmit((e) => {
    e.preventDefault();
    return onSave({
      name: name.value,
      description: description.value,
      barcode: barcode.value,
    });
  });

  if (loading) {
    return <Loader />;
  }

  const headerText = isCreateMode
    ? "Add new barcode to blocklist"
    : `Edit blocked barcode ${barcodeNumber}`;

  const validationErrors = anyValidationErrors();

  return (
    <>
      <PageContainer>
        <GridItem size={{ md: "1/2" }}>
          <PageHeader>
            <Scan />
            {headerText}
          </PageHeader>
        </GridItem>
        <GridItem size={{ md: "1/2" }}>
          <InlineGroup alignEnd>
            <OutlinedButton onClick={() => navigate("/barcodeblocklists")}>
              Close
            </OutlinedButton>
            {!isCreateMode && (
              <FilledButton onClick={() => setRequestedAction("delete")}>
                Delete
              </FilledButton>
            )}
            <FilledButton
              onClick={onSaveSubmit}
              disabled={validationErrors && shouldBlockSubmit}
            >
              Save
            </FilledButton>
          </InlineGroup>
        </GridItem>
        {validationErrors && shouldBlockSubmit && (
          <GridItem size="1/1">
            <Alert variant="error">
              <AlertIcon>
                <ErrorCircle aria-label="Error" role="img" />
              </AlertIcon>
              Please address validation errors before continuing
            </Alert>
          </GridItem>
        )}
        {apiErrorText && (
          <GridItem size="1/1">
            <Alert variant="error">
              <AlertIcon>
                <ErrorCircle aria-label="Error" role="img" />
              </AlertIcon>
              {apiErrorText}
            </Alert>
          </GridItem>
        )}
        <GridItem size="1/1">
          <TextField name="name" label="Barcode name" {...name.props} />
        </GridItem>
        <GridItem size="1/1">
          <TextField
            name="description"
            label="Barcode description"
            {...description.props}
          />
        </GridItem>
        <GridItem size="1/1">
          <NumberField
            name="barcode"
            label="Barcode number"
            {...barcode.props}
          />
        </GridItem>
      </PageContainer>
      <ConfirmationModal
        showModel={requestedAction === "delete"}
        onDismiss={onDismiss}
        onConfirm={async () => {
          onDismiss();
          await onDelete();
        }}
        heading="Delete blocked barcode"
        message={`Are you sure you want to delete ${barcodeNumber} - ${initialName} ?`}
      />
    </>
  );
};

export default BarcodeBlocklistsEditor;
