import { Header, HttpClient, MediaType, Method, managementApi } from "./http";

export class OfferAllocationClient extends HttpClient {
  allocate(payload) {
    return this.request(
      Method.POST,
      managementApi("/offer-allocation"),
      payload
    );
  }
  bulkUploadOcc(file) {
    const data = new FormData();
    data.append("file", file);
    return this.request(
      Method.POST,
      managementApi("/offer/bulk-upload/occ-offer"),
      data,
      {
        [Header.CONTENT_TYPE]: MediaType.MultipartFormData,
      }
    );
  }
}

export default OfferAllocationClient;
