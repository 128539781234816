import React, { createContext, useContext, useState } from "react";

const PaginationContext = createContext();

export const PaginationProvider = ({ children }) => {
  const [page, setPage] = useState(1);
  const [selectedCampaignIds, setData] = useState([]);

  const clearData = () => {
    setData([]);
    setPage(1);
  };

  return (
    <PaginationContext.Provider
      value={{ page, setPage, selectedCampaignIds, setData, clearData }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => {
  return useContext(PaginationContext);
};
