import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { ItemUnitMetric } from "../Sku";

export const SkuPortionsGroup = ({ sku, handleChange }) => (
  <GridWrapper>
    <GridItem size={{ xs: "1/2" }} style={{ margin: "1rem 0 0 0" }}>
      <Label>Unit Metric</Label>
      <br />
      <Form.Select
        style={{
          width: "100%",
          backgroundColor: "transparent",
          padding: "16px 8px 16px",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #dedfe0",
          borderRadius: "2px",
          color: "#737373",
          minHeight: "48px",
        }}
        value={sku ? sku.itemUnitMetric : "EACH"}
        name="itemUnitMetric"
        options={ItemUnitMetric}
        placeholder="unit metric"
        type="text"
        onChange={handleChange}
        required
      />
    </GridItem>
    <GridItem size={{ xs: "1/2" }} style={{ margin: "1rem 0 0 0" }}>
      <Label>Portions per metric</Label>
      <Form.Input
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4.571px",
          color: "#737373",
          minHeight: "48px",
        }}
        value={sku.portionsPerMetric || ""}
        name="portionsPerMetric"
        placeholder="Portions per metric"
        onChange={handleChange}
        required
      />
    </GridItem>
  </GridWrapper>
);
