import {
  Header,
  HttpClient,
  MediaType,
  Method,
  encodeQuery,
  managementApi,
} from "./http";

export class PromotionsClient extends HttpClient {
  bulkUpload(file) {
    const data = new FormData();
    data.append("file", file);
    return this.request(
      Method.POST,
      managementApi("/promotions/bulk-upload"),
      data,
      {
        [Header.CONTENT_TYPE]: MediaType.MultipartFormData,
      }
    );
  }

  getPromotions(promotionId, name, page) {
    const params = {
      promotionIds: promotionId ? [promotionId] : null,
      name: name ? name : null,
      page: page - 1, // The API starts counting pages at 0
    };
    return this.request(
      Method.GET,
      managementApi(`/promotions${encodeQuery(params)}`)
    );
  }

  downloadPromotionById(data) {
    return this.request(
      Method.GET,
      managementApi(`/promotions/export?promotionIds=${data}`)
    );
  }

  downloadPromotionByName(data) {
    return this.request(
      Method.GET,
      managementApi(`/promotions/export?name=${data}`)
    );
  }

  createPromotion(data) {
    return this.request(Method.POST, managementApi(`/promotions/create`), data);
  }

  fetchSku(id) {
    return this.request(Method.GET, managementApi(`/v2/product/sku/${id}`));
  }

  fetchSubCategory(subCategoryID) {
    return this.request(
      Method.GET,
      managementApi(`/v2/product/sub-category/${subCategoryID}`)
    );
  }

  searchProduct(name, page) {
    const params = {
      name: name ? name : null,
      page: page - 1, // The API starts counting pages at 0
    };
    return this.request(
      Method.GET,
      managementApi(`/products/search${encodeQuery(params)}`)
    );
  }

  getPromotion(id) {
    return this.request(Method.GET, managementApi(`/promotions/${id}`));
  }

  editPromotion(id, data) {
    return this.request(Method.PUT, managementApi(`/promotions/${id}`), data);
  }

  downloadFailedBulkUploadRecords(bulkUploadId) {
    return this.request(
      Method.GET,
      managementApi(`/promotions/bulk-upload/failed/${bulkUploadId}`)
    );
  }

  downloadSuccessfulBulkUploadRecords(bulkUploadId) {
    return this.request(
      Method.GET,
      managementApi(`/promotions/bulk-upload/successful/${bulkUploadId}`)
    );
  }

  getBulkUploads(page) {
    return this.request(
      Method.GET,
      managementApi(
        `/promotions/history${encodeQuery({
          page: page - 1, // The API starts counting pages at 0
        })}`
      )
    );
  }
}

export default PromotionsClient;
