import { useMsal } from "@azure/msal-react";
import Cookies from "universal-cookie";

import { useSessionContext } from "../contexts/SessionContext";

const cookies = new Cookies();

const GetAccounts = () => {
  const { accounts } = useMsal();
  return accounts;
};

const UseUserRoles = () => {
  const { userRoles } = useSessionContext();
  return userRoles;
};

const UseRoleBasedPermissions = () => {
  const { isRoleBasedAccess } = useSessionContext();
  return isRoleBasedAccess;
};

export const getSessionCookieData = () => {
  if (!UseRoleBasedPermissions()) {
    const sessionCookie = cookies.get("pianoManagementSession");
    if (!sessionCookie) {
      return null;
    }
    // The cookie is a JWT, and the middle section is all we care about
    const cookieComponents = sessionCookie.split(".");
    if (!cookieComponents || cookieComponents.length <= 1) {
      return null;
    }
    const base64Session = cookieComponents[1];
    const data = JSON.parse(atob(base64Session));

    let permissions = [];
    if (data.aud && data.aud.length > 0) {
      permissions = data.aud.split(",");
    }
    return {
      username: data.identity?.username ?? null,
      permissions,
    };
  } else if (UseRoleBasedPermissions()) {
    return {
      username: GetAccounts()[0]?.name,
      permissions: UseUserRoles(),
    };
  }
};
