import React from "react";

import { Label, TextInput } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

export const PodClickUrlInput = ({ pod, handleChange }) => (
  <GridWrapper>
    <GridItem style={{ margin: "1rem 0 0 0" }}>
      <Label>Click Url</Label>
      <TextInput
        value={pod.link}
        name="link"
        placeholder="clickUrl"
        onChange={(e) => {
          handleChange(e, null);
        }}
      />
    </GridItem>
  </GridWrapper>
);
