import {
  Header,
  HttpClient,
  MediaType,
  Method,
  encodeQuery,
  managementApi,
} from "./http";

export class CampaignClient extends HttpClient {
  createSearchFilters(
    name,
    barcode,
    tags,
    active,
    mode,
    clientType,
    offerType,
    createdBy,
    channel
  ) {
    return {
      name,
      barcode,
      tags,
      active,
      mode,
      clientType,
      offerType,
      createdBy,
      channel,
    };
  }

  fetchCampaigns(page, size, filters) {
    return this.request(
      Method.GET,
      managementApi(
        `/campaign/search${encodeQuery({
          page: page,
          size: size,
          ...filters,
        })}`
      )
    );
  }

  createCampaignTag(name) {
    return this.request(Method.POST, managementApi("/campaign/tags"), { name });
  }

  assignSupplierCategory(data) {
    let supplierCategory = "OCC";
    if (
      data.accountClientType !== "MANUAL_ASSIGNMENT" ||
      data.mode !== "TARGETED"
    ) {
      supplierCategory = "DIGITAL_NECTAR";
    }
    return supplierCategory;
  }

  generateBarcode(campaignRequestBody) {
    return this.request(Method.POST, managementApi("/barcode/barcode"), {
      campaignType: campaignRequestBody.campaignType,
      pointsValue: campaignRequestBody.maximumPoints,
      couponValue: campaignRequestBody.discountAmount,
      multiplier: campaignRequestBody.pointsMultiplierValue,
      productRange: campaignRequestBody.productRange,
      // Copying what campaign ingestion does https://github.com/JSainsburyPLC/piano-campaign-ingestion/blob/d7a8b377c60cd5fd4387abee56671b6bf2094e4a/src/main/java/com/sainsburys/piano/campaign/ingestion/ingest/BarcodeRequestTransformer.java#L17
      supplierCategory:
        campaignRequestBody.campaignType === "MONEY_OFF" &&
        this.assignSupplierCategory(campaignRequestBody),
      assignmentType: campaignRequestBody.accountClientType,
      campaignMode: campaignRequestBody.mode, // Seems like we should use the mode in the form
    });
  }

  createCampaign(campaignRequestBody) {
    let payload = campaignRequestBody;
    if (campaignRequestBody.campaignType === "MONEY_OFF") {
      payload = {
        ...campaignRequestBody,
        supplierCategory: this.assignSupplierCategory(campaignRequestBody),
      };
    }
    return this.request(Method.POST, managementApi("/campaign"), payload);
  }

  fetchCampaign(id) {
    return this.request(Method.GET, managementApi(`/campaign/${id}`));
  }

  updateCampaign(id, data) {
    let payload = data;
    if (data.campaignType === "MONEY_OFF") {
      payload = {
        ...data,
        supplierCategory: this.assignSupplierCategory(data),
      };
    }
    return this.request(Method.PUT, managementApi(`/campaign/${id}`), payload);
  }

  updateCampaignDates(id, data) {
    return this.request(
      Method.PATCH,
      managementApi(`/campaign/${id}/dates`),
      data
    );
  }

  bulkUpdateCampaignDates(payload) {
    return this.request(
      Method.PATCH,
      managementApi("/campaign/dates"),
      payload
    );
  }

  fetchCampaignTags() {
    return this.request(Method.GET, managementApi(`/campaign/tags`));
  }

  commitCampaign(id) {
    return this.request(Method.POST, managementApi(`/campaign/${id}/commit`));
  }

  pauseCampaign(id) {
    return this.request(Method.POST, managementApi(`/campaign/${id}/suspend`));
  }

  resumeCampaign(id) {
    return this.request(Method.POST, managementApi(`/campaign/${id}/resume`));
  }

  stopCampaign(id) {
    return this.request(Method.POST, managementApi(`/campaign/${id}/stop`));
  }

  exportCampaigns(ids, tags, format) {
    const queryString = encodeQuery({ ids, tags, format });

    return this.request(
      Method.GET,
      managementApi(`/campaign/search/csv${queryString}`)
    );
  }

  bulkUpload(file) {
    const data = new FormData();
    data.append("file", file);
    return this.request(
      Method.POST,
      managementApi("/campaign/bulk-upload"),
      data,
      {
        [Header.CONTENT_TYPE]: MediaType.MultipartFormData,
      }
    );
  }

  downloadFailedBulkUploadRecords(bulkUploadId) {
    return this.request(
      Method.GET,
      managementApi(`/campaign/bulk-upload/failed/${bulkUploadId}`)
    );
  }

  downloadSuccessfulBulkUploadBarcodeRecords(bulkUploadId) {
    return this.request(
      Method.GET,
      managementApi(`/campaign/bulk-upload/${bulkUploadId}/barcodes`)
    );
  }

  downloadSuccessfulBulkUploadFinanceRecords(bulkUploadId) {
    return this.request(
      Method.GET,
      managementApi(`/campaign/bulk-upload/${bulkUploadId}/finance`)
    );
  }

  getBulkUploads(page, filename) {
    const params = {
      page: page - 1, // The API starts counting pages at 0
      filename: filename ? filename : null,
    };
    return this.request(
      Method.GET,
      managementApi(`/campaign/history${encodeQuery(params)}`)
    );
  }
}

export default CampaignClient;
