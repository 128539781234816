import { GridItem } from "@jsluna/grid";
import { Text } from "@jsluna/typography";

export const NoResultFound = () => {
  return (
    <GridItem size="1/1" className="ln-u-text-align-center">
      <Text element="span" typeStyle="label-1">
        No result found
      </Text>
    </GridItem>
  );
};
export default NoResultFound;
