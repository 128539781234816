import classnames from "classnames";
import React from "react";

import {
  ButtonGroupPrimary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { ListItem } from "@jsluna/list";
import { Modal, ModalHeading } from "@jsluna/modal";

import { toApiFormat } from "../../common/dates";
import { useForm } from "../../common/hooks/useForm";
import "./ConfirmationModal.scss";
import { DateTimeField } from "./FormFields";

const ConfirmationModal = ({
  showModel,
  requestedAction,
  onDismiss,
  onConfirm,
  heading,
  message,
  className,
  selectedItems,
  failingValidationItems,
}) => {
  const { useField } = useForm();

  const canUpdateCommit =
    requestedAction === "commit" &&
    failingValidationItems &&
    failingValidationItems.length > 0;
  const canBulkUpdate = requestedAction === "bulkEdit";

  const startDate = useField({
    initialValue: "",
    requiredIfActive: canUpdateCommit ? true : false,
    validations: [
      [
        (v) => v >= toApiFormat(new Date()),
        `Start date must not be in the past ${
          canBulkUpdate ? "(Optional)" : ""
        }`,
      ],
    ],
  });

  const endDate = useField({
    initialValue: "",
    requiredIfActive: canUpdateCommit ? true : false,
    validations: [
      [
        (v) => v >= toApiFormat(new Date()),
        `Expiry date must not be in the past ${
          canBulkUpdate ? "(Optional)" : ""
        }`,
      ],
      [
        (v) => !startDate.value || v > startDate.value,
        "Must be after Campaign Start",
      ],
    ],
  });

  const getApiValue = (fieldProps) => {
    if (fieldProps.disabled) {
      return null;
    }
    if (fieldProps.value === "") {
      return null;
    }
    return fieldProps.value;
  };

  const requestBody = {
    startDate: getApiValue(startDate),
    endDate: getApiValue(endDate),
  };

  return (
    <div
      className={classnames(
        "management_portal_confirmation_modal",
        { management_portal_confirmation_modal_open: showModel },
        { management_portal_confirmation_modal_closed: !showModel },
        className
      )}
    >
      <Modal
        fullScreen
        restrictClose
        alert
        handleClose={onDismiss}
        open={showModel}
        headingId="dialog-modal"
        className={canBulkUpdate || canUpdateCommit ? "overlayModel" : ""}
      >
        <ModalHeading element="h3">{heading}</ModalHeading>
        <p>{message}</p>

        {canBulkUpdate && (
          <GridItem size={failingValidationItems ? "1/2" : "1"}>
            <p className="ln-c-label">Selected campaign names</p>
            <div className="selectionList">
              {selectedItems.map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </div>
          </GridItem>
        )}

        {canUpdateCommit && (
          <GridItem size={failingValidationItems ? "1/2" : "1"}>
            <p className="ln-c-label">Selected campaign names</p>
            <div className="selectionList">
              {selectedItems.map((item) => (
                <ListItem key={item.id}>{item.name}</ListItem>
              ))}
            </div>
          </GridItem>
        )}

        {canUpdateCommit && (
          <GridItem size="1/2">
            <p className="ln-c-label">Failed validation campaign names</p>
            <div className="selectionList">
              {failingValidationItems.map((item) => (
                <ListItem key={item.id}>{item.name}</ListItem>
              ))}
            </div>
          </GridItem>
        )}
        {(canUpdateCommit || canBulkUpdate) && (
          <GridWrapper>
            <GridItem size={{ md: "1/2" }}>
              {canUpdateCommit && (
                <p className="ln-c-label">Start date for failing campaigns</p>
              )}
              {canBulkUpdate && <p className="ln-c-label">Start date</p>}
              <DateTimeField
                name="campaign-expiry"
                defaultTime="00:00:00"
                placeholder="Select campaign start date (Default to time: 00:00)"
                {...startDate.props}
                time={false}
              />
            </GridItem>
            <GridItem size={{ md: "1/2" }}>
              {canUpdateCommit && (
                <p className="ln-c-label">End date for failing campaigns</p>
              )}
              {canBulkUpdate && <p className="ln-c-label">End date</p>}
              <DateTimeField
                name="Campaign-expiry"
                placeholder="Select campaign expiry date (Default to time: 23:59)"
                {...endDate.props}
                time={false}
              />
            </GridItem>
          </GridWrapper>
        )}
        <ButtonGroupWrapper>
          <ButtonGroupPrimary>
            <OutlinedButton className="ln-u-margin-right" onClick={onDismiss}>
              Close
            </OutlinedButton>
            <FilledButton
              onClick={() => onConfirm(requestBody)}
              disabled={
                canUpdateCommit
                  ? !startDate.value ||
                    !endDate.value ||
                    !!startDate.errorMessage ||
                    !!endDate.errorMessage
                  : canBulkUpdate
                  ? (!startDate.value && !endDate.value) ||
                    (!!startDate.errorMessage && !!endDate.errorMessage)
                  : false
              }
            >
              {canUpdateCommit ? "Update & Commit" : "Confirm"}
            </FilledButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
