import React, { createContext, useContext, useState } from "react";

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [userRoles, setUserRoles] = useState([]);
  const [isRoleBasedAccess, setIsRoleBasedAccess] = useState(false);

  const clearData = () => {
    setUserRoles([]);
  };

  return (
    <SessionContext.Provider
      value={{
        userRoles,
        setUserRoles,
        clearData,
        isRoleBasedAccess,
        setIsRoleBasedAccess,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => {
  return useContext(SessionContext);
};
