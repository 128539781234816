import React from "react";
import { DateTimePicker } from "react-widgets";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

export const PodDatesInput = ({ pod, handleChangeDate }) => (
  <GridWrapper>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
      <Label>Homepage Pod Start</Label>
      <br />
      <DateTimePicker
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "2px #dedfe0",
          fontFamily: "sans-serif",
          border: "1px solid #dedfe0",
          borderRadius: "4px",
          color: "#dedfe0",
        }}
        culture="en"
        value={pod.startDateTime && new Date(pod.startDateTime)}
        onChange={(e) => handleChangeDate(e, "startDateTime")}
        min={new Date()}
      />
    </GridItem>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
      <Label>Homepage Pod End</Label>
      <br />
      <DateTimePicker
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "2px #dedfe0",
          fontFamily: "sans-serif",
          border: "1px solid #dedfe0",
          borderRadius: "4px",
          color: "#dedfe0",
        }}
        culture="en"
        value={pod.endDateTime && new Date(pod.endDateTime)}
        onChange={(e) => handleChangeDate(e, "endDateTime")}
        min={new Date()}
      />
    </GridItem>
  </GridWrapper>
);
