import React from "react";

import { Alert, AlertIcon } from "@jsluna/alert";
import {
  ButtonGroupPrimary,
  ButtonGroupWrapper,
  FilledButton,
  IconButton,
  TextButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label, SelectField, TextInputField } from "@jsluna/form";
import { GridItem } from "@jsluna/grid";
import { Delete, InfoCircle, Upload } from "@jsluna/icons";

const UserGroupDialogModal = ({
  openUserGrpDialog,
  activeTab,
  userGroupArray,
  eventAudienceVal,
  handleEventAudienceChange,
  userGroupName,
  handleChange,
  uploadInputRef,
  onOldUploadFileChange,
  onUploadClick,
  uploadedFiles,
  MAX_COUNT,
  removeFile,
  alertOpen,
  setAlertOpen,
  isCsvFileUploaded,
  resetValues,
  createUserGroup,
  addCsvToUserGrp,
  openDeleteConfirmDialog,
  openCommitConfirmDialog,
  openIngestConfirmDialog,
  openDuplicateConfirmDialog,
  confirmAction,
}) => {
  const ShowAlert = (props) => {
    return (
      <Alert
        variant={props.variant}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        squared
      >
        <AlertIcon>
          <InfoCircle aria-label="Information" role="img" />
        </AlertIcon>
        {props.message}
      </Alert>
    );
  };

  return (
    <Card className={openUserGrpDialog && "custom-card"}>
      {openUserGrpDialog && activeTab === "create" ? (
        <TextInputField
          name="id"
          label="User group name"
          placeholder="eg:- CFC-2023-HI"
          value={userGroupName}
          onChange={handleChange}
        />
      ) : (
        openUserGrpDialog &&
        activeTab === "edit" && (
          <SelectField
            name="UserGroupName"
            label="User group name"
            options={userGroupArray ? userGroupArray : []}
            value={eventAudienceVal}
            onChange={(e) => handleEventAudienceChange(e)}
          />
        )
      )}
      {openUserGrpDialog && (
        <div className="ln-u-margin-top*3">
          <input
            id="bulk-upload-file"
            type="file"
            hidden
            name="file"
            ref={uploadInputRef}
            onChange={(e) => onOldUploadFileChange(e)}
            accept=".csv"
            multiple
          />
          <IconButton
            onClick={onUploadClick}
            variant="outlined"
            label="Browse CSV"
            disabled={uploadedFiles.length === MAX_COUNT}
          >
            <Upload />
          </IconButton>
          <Label className="modal-csv-browse-info">
            Browse multiple files upto {MAX_COUNT} and upload
          </Label>
          {uploadedFiles &&
            uploadedFiles.map((file, index) => (
              <div>
                <Label className="modal-csv-file-label">
                  {file.name}
                  <button onClick={() => removeFile(index)}>
                    <Delete />
                  </button>
                </Label>
              </div>
            ))}
        </div>
      )}
      {openUserGrpDialog &&
      uploadedFiles.length &&
      alertOpen &&
      isCsvFileUploaded ? (
        <GridItem className="padding-left-none">
          <ShowAlert
            variant={"info"}
            message={`${uploadedFiles.length} file${
              uploadedFiles.length > 1 ? "s are" : " is"
            } ready for Upload`}
          />
        </GridItem>
      ) : (
        alertOpen &&
        !isCsvFileUploaded && (
          <GridItem className="padding-left-none">
            <ShowAlert variant={"warning"} message={"Please upload CSV file"} />
          </GridItem>
        )
      )}
      <ButtonGroupWrapper actionbar="true">
        <ButtonGroupPrimary>
          <TextButton onClick={resetValues}>Cancel</TextButton>
          {openUserGrpDialog ? (
            <FilledButton
              className="ln-u-margin-left"
              onClick={
                userGroupName
                  ? () => createUserGroup()
                  : eventAudienceVal
                  ? () => addCsvToUserGrp()
                  : ""
              }
              disabled={
                !((userGroupName || eventAudienceVal) && uploadedFiles.length)
              }
            >
              Upload & Create
            </FilledButton>
          ) : (
            (openDeleteConfirmDialog ||
              openCommitConfirmDialog ||
              openIngestConfirmDialog ||
              openDuplicateConfirmDialog) && (
              <FilledButton
                className="ln-u-margin-left"
                onClick={() => confirmAction()}
              >
                {openDeleteConfirmDialog
                  ? "Delete"
                  : openCommitConfirmDialog
                  ? "Commit"
                  : openIngestConfirmDialog
                  ? "Ingest"
                  : "Duplicate"}
              </FilledButton>
            )
          )}
        </ButtonGroupPrimary>
      </ButtonGroupWrapper>
    </Card>
  );
};

export default UserGroupDialogModal;
