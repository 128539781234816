import React from "react";

import { PodAccessibilityTextInput } from "./podforminputs/PodAccessibilityTextInput";
import { PodClickUrlInput } from "./podforminputs/PodClickUrlInput";
import { PodColorsGroup } from "./podforminputs/PodColorsGroup";
import { PodDatesInput } from "./podforminputs/PodDatesInput";
import { PodFeatureStateGroup } from "./podforminputs/PodFeatureStateGroup";
import { PodImagesInput } from "./podforminputs/PodImagesInput";
import { PodSubTextInput } from "./podforminputs/PodSubTextInput";
import { PodTitleInput } from "./podforminputs/PodTitleInput";

export const PodEditorForm = ({
  pod,
  handleChange,
  handleChangeDate,
  handleColorChange,
}) => {
  return (
    <div>
      <PodTitleInput pod={pod} handleChange={handleChange} />

      <PodSubTextInput pod={pod} handleChange={handleChange} />

      <PodClickUrlInput pod={pod} handleChange={handleChange} />

      <PodColorsGroup pod={pod} handleColorChange={handleColorChange} />

      <PodFeatureStateGroup pod={pod} handleChange={handleChange} />

      <PodDatesInput pod={pod} handleChangeDate={handleChangeDate} />

      <PodAccessibilityTextInput pod={pod} handleChange={handleChange} />

      <PodImagesInput pod={pod} handleChange={handleChange} />
    </div>
  );
};
