import React from "react";

import Header from "./Header";

const Layout = ({ children }) => (
  <div>
    <Header />

    <div style={{ padding: "1rem" }}>{children}</div>
  </div>
);

export default Layout;
