import React from "react";
import { useNavigate } from "react-router-dom";

import { FilledButton } from "@jsluna/button";

import notFoundIcon from "../../assets_V2/notFoundIcon.svg";
import "./NotFoundView.scss";

const AccessDeniedView = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="notFoundContainer fade-in">
      <img className="notFoundIcon" src={notFoundIcon} alt="Not Found Icon" />
      <h2>Access Denied!</h2>
      <h4 className="notFoundDesc">
        You don't have the right permission for this page.
      </h4>
      <div>
        <FilledButton onClick={handleGoHome}>Go to the Home page</FilledButton>
      </div>
    </div>
  );
};

export default AccessDeniedView;
