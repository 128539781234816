import React from "react";

import ProductClient from "../../services/ProductClient";
import { LoadingState } from "../../services/http.js";
import GroupTable from "./GroupTable";
import PagedTable from "./PagedTable";

const productClient = new ProductClient();
const WAIT_INTERVAL = 500;

class GroupListAspect extends React.Component {
  state = {
    page: 0,
    totalPages: 0,
    groups: [],
  };

  loadGroups = (page = 0) => {
    return productClient.listGroups(page).then((data) => {
      this.setState({
        page: data.currentPage,
        totalPages: data.totalPages,
        groups: data.groups,
      });
    });
  };

  pagingNavigator = (amount) => {
    const { page, totalPages } = this.state;
    clearTimeout(this._searchTimeout);

    if (page + amount < totalPages && page + amount >= 0) {
      this._searchTimeout = setTimeout(() => {
        this.setState({ loadingState: LoadingState.PENDING });
        this.loadGroups(page + amount);
      }, WAIT_INTERVAL);
    }
  };

  handleGroupCheck = (group) => {
    if (group.status === "LOCKED") {
      alert("This group is locked.");
      return;
    }
    this.props.onGroupCheck(group);
  };

  componentDidMount = () => {
    this.loadGroups();
  };

  render() {
    const { page, totalPages, groups } = this.state;
    const { selectedGroups } = this.props;
    return (
      <PagedTable
        page={page}
        totalPages={totalPages}
        items={groups}
        pagingNavigator={this.pagingNavigator}
      >
        {this.props.children}
        <GroupTable
          groups={groups}
          onRowCheck={this.handleGroupCheck}
          onRowSelect={this.props.onGroupSelect}
          selectedGroups={selectedGroups}
        />
      </PagedTable>
    );
  }
}

export default GroupListAspect;
