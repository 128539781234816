import React, { useState } from "react";
import { Image } from "semantic-ui-react";

import { OutlinedButton } from "@jsluna/button";
import { Modal, ModalHeading } from "@jsluna/modal";

import { defaultDateTimeFormat } from "../../common/dates";
import "./PodAssetsList.scss";

const PodAssetsList = (podsAssets) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(undefined);

  const PodAssetTable = (props) => (
    <div className="homepagePodList__table">
      <table className="ln-c-table" celled striped responsive>
        {props.children}
      </table>
    </div>
  );

  const PodAssetTableHeader = () => (
    <thead className="ln-c-table__header">
      <tr className="ln-c-table__row ln-c-table__header-row">
        {/* <th>Name</th> */}
        <th>URL</th>
        <th>Last Modified Date</th>
        <th>Copy Image Url</th>
        <th>Preview Image</th>
      </tr>
    </thead>
  );

  const allAssets = podsAssets.podsAssets && podsAssets.podsAssets.content;

  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
  };

  const previewImage = (imageUrl) => {
    setImagePreview(imageUrl);
    setIsOpen(true);
  };

  return (
    <>
      <PodAssetTable>
        <PodAssetTableHeader />
        <tbody className="ln-c-table__body">
          {allAssets && allAssets.length > 0 ? (
            allAssets.map((assets, i) => (
              <tr className="ln-c-table__row ln-c-table__header-row" key={i}>
                <td className="ln-c-table__cell">
                  {assets.name &&
                    assets.name.substring(assets.name.lastIndexOf("/") + 1)}
                </td>
                <td className="ln-c-table__cell">
                  {defaultDateTimeFormat(
                    assets.lastModifiedDate
                      ? assets.lastModifiedDate
                      : "No moodifiedDate available"
                  )}
                </td>
                <td className="ln-c-table__cell">
                  <OutlinedButton
                    className="ln-u-margin-right"
                    onClick={() => {
                      copyToClipboard(assets.url);
                    }}
                  >
                    Copy
                  </OutlinedButton>
                </td>
                <td className="ln-c-table__cell">
                  <OutlinedButton
                    className="ln-u-margin-right"
                    onClick={() => {
                      previewImage(assets.url);
                    }}
                  >
                    Preview
                  </OutlinedButton>
                </td>
              </tr>
            ))
          ) : (
            <tr className="ln-c-table__row ln-c-table__header-row">
              <td
                className="ln-c-table__cell"
                colSpan={4}
                style={{ textAlign: "center" }}
              >
                No Asset data available
              </td>
            </tr>
          )}
        </tbody>
      </PodAssetTable>

      <Modal
        fullScreen
        handleClose={() => setIsOpen(false)}
        open={isOpen}
        className="homepagepod-image-preview-modal"
      >
        <ModalHeading element="h3">Image Preview</ModalHeading>
        <p>
          <Image src={imagePreview} />
        </p>
      </Modal>
    </>
  );
};

export default PodAssetsList;
