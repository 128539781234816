import React from "react";

import { Label } from "@jsluna/form";

import { OfferCodeDescriptionInput } from "./offercodeforminputs/OfferCodeDescriptionInput";
import { OfferCodeDetailsInput } from "./offercodeforminputs/OfferCodeDetailsInput";
import { OfferCodeDisplayTypeSelect } from "./offercodeforminputs/OfferCodeDisplayTypeSelect";
import { OfferCodeIdInput } from "./offercodeforminputs/OfferCodeIdInput";

export const OfferCodeEditorForm = ({
  offerCode,
  handleChange,
  isNewOfferCode,
}) => {
  return (
    <div>
      {!isNewOfferCode && (
        <div>
          <Label>ID</Label>
          <br /> {offerCode.id}
        </div>
      )}
      <br />
      {isNewOfferCode && (
        <OfferCodeIdInput offerCode={offerCode} handleChange={handleChange} />
      )}

      <OfferCodeDisplayTypeSelect
        offerCode={offerCode}
        handleChange={handleChange}
      />

      <OfferCodeDetailsInput
        offerCode={offerCode}
        handleChange={handleChange}
      />

      <OfferCodeDescriptionInput
        offerCode={offerCode}
        handleChange={handleChange}
      />
    </div>
  );
};
