import React from "react";

import ProductClient from "../../services/ProductClient";
import { LoadingState } from "../../services/http.js";
import PagedTable from "./PagedTable";
import ProductTable from "./ProductTable";

const productClient = new ProductClient();
const WAIT_INTERVAL = 500;

class GroupDetailAspect extends React.Component {
  state = {
    page: 0,
    totalPages: 0,
    products: [],
  };

  loadGroupProducts = (page = 0) => {
    return productClient
      .getGroupProducts(this.props.group.id, page)
      .then((data) => {
        this.setState({
          page: data.currentPage,
          totalPages: data.totalPages,
          products: data.products,
        });
      });
  };

  pagingNavigator = (amount) => {
    const { page, totalPages } = this.state;
    clearTimeout(this._searchTimeout);

    if (page + amount < totalPages && page + amount >= 0) {
      this._searchTimeout = setTimeout(() => {
        this.setState({ loadingState: LoadingState.PENDING });
        this.loadGroupProducts(page + amount);
      }, WAIT_INTERVAL);
    }
  };

  componentDidMount = () => {
    this.loadGroupProducts();
  };

  render() {
    const { page, totalPages, products } = this.state;
    return (
      <div>
        <PagedTable
          page={page}
          totalPages={totalPages}
          items={products}
          pagingNavigator={this.pagingNavigator}
        >
          {this.props.children}
          <ProductTable
            products={products}
            selectedProducts={[]}
            onProductSelect={() => {}}
          />
        </PagedTable>
      </div>
    );
  }
}

export default GroupDetailAspect;
