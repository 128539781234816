import React from "react";

import { Label, TextInput } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

export const PodSubTextInput = ({ pod, handleChange }) => (
  <GridWrapper>
    <GridItem style={{ margin: "1rem 0 0 0" }}>
      <Label>Sub Text</Label>
      <TextInput
        value={pod?.header?.data?.subtitle}
        name="subtitle"
        placeholder="sub text"
        onChange={(e) => {
          handleChange(e, "header");
        }}
      />
    </GridItem>
  </GridWrapper>
);
