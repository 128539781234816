export const footerImageListConfig = [
  {
    id: 1,
    imageUrl:
      "https://s3.eu-west-1.amazonaws.com/assets.pianoinfradev.com/nectarlogo.png",
    altText: "NectarLogo",
  },
  {
    id: 2,
    imageUrl:
      "https://s3.eu-west-1.amazonaws.com/assets.pianoinfradev.com/images/argos/argos_footer_2.jpg",
    altText: "ArgosLogo",
  },
  {
    id: 3,
    imageUrl:
      "https://s3.eu-west-1.amazonaws.com/assets.pianorewards.com/images/british-airways/BA_Megapod_Footer_09.png",
    altText: "BALogo",
  },
  {
    id: 4,
    imageUrl:
      "https://s3.eu-west-1.amazonaws.com/assets.pianorewards.com/images/sainsburys_bank_logo.png",
    altText: "SainsburysBankLogo",
  },
  {
    id: 5,
    imageUrl:
      "	https://cdn.pi.nectar.com/binaries/content/gallery/nectarwebsite/logos/ebay-352x160.png",
    altText: "EbayLogo",
  },
];
