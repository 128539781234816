import { HttpClient, Method, managementApi } from "./http";

class LedgerClient extends HttpClient {
  getTransactions(id, offset, limit) {
    return this.request(
      Method.GET,
      managementApi(
        `/transaction?collectorId=${id}&limit=${limit}&offset=${offset}`
      ),
      {}
    );
  }

  getBaskets(externalHandle, year, month) {
    return this.request(
      Method.GET,
      managementApi(
        `/transaction/${externalHandle}${
          year && month ? "?year=" + year + "&month=" + month : ""
        }`
      ),
      {}
    );
  }
}

export default LedgerClient;
