import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, Message } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { SelectField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Modal, ModalHeading } from "@jsluna/modal";

import "../../main.scss";
import ManagementUserClient from "../../services/ManagementUserClient";
import { LoadingState } from "../../services/http";
import { getSessionCookieData } from "../../services/sessionCookie";

const managementUserClient = new ManagementUserClient();

const UserManagementView = () => {
  const [user, setUser] = useState(undefined);
  const [loadingState, setLoadingState] = useState(LoadingState.PENDING);
  // const [isEmailBeingEdited, setIsEmailBeingEdited] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [roleSelected, setRoleSelected] = useState("");
  const [resetClicked, setResetClicked] = useState(false);
  const [setRoleClicked, setSetRoleClicked] = useState(false);
  const [setDeactivateClicked, setSetDeactivateClicked] = useState(false);
  const sessionData = getSessionCookieData();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    initialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const initialState = () => {
    managementUserClient
      .fetchUser(id)
      .then(({ data }) => {
        setUser(data);
        setLoadingState(LoadingState.SUCCESS);
      })
      .catch((err) => {
        console.log(err);
        setLoadingState(LoadingState.FAILED);
      });
  };

  // const toggleEmailEditing = () => {
  //   setIsEmailBeingEdited(!isEmailBeingEdited);
  // };

  const onEmailChanged = (newEmail) => {
    setUser({ ...user, email: newEmail });
  };

  const switchPermission = (permissionId) => {
    managementUserClient
      .switchPermission(user.id, permissionId)
      .then(() => {
        initialState();
      })
      .catch((err) => {
        console.log(err);
        setLoadingState(LoadingState.FAILED);
      });
  };

  const tagUser = (tag) => {
    managementUserClient
      .tagUser(user.id, tag)
      .then(() => {
        initialState();
        setSetRoleClicked(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingState(LoadingState.FAILED);
      });
  };

  const deactivateUser = () => {
    managementUserClient
      .deactivate(user.id)
      .then(() => navigate(`/management-user`))
      .catch((err) => {
        console.log(err);
        setLoadingState(LoadingState.FAILED);
      });
  };

  // const dropdownChanged = (e, t) => {
  //   tagUser(t.value);
  // };

  const updateEmail = (e) => {
    setEmailValue(e.target.value);
  };

  const saveNewEmailAndStopEditing = () => {
    managementUserClient.updateDetails(user.id, emailValue);
    onEmailChanged(emailValue);
    setResetClicked(false);
  };

  const dropDownRender = () => {
    const options = [
      { label: "Developer", value: "Developer" },
      { label: "Careline", value: "Careline" },
      { label: "Campaign Editor", value: "Campaign Editor" },
      { label: "Administrator", value: "Administrator" },
    ];
    return (
      <SelectField
        name="select-field-1"
        label="Choose Role"
        options={options}
        onChange={(e) => selectRole(e)}
      />
    );
  };

  const selectRole = (e) => {
    setRoleSelected(e.value ? e.value : e.target.value);
  };

  const ResetModal = () => (
    <Modal
      open={resetClicked}
      fullScreen
      restrictClose
      alert
      headingId="dialog-modal"
    >
      <ModalHeading element="h3">E-mail reset</ModalHeading>
      <div className="ln-c-form-group">
        <div className="ln-c-input-group">
          <input
            id="form-group-1"
            name="form-group-1"
            type="text"
            className="ln-c-text-input ln-c-input-group__control"
            placeholder="Enter a valid email"
            required
            aria-invalid="false"
            value={emailValue}
            onChange={updateEmail}
          />
        </div>
      </div>
      <ButtonGroupPrimary>
        <FilledButton onClick={saveNewEmailAndStopEditing}>
          Confirm
        </FilledButton>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <OutlinedButton onClick={() => setResetClicked(false)}>
          Close
        </OutlinedButton>
      </ButtonGroupPrimary>
    </Modal>
  );

  const SetRoleModal = () => (
    <Modal
      open={setRoleClicked}
      fullScreen
      restrictClose
      alert
      headingId="dialog-modal"
      className="modal-overflow"
    >
      <ModalHeading element="h3">Set Role</ModalHeading>
      {dropDownRender()}
      <ButtonGroupPrimary>
        <FilledButton onClick={() => tagUser(roleSelected)}>
          Confirm
        </FilledButton>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <OutlinedButton onClick={() => setSetRoleClicked(false)}>
          Close
        </OutlinedButton>
      </ButtonGroupPrimary>
    </Modal>
  );

  const DeactivateModal = () => (
    <Modal
      open={setDeactivateClicked}
      fullScreen
      restrictClose
      alert
      headingId="dialog-modal"
      className="modal-overflow"
    >
      <p>
        <b>Are you sure you want to deactivate this account?</b>
      </p>
      <ButtonGroupPrimary>
        <FilledButton onClick={deactivateUser}>Confirm</FilledButton>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <OutlinedButton onClick={() => setSetDeactivateClicked(false)}>
          Close
        </OutlinedButton>
      </ButtonGroupPrimary>
    </Modal>
  );

  return (
    <>
      {loadingState === LoadingState.SUCCESS ? (
        <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
          {ResetModal()}
          {SetRoleModal()}
          {DeactivateModal()}
          <GridWrapper>
            <GridItem size="1/2">
              <p style={{ fontSize: "1.8rem", margin: "0" }}>
                <b>User: {user.username}</b>
              </p>
              <p style={{ fontSize: "1.8rem", margin: "0" }}>
                <b>
                  Email: {user.email ? user.email : "Email ID not available"}
                </b>
              </p>
              <p>
                <b>Last Updated:</b>&nbsp;&nbsp;{user.updated}
              </p>
            </GridItem>
            <GridItem size="1/2">
              <ButtonGroupPrimary>
                {sessionData.permissions.includes("management_user:write") && (
                  <FilledButton onClick={() => setSetRoleClicked(true)}>
                    Set Role
                  </FilledButton>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <FilledButton onClick={() => setResetClicked(true)}>
                  Change e-mail
                </FilledButton>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <FilledButton onClick={() => setSetDeactivateClicked(true)}>
                  Deactivate User
                </FilledButton>
              </ButtonGroupPrimary>
            </GridItem>
            <GridItem style={{ marginTop: "1rem" }}>
              <div className="ln-c-table-container">
                <table className="ln-c-table">
                  <caption className="ln-c-table__caption ln-u-visually-hidden">
                    Access Details.
                  </caption>
                  <thead className="ln-c-table__header">
                    <tr className="ln-c-table__row ln-c-table__header-row">
                      <th className="ln-c-table__header-cell customCol">
                        Permissions
                      </th>
                      <th className="ln-c-table__header-cell customHead">
                        Active Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="ln-c-table__body">
                    {loadingState === LoadingState.SUCCESS &&
                      user.permissions
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((permission, i) => (
                          <PermissionSummaryRow
                            key={i}
                            permission={permission}
                            switchPermission={switchPermission}
                          />
                        ))}
                  </tbody>
                </table>
              </div>
            </GridItem>
          </GridWrapper>
        </Card>
      ) : null}

      <Message negative hidden={loadingState !== LoadingState.FAILED}>
        <Message.Header>Error loading user</Message.Header>
      </Message>
    </>
  );
};

const PermissionSummaryRow = ({ permission, switchPermission }) => (
  <tr className="ln-c-table__row">
    <td className="ln-c-table__cell">{permission.name}</td>
    <td className="ln-c-table__cell">
      <Checkbox
        checked={permission.hasPermission}
        onClick={() => switchPermission(permission.id)}
      />
    </td>
  </tr>
);

PermissionSummaryRow.propTypes = {
  switchPermission: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hasPermission: PropTypes.bool.isRequired,
  }).isRequired,
};

export default UserManagementView;
