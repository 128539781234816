import React from "react";

import { useSessionContext } from "../contexts/SessionContext";

export const userRolesPermissions = (WrappedComp, reqPermission) => {
  return (props) => {
    const { userRoles, isRoleBasedAccess } = useSessionContext();

    // Check for role-based access
    if (isRoleBasedAccess) {
      const hasPermission = userRoles.some((role) =>
        reqPermission.includes(role)
      );

      // Redirect if permission is denied
      if (!hasPermission) {
        window.location = "/403";
        return null; // Return null to avoid rendering anything
      }
    }

    // Render the wrapped component with the provided props
    return <WrappedComp {...props} />;
  };
};
