import { useState } from "react";

export const useListSelection = (items, idSelector) => {
  const [selectedIds, setSelectedIds] = useState([]);

  const selectedItems = (items ?? []).filter((item) =>
    selectedIds.includes(idSelector(item))
  );
  const resetSelection = () => setSelectedIds([]);

  const noneSelected = selectedIds.length < 1;

  const selectAll = () => {
    if (!noneSelected) {
      setSelectedIds([]);
    } else {
      setSelectedIds(items.map(idSelector));
    }
  };

  const selectItem = (item) => {
    const itemId = idSelector(item);
    const index = selectedIds.findIndex((row) => row === itemId);
    if (index === -1) setSelectedIds([...selectedIds, itemId]);
    else setSelectedIds(selectedIds.filter((row) => row !== itemId));
  };

  const itemSelected = (item) =>
    selectedIds.some((id) => id === idSelector(item));
  const allSelected = items.every((item) =>
    selectedIds.includes(idSelector(item))
  );

  return {
    selectedIds,
    selectedItems,
    resetSelection,
    selectAll,
    selectItem,
    itemSelected,
    noneSelected,
    allSelected,
  };
};
