import classnames from "classnames";
import React, { useState } from "react";

import { Button } from "@jsluna/button";
import { ArrowDown } from "@jsluna/icons";
import { ListGroupItem } from "@jsluna/list";
import { ClickAway } from "@jsluna/utils";

import "./ButtonDropdown.scss";

export const ButtonDropdown = ({
  disabled,
  children,
  label,
  icon,
  variant = "filled",
}) => {
  const [active, setActive] = useState(false);
  return (
    <div className="management-portal-dropdown">
      <ul className="ln-c-list-group ln-c-list-group--actionable ln-c-list-group--full ln-c-list-group--inline">
        <ClickAway onClickAway={() => setActive(false)}>
          <li className="ln-c-list-group__dropdown-container">
            <Button
              variant={variant}
              disabled={disabled}
              className={classnames(
                { "is-active": active },
                "management-portal-dropdown_button",
                { "ln-c-button--icon-text": icon }
              )}
              onClick={(e) => setActive((a) => !a)}
            >
              {icon &&
                React.cloneElement(icon, {
                  className: icon.props.className + " ln-c-button__icon",
                })}
              {label}
              <ArrowDown className="ln-c-button__icon ln-c-list-group__dropdown-arrow" />
            </Button>
            <ul className="ln-c-list-group ln-c-list-group--dropdown management-portal-dropdown_list">
              {children}
            </ul>
          </li>
        </ClickAway>
      </ul>
    </div>
  );
};

export const ButtonDropdownItem = ({ children, className, ...rest }) => {
  return (
    <ListGroupItem
      {...rest}
      className={classnames(className, "management-portal-dropdown_item")}
    >
      {children}
    </ListGroupItem>
  );
};
