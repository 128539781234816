import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Alert, AlertIcon } from "@jsluna/alert";
import { Button, IconButton } from "@jsluna/button";
import { Form, FormGroup, SearchField } from "@jsluna/form";
import { GridItem } from "@jsluna/grid";
import {
  Download,
  ErrorCircle,
  GridView,
  InfoCircle,
  Plus,
  Search,
  Upload,
} from "@jsluna/icons";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";
import { Table } from "@jsluna/table";
import { Text } from "@jsluna/typography";

import { InlineGroup } from "../../common/components/InlineGroup";
import NoResultFound from "../../common/components/NoResultFound";
import PageContainer from "../../common/components/PageContainer";
import { PageHeader } from "../../common/components/PageHeader";
import Pagination from "../../common/components/Pagination";
import StatusIndicator from "../../common/components/StatusIndicator";
import { triggerDownload } from "../../common/components/downloadUtils";
import { toDisplayString } from "../../common/dates";
import { toApiFormat } from "../../common/dates";
import { useAsyncActionState } from "../../common/hooks/useAsyncActionState";
import { useSearch } from "../../common/hooks/useSearch";
import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import PromotionsClient from "../../services/PromotionsClient";
import { LoadingState } from "../../services/http";

const apiClient = new PromotionsClient();

const getDashboardData = async (page, filters) => {
  const response = await apiClient.getPromotions(
    filters.id,
    filters.name,
    page
  );
  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
};

const downloadExport = async (id, name) => {
  try {
    if (!name && !id) {
      console.error("Download triggered but button should be disabled");
      return;
    }
    let downloadName;
    let response;
    if (name) {
      downloadName = `Promotions_${name}.csv`;
      response = await apiClient.downloadPromotionByName(name);
    } else if (id) {
      downloadName = `Promotions_${id}.csv`;
      response = await apiClient.downloadPromotionById(id);
    }
    if (response.status !== 200) {
      throw new Error(response);
    }
    triggerDownload(downloadName, response.data);
    toast.success("Download Success");
  } catch (err) {
    console.error(err);
    const message = err?.response?.data?.description ?? "";
    toast.error("Failed to download export. " + message);
    throw err;
  }
};

const PromoSearchField = ({
  onSubmit,
  disabled,
  searchValue,
  onSearchValueChanged,
  valueName,
}) => {
  const name = valueName.toLowerCase().replace(" ", "-");
  return (
    <Form onSubmit={onSubmit}>
      <FormGroup name="form-group-3" hideLabel label="Search">
        <SearchField
          name={name}
          label={"Search by " + valueName}
          hideLabel
          placeholder={"Search by " + valueName}
          hasAction
          onChange={onSearchValueChanged}
          value={searchValue}
          action={
            <Button disabled={disabled} type="submit">
              <span className="ln-u-visually-hidden">Search</span>
              <Search />
            </Button>
          }
        />
      </FormGroup>
    </Form>
  );
};

const PromotionDashboard = () => {
  const [idField, setIdField] = useState("");
  const [nameField, setNameField] = useState("");
  const navigate = useNavigate();
  const onIdChanged = (e) => {
    setIdField(e.target.value);
    setNameField("");
  };
  const onNameChanged = (e) => {
    setIdField("");
    setNameField(e.target.value);
  };

  const {
    requestedPage,
    listResults,
    listState,
    lastSearch,
    listReady,
    changePage,
    newSearch,
  } = useSearch(getDashboardData);

  const listError = listState === LoadingState.FAILED;
  const searchSuccess =
    (lastSearch.id || lastSearch.name) && listState === LoadingState.SUCCESS;
  const showResults = listReady && listResults.content;
  const noIdResults =
    searchSuccess && listResults.totalElements <= 0 && lastSearch.id;
  const noNameResults =
    searchSuccess && listResults.totalElements <= 0 && lastSearch.name;
  const searchDisabled = listState === LoadingState.IN_PROGRESS;

  const [actionState, performAction] = useAsyncActionState();
  const actionInProgress = actionState === LoadingState.IN_PROGRESS;
  const downloadDisabled =
    !searchSuccess ||
    !!noIdResults ||
    !!noNameResults ||
    !showResults ||
    actionInProgress;
  const showLoading = searchDisabled || actionInProgress;

  const isCmpgnEditor = HasRequiredRoleAccess("campaigns");

  const onSearchSubmitted = (e) => {
    e.preventDefault();
    if (searchDisabled) {
      return;
    }
    return newSearch({ name: nameField, id: idField });
  };

  const onDownloadClick = (e) => {
    e.preventDefault();
    if (downloadDisabled) {
      return;
    }
    return performAction(() => downloadExport(lastSearch.id, lastSearch.name));
  };

  return (
    <>
      <div className="promo-dashboard">
        <PageContainer>
          <GridItem size={{ md: "1/3" }}>
            <PageHeader>
              <GridView />
              Promotions
            </PageHeader>
          </GridItem>
          <GridItem size={{ md: "1/3" }}>
            <PromoSearchField
              onSubmit={onSearchSubmitted}
              disabled={searchDisabled}
              searchValue={idField}
              onSearchValueChanged={onIdChanged}
              valueName="Promotion Id"
            />
          </GridItem>
          <GridItem size={{ md: "1/3" }}>
            <PromoSearchField
              onSubmit={onSearchSubmitted}
              disabled={searchDisabled}
              searchValue={nameField}
              onSearchValueChanged={onNameChanged}
              valueName="Promotion Name"
            />
          </GridItem>
          <GridItem size="1/1">
            <InlineGroup>
              <IconButton
                variant="filled"
                label="Create Promotion"
                onClick={() => navigate("/promotions/create")}
                disabled={!isCmpgnEditor}
              >
                <Plus />
              </IconButton>
              <IconButton
                variant="outlined"
                label="Bulk Uploads"
                onClick={() => navigate("/promotions/upload")}
              >
                <Upload />
              </IconButton>
              <IconButton
                onClick={onDownloadClick}
                variant="filled"
                disabled={downloadDisabled}
                label="Download promotions export"
              >
                <Download />
              </IconButton>
            </InlineGroup>
          </GridItem>
          <GridItem size="1/1">
            {listError && (
              <Alert variant="error">
                <AlertIcon>
                  <ErrorCircle aria-label="Error" role="img" />
                </AlertIcon>
                Oops! Something has gone wrong. Please try again, and if this
                continues to happen, please contact Tech Support.
              </Alert>
            )}
            {noNameResults && (
              <Alert variant="warning">
                <AlertIcon>
                  <ErrorCircle aria-label="Information" role="img" />
                </AlertIcon>
                Oops! We couldn't find the promotion you were looking for.
                Please check that the Promotion Name is correct and try again.
              </Alert>
            )}
            {noIdResults && (
              <Alert variant="warning">
                <AlertIcon>
                  <ErrorCircle aria-label="Information" role="img" />
                </AlertIcon>
                Oops! We couldn't find the promotion you were looking for.
                Please check that the Promotion ID is correct and try again.
              </Alert>
            )}
            {searchSuccess && listResults.totalElements === 1 && (
              <Alert variant="success">
                <AlertIcon>
                  <InfoCircle aria-label="Information" role="img" />
                </AlertIcon>
                Search success! A total of {listResults.totalElements} promotion
                matched your search.
              </Alert>
            )}
            {searchSuccess && listResults.totalElements > 1 && (
              <Alert variant="success">
                <AlertIcon>
                  <InfoCircle aria-label="Information" role="img" />
                </AlertIcon>
                Search success! A total of {listResults.totalElements}{" "}
                promotions matched your search.
              </Alert>
            )}
          </GridItem>
          {!showResults ? null : listResults.content.length > 0 ? (
            <>
              <GridItem size="1/1">
                <Table
                  data={listResults.content}
                  rowKey="id"
                  caption="Promotion results"
                  visuallyHiddenCaption
                  columns={[
                    {
                      name: "Promotion ID",
                      accessor: (item) => ({
                        value: (
                          <Button
                            variant="text"
                            color="dark"
                            onClick={() =>
                              navigate(`/promotions/edit/${item.id}`)
                            }
                            disabled={!isCmpgnEditor}
                          >
                            {item.id}
                          </Button>
                        ),
                      }),
                    },
                    {
                      name: "Promotion Name",
                      accessor: (item) => ({ value: item.name }),
                    },
                    {
                      name: "Date Created",
                      accessor: (item) => ({
                        value: toDisplayString(item.created),
                      }),
                    },
                    {
                      name: "Campaign Assigned",
                      accessor: (item) => ({
                        value: item.campaigns?.map((campaign) => (
                          <Text
                            element="span"
                            typeStyle="label-1"
                            key={campaign.id}
                          >
                            {campaign.id.split("-")[0] + " | " + campaign.name}
                            <br />
                          </Text>
                        )),
                      }),
                    },
                    {
                      name: "Status",
                      accessor: (item) => {
                        const active =
                          item.expiry_date >= toApiFormat(new Date());
                        return {
                          value: (
                            <StatusIndicator
                              variant={active ? "success" : "disabled"}
                              text={active ? "Active" : "Inactive"}
                            />
                          ),
                        };
                      },
                    },
                  ]}
                />
              </GridItem>
              {listResults.totalPages > 0 && (
                <GridItem size="1/1">
                  <Pagination
                    currentPage={requestedPage}
                    totalPages={listResults.totalPages}
                    onPageChange={changePage}
                  />
                </GridItem>
              )}
            </>
          ) : (
            <NoResultFound />
          )}
        </PageContainer>
      </div>
      {showLoading && (
        <ProgressIndicator page loading preventFocus>
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      )}
    </>
  );
};

export default PromotionDashboard;
