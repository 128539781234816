import React, { useState } from "react";

import { AutocompleteField } from "@jsluna/autocomplete";

export const MultiSelectField = ({ options, value, onChange, ...rest }) => {
  const [search, setSearch] = useState("");

  const onClear = (selectedOption) => {
    setSearch("");
    onChange(value.filter((o) => o !== selectedOption.value));
  };

  const onSelect = (selectedOption) => {
    setSearch("");
    onChange([...value, selectedOption.value]);
  };

  const availableOptions = options.filter((t) => !value.includes(t.value));
  const selectedOptions = options.filter((t) => value.includes(t.value));

  // If the entered value exactly matches one of the unselected options, then promote it to the top of the list
  if (availableOptions.map((x) => x.value).includes(search)) {
    availableOptions.sort((x, y) =>
      x.value === search ? -1 : y.value === search ? 1 : 0
    );
  }

  return (
    <AutocompleteField
      {...rest}
      selectedOptions={selectedOptions}
      onSelect={onSelect}
      onClear={onClear}
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      options={availableOptions}
      multiSelect
    />
  );
};

const toAutocompleteOption = (t) => ({ label: t, value: t });
const defaultNewTagPrompt = (inputValue) => `Add new tag: ${inputValue}`;

export const TagsField = ({
  existingTags,
  value,
  onChange,
  allowAddingTags = false,
  createNewTagPrompt = defaultNewTagPrompt,
  ...rest
}) => {
  const [search, setSearch] = useState("");

  const onClear = (selectedOption) => {
    setSearch("");
    onChange(value.filter((o) => o !== selectedOption.value));
  };

  const onSelect = (selectedOption) => {
    setSearch("");
    onChange([...value, selectedOption.value]);
  };

  // If the entered value exactly matches one of the unselected options, then promote it to the top of the list
  const availableTags = existingTags.filter((t) => !value.includes(t));
  if (availableTags.includes(search)) {
    availableTags.sort((x, y) => (x === search ? -1 : y === search ? 1 : 0));
  }

  const options = availableTags.map(toAutocompleteOption);

  if (allowAddingTags) {
    // If the user has entered something that doesn't exist yet, then prompt them to create it
    if (!!search && ![...existingTags, ...value].includes(search)) {
      options.unshift({
        label: createNewTagPrompt(search),
        value: search,
      });
    }
  }

  return (
    <AutocompleteField
      {...rest}
      selectedOptions={value.map(toAutocompleteOption)}
      onSelect={onSelect}
      onClear={onClear}
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      options={options}
      multiSelect
    />
  );
};
