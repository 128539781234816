import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTimePicker } from "react-widgets";

import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Checkbox, Label, SelectField, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";

import { MultiSelectField } from "../../common/components/FormFields";
import MarketingEventClient from "../../services/MarketingEventClient";
import { iterationsInitialState } from "./Constants";
import "./MarketingEvents.scss";

const marketingEventClient = new MarketingEventClient();

const MarketingEventIterations = () => {
  const navigate = useNavigate();
  const [userGroupNames, setUserGroupNames] = useState(undefined);
  const [marketingEventsList, setMarketingEventsList] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [iterations, setIterations] = useState(iterationsInitialState);
  const [editScreen, setEditScreen] = useState(false);
  const location = useLocation();
  const { editIterations } = location.state || {};

  const currentIterationDetails = editIterations?.rowData;
  const currentIterationDetailsKeys =
    currentIterationDetails &&
    currentIterationDetails[0] &&
    Object.keys(currentIterationDetails[0]);
  const [usergroups, setusergroups] = useState([]);
  const [enableCreate, setEnableCreate] = useState(true);

  useEffect(() => {
    getMarketingEvents().then(() => setLoading(false));
    getUserGroup().then(() => setLoading(false));

    if (editIterations && editIterations.enableEdit === true) {
      setEditScreen(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentIterationDetails && currentIterationDetails[0]) {
      const iterationDetails = currentIterationDetails[0];
      // eslint-disable-next-line
      currentIterationDetailsKeys.map((key) => {
        setIterations((prevState) => ({
          ...prevState,
          [key]: iterationDetails[key],
        }));
      });
      setusergroups(iterationDetails.userGroups);
    }
    // eslint-disable-next-line
  }, [currentIterationDetails]);

  useEffect(() => {
    if (
      iterations.id &&
      iterations.marketingEvent &&
      iterations.startDate &&
      iterations.endDate &&
      usergroups &&
      usergroups.length > 0
    ) {
      setEnableCreate(false);
    } else {
      setEnableCreate(true);
    }
  }, [iterations, usergroups]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setIterations((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setIterations((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleDateChange = (event, name) => {
    setIterations((prevState) => ({
      ...prevState,
      [name]: event,
    }));
  };

  const handleMultiSelectChange = (usergroups) => {
    setusergroups(usergroups);
  };

  const getUserGroup = async () => {
    await marketingEventClient
      .getUserGroup()
      .then(({ data }) =>
        setUserGroupNames(
          data.map((item) => {
            return { label: item.name, value: item.name };
          })
        )
      )
      .catch((err) => {
        setUserGroupNames(undefined);
      });
  };

  const getMarketingEvents = async () => {
    await marketingEventClient
      .fetchMarketingEvents(0, 100, false)
      .then(({ data }) => {
        if (data && data.content && data.content.length > 0)
          setMarketingEventsList(
            data.content.map((item) => {
              return { label: item.id, value: item.id };
            })
          );
      })
      .catch((err) => {
        setMarketingEventsList(undefined);
      });
  };

  const assignUergroupToIterations = () => {
    Promise.all([
      usergroups.map((usergroup) =>
        marketingEventClient
          .assignUserGroupToIteration(iterations.id, usergroup)
          .then((res) => {
            if (res.status === 200) {
              toast.success(
                usergroup + "assigned to" + iterations.id + "Successfully"
              );
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(
              "Failed to assign usergroup to iteration" + iterations.id
            );
          })
      ),
    ]).then(navigate("/marketing-events"));
  };

  const containsWhitespace = (str) => /\s/.test(str);

  const handleCreateIterations = async () => {
    if (iterations.id && containsWhitespace(iterations.id)) {
      toast.error("Please enter id without any white space");
      return;
    }
    setLoading(true);
    await marketingEventClient
      .createMarketingEventIterations(iterations)
      .then((res) => {
        if (res.status === 200) {
          toast.success(iterations.id + "Created Successfully");
          assignUergroupToIterations();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          "Failed to create iteration to ME" + iterations.marketingEvent
        );
      });
  };

  return (
    <React.Fragment>
      <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
        <GridWrapper matrix className="ln-u-margin-top*3">
          <GridItem>
            <Label htmlFor="marketing-events" className="ln-u-margin-left">
              Marketing Event - Iterations
            </Label>
            <br />
            <br />
            <GridItem size={"4/5"}>
              <GridItem size={"1/2"}>
                <TextInputField
                  name="id"
                  label="Iteration Id"
                  placeholder="eg:- SFP24"
                  value={iterations.id}
                  onChange={handleChange}
                  disabled={editScreen}
                />
              </GridItem>
              <GridItem size="1/2" />
              <GridItem size="1/2">
                <SelectField
                  name="marketingEvent"
                  label="Select Marketing Event"
                  placeholder="Select marketing event"
                  options={marketingEventsList ? marketingEventsList : []}
                  value={iterations.marketingEvent}
                  onChange={handleChange}
                  disabled={editScreen}
                />
              </GridItem>
              <GridItem size="1/2" />
              <GridItem size="1/2">
                <MultiSelectField
                  name="usergroup"
                  label="Select Audience"
                  placeholder="Search by usergroup name"
                  options={userGroupNames ? userGroupNames : []}
                  value={usergroups}
                  onChange={handleMultiSelectChange}
                />
              </GridItem>
              <GridItem size="1/2" />
              <GridItem size="1/2">
                <div className="ln-c-form-group">
                  <Label>Event Start Date</Label>
                  <br />
                  <DateTimePicker
                    placeholder="Choose a valid start date"
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                      boxShadow: "2px #737373",
                      fontFamily: "sans-serif",
                      border: "1px solid #737373",
                      borderRadius: "4px",
                      color: "#737373",
                    }}
                    culture="en"
                    min={new Date()}
                    name="startDate"
                    onChange={(e) => handleDateChange(e, "startDate")}
                    value={
                      iterations.startDate && new Date(iterations.startDate)
                    }
                    disabled={editScreen}
                  />
                </div>
              </GridItem>
              <GridItem size="1/2">
                <div className="ln-c-form-group">
                  <Label>Event End Date</Label>
                  <br />
                  <DateTimePicker
                    placeholder="Choose a valid end date"
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                      boxShadow: "2px #737373",
                      fontFamily: "sans-serif",
                      border: "1px solid #737373",
                      borderRadius: "4px",
                      color: "#737373",
                    }}
                    culture="en"
                    min={
                      (iterations.startDate &&
                        new Date(iterations.startDate)) ||
                      new Date()
                    }
                    name="endDate"
                    onChange={(e) => handleDateChange(e, "endDate")}
                    value={iterations.endDate && new Date(iterations.endDate)}
                    disabled={editScreen}
                  />
                </div>
              </GridItem>
              <GridItem size={"1/2"}>
                <Checkbox
                  name="welcomeIteration"
                  label="Enable Welcome Offer"
                  checked={iterations.checked}
                  onChange={handleCheckboxChange}
                />
              </GridItem>
              <GridItem size="1/2" />
              <GridItem size="1/2">
                <ButtonGroupWrapper>
                  <ButtonGroupSecondary>
                    <OutlinedButton
                      type="button"
                      size="small"
                      onClick={() => navigate("/marketing-events")}
                    >
                      Cancel
                    </OutlinedButton>
                    &nbsp;&nbsp;
                    <FilledButton
                      primary
                      size="small"
                      type="submit"
                      onClick={handleCreateIterations}
                      disabled={enableCreate}
                    >
                      {editScreen ? "Update" : "Create"}
                    </FilledButton>
                  </ButtonGroupSecondary>
                </ButtonGroupWrapper>
              </GridItem>
            </GridItem>
          </GridItem>
        </GridWrapper>
      </Card>
      {loading && (
        <ProgressIndicator
          page
          style={{ position: "absolute", zIndex: 10 }}
          loading
          preventFocus
        >
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      )}
    </React.Fragment>
  );
};

export default MarketingEventIterations;
