import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Table } from "semantic-ui-react";

import LedgerClient from "../../services/LedgerClient";
import { LoadingState } from "../../services/http";
import TransactionRow from "./TransactionRow";

const ledgerClient = new LedgerClient();

class TransactionsView extends Component {
  static propTypes = {
    cardNumber: PropTypes.string.isRequired,
  };

  state = {
    transactions: [],
    loadingState: LoadingState.PENDING,
    offset: 0,
    hasAllTransactions: false,
    limit: 20,
  };

  constructor() {
    super();

    this.getTransactions = this.getTransactions.bind(this);
  }

  componentDidMount() {
    this.getTransactions();
  }

  getTransactions() {
    const collectorID = this.props.cardNumber.substr(
      this.props.cardNumber.length - 11
    );
    ledgerClient
      .getTransactions(collectorID, this.state.offset, this.state.limit)
      .then(({ data }) =>
        this.setState((prevState) => {
          return {
            hasAllTransactions: data.offset + this.state.limit >= data.total,
            offset: data.offset + this.state.limit,
            transactions: !!data.items
              ? prevState.transactions.concat(data.items)
              : prevState.transactions,
            loadingState: LoadingState.SUCCESS,
          };
        })
      )
      .catch((e) => {
        console.log(e);
        this.setState({
          loadingState: LoadingState.FAILED,
        });
      });
  }

  render() {
    let content;

    switch (this.state.loadingState) {
      case LoadingState.SUCCESS:
        content =
          this.state.transactions.length > 0 ? (
            <div>
              <Table celled striped basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Store</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.transactions.map((row, i) => {
                    return <TransactionRow key={i} transaction={row} />;
                  })}
                </Table.Body>
              </Table>
              {!this.state.hasAllTransactions ? (
                <Button onClick={this.getTransactions} content="Load more" />
              ) : null}
            </div>
          ) : (
            <span>No transactions.</span>
          );
        break;
      case LoadingState.PENDING:
        content = "Loading transactions...";
        break;
      default:
        content = "Failed to load transactions.";
        break;
    }

    return <div>{content}</div>;
  }
}

export default TransactionsView;
