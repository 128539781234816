import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ProgressBar, ProgressIndicator } from "@jsluna/progress";

import { SuccessToastNotification } from "../../common/helpers/Notifications/SuccessToastNotification";
import { BarcodeBlocklistsClient } from "../../services/BarcodeBlocklistsClient";
import BarcodeBlocklistsEditor from "./BarcodeBlocklistsEditor";

const apiClient = new BarcodeBlocklistsClient();

const EditBarcodeBlocklist = () => {
  const { barcode } = useParams();
  const navigate = useNavigate();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const [loading, setLoading] = useState(false);
  const [apiErrorText, setApiErrorText] = useState("");

  const onSave = async (requestBody) => {
    setLoading(true);
    try {
      const response = await apiClient.editBarcode(barcode, requestBody);
      if (response.status !== 200) {
        throw new Error(response);
      }
      SuccessToastNotification(
        `Edited barcode blocklist "${requestBody.name}" with number: ${barcode}`
      );
      setLoading(false);
      navigate("/barcodeblocklists");
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText(
        "An error occurred when saving the blocked barcode. " + message
      );
      setLoading(false);
    }
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      const response = await apiClient.deleteBarcode(barcode);
      if (response.status !== 200) {
        throw new Error(response);
      }
      SuccessToastNotification(
        `Successfully deleted blocked barcode ${barcode}`
      );
      navigate("/barcodeblocklists");
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText("Failed to delete blocked barcode. " + message);
    } finally {
      setLoading(false);
    }
  };

  // On mount of component, load the data for the barcode
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const response = await apiClient.getBarcodeDetails(barcode);
        if (response.status !== 200) {
          throw new Error(response);
        }
        setInitialValues(response.data);
      } catch (err) {
        console.error(err);
        const message = err?.response?.data?.description ?? "";
        setApiErrorText("Failed to load blocked barcode. " + message);
      } finally {
        setInitialLoadComplete(true);
      }
    };
    loadInitialData();
  }, [barcode]);
  // if the barcode in the path changes, then this will re-run, but it should remount the component anyway.

  return !initialLoadComplete ? (
    <ProgressIndicator page loading preventFocus>
      <ProgressBar color="light" />
      Loading...
    </ProgressIndicator>
  ) : (
    <BarcodeBlocklistsEditor
      loading={loading}
      apiErrorText={apiErrorText}
      onSave={onSave}
      initialValues={initialValues}
      barcodeNumber={barcode}
      onDelete={onDelete}
    />
  );
};

export default EditBarcodeBlocklist;
