import { Header, HttpClient, MediaType, Method, managementApi } from "./http";

export class MarketingEventClient extends HttpClient {
  fetchMarketingEvents(page, size, expired) {
    return this.request(
      Method.GET,
      managementApi(
        `/marketing-event?page=${page}&size=${size}&expired=${expired}`
      )
    );
  }

  fetchMarketingEventsIterations(page, size, expired) {
    return this.request(
      Method.GET,
      managementApi(
        `/marketing-event/iterations?page=${page}&size=${size}&expired=${expired}`
      )
    );
  }

  createMarketingEvent(eventDetails) {
    return this.request(
      Method.POST,
      managementApi("/marketing-event"),
      eventDetails
    );
  }

  createMarketingEventIterations(eventDetails) {
    return this.request(
      Method.POST,
      managementApi("/marketing-event/iteration"),
      eventDetails
    );
  }

  editMarketingEvent(eventDetails) {
    return this.request(
      Method.PUT,
      managementApi("/marketing-event"),
      eventDetails
    );
  }

  addTrackersToMarketingEvent(marketingEventName, eventBuilders) {
    return this.request(
      Method.POST,
      managementApi(`/marketing-event/${marketingEventName}/trackers`),
      eventBuilders
    );
  }

  addOptionsToMarketingEvent(marketingEventName, optionBuilders) {
    return this.request(
      Method.POST,
      managementApi(
        `/marketing-event/${marketingEventName}/redemption-options`
      ),
      optionBuilders
    );
  }

  addUserGroupToMarketingEvent(marketingEventName, eventAudience) {
    return this.request(
      Method.POST,
      managementApi(
        `/marketing-event/${marketingEventName}/usergroup/${eventAudience}`
      )
    );
  }

  addUATUserGroupToMarketingEvent(marketingEventId, userGroupName) {
    return this.request(
      Method.POST,
      managementApi(
        `/marketing-event/${marketingEventId}/uat-usergroup/${userGroupName}`
      )
    );
  }

  createUserGroup(userGroupName) {
    return this.request(
      Method.POST,
      managementApi("/marketing-event/user-group"),
      { name: userGroupName }
    );
  }

  createUserGroupWithAspireTable(Payload) {
    return this.request(
      Method.POST,
      managementApi("/marketing-event/user-group"),
      Payload
    );
  }

  getUserGroup() {
    return this.request(
      Method.GET,
      managementApi("/marketing-event/user-group")
    );
  }

  addCsvToUserGroup(userGroupName, uploadedFile) {
    return this.request(
      Method.POST,
      managementApi(`/marketing-event/user-group/${userGroupName}/add-data`),
      uploadedFile,
      {
        [Header.CONTENT_TYPE]: MediaType.MultipartFormData,
      }
    );
  }

  deleteMarketingEvent(eventID) {
    return this.request(
      Method.DELETE,
      managementApi(`/marketing-event/${eventID}`)
    );
  }

  deleteMarketingEventIteration(iterationID) {
    return this.request(
      Method.DELETE,
      managementApi(`/marketing-event/iteration/${iterationID}`)
    );
  }

  commitMarketingEvent(eventID) {
    return this.request(
      Method.POST,
      managementApi(`/marketing-event/${eventID}/commit`)
    );
  }

  ingestMarketingEventIteration(iterationId) {
    return this.request(
      Method.PUT,
      managementApi(`/marketing-event/${iterationId}/ingestUser`)
    );
  }

  assignUserGroupToIteration(iterationId, userGroupName) {
    return this.request(
      Method.POST,
      managementApi(
        `/marketing-event/${iterationId}/usergroup/${userGroupName}`
      )
    );
  }

  deleteTrackersFromMarketingEvent(trackerID) {
    return this.request(
      Method.DELETE,
      managementApi(`/marketing-event/tracker/${trackerID}`)
    );
  }

  deleteOptionsFromMarketingEvent(optionID) {
    return this.request(
      Method.DELETE,
      managementApi(`/marketing-event/redemption-options/${optionID}`)
    );
  }

  createUIOptions(uiContext, uiOptions) {
    return this.request(
      Method.POST,
      managementApi(`/marketing-event/ui-options/${uiContext}`),
      uiOptions
    );
  }

  createUIContents(uiContents) {
    return this.request(
      Method.POST,
      managementApi(`/marketing-event/ui-content`),
      uiContents
    );
  }

  getUiOptions(uiContext) {
    return this.request(
      Method.GET,
      managementApi(`/marketing-event/ui-options/${uiContext}`)
    );
  }

  getUiOptionsByME(uiOptions, id) {
    return this.request(
      Method.GET,
      managementApi(`/marketing-event/ui-options/${uiOptions}/${id}`)
    );
  }

  getUiContent(coreLogicType) {
    return this.request(
      Method.GET,
      managementApi(`/marketing-event/ui-content/${coreLogicType}`)
    );
  }

  assignUIContent(contentId, marketingEventId) {
    return this.request(
      Method.POST,
      managementApi(
        `/marketing-event/${contentId}/assign-ui-content/${marketingEventId}`
      )
    );
  }

  assignUIOptions(uiContext, uiOptions) {
    return this.request(
      Method.POST,
      managementApi(`/marketing-event/assign-ui-options/${uiContext}`),
      uiOptions
    );
  }

  addPriorityToME(priority, id) {
    return this.request(
      Method.PATCH,
      managementApi(
        `/marketing-event?priority=${priority}&marketingEventId=${id}`
      )
    );
  }
}

export default MarketingEventClient;
