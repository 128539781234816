import React from "react";

import { Modal, ModalHeading } from "@jsluna/modal";

const Modals = (props) => {
  return (
    <Modal
      fullScreen
      restrictClose
      className={props.className}
      open={props.showModal}
      headingId="dialog-modal"
    >
      <ModalHeading element="h3" className={props.headerClassName}>
        {props.modalTitle}
      </ModalHeading>
      {props.modalItems}
    </Modal>
  );
};

export default Modals;
