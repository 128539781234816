import React from "react";

import { Label } from "@jsluna/form";

import ArrowRight from "../../assets_V2/ArrowRight.png";

const MarketingEventOptionsPreview = ({ optionArrVal, eventDetails }) => {
  return (
    <div className="iphone-frame">
      <div className="iphone">
        <div className="iphone-content">
          <div>
            <div className="ln-u-margin-top ln-u-margin-bottom*2">
              <Label className="custom-label">{eventDetails.name}</Label>
            </div>
            {optionArrVal &&
              optionArrVal.length > 0 &&
              optionArrVal
                .sort((a, b) => a.trackerOrder - b.trackerOrder)
                .map((task, index) => (
                  <div className="task-options-container ln-u-margin-top">
                    <div className="task-header">{task.optionName}</div>
                    <div className="task">
                      <span>
                        <p className="custom-title2">
                          {task.redemptionPointsValue &&
                            task.redemptionPointsValue}{" "}
                          <br /> {"Points"}
                        </p>
                      </span>
                      <span>
                        <img
                          className="arrrow-img"
                          src={ArrowRight}
                          alt="arrow"
                        />
                      </span>
                      <span>
                        <b>{`£ ${
                          task.redemptionPointsValue
                            ? task.redemptionPointsValue / task.pointsValue
                            : 0
                        }`}</b>
                      </span>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingEventOptionsPreview;
