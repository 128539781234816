import { HttpClient, Method, managementApi } from "./http";

class SessionClient extends HttpClient {
  createSession(access_token) {
    return this.request(Method.POST, managementApi("/sso-session/"), {
      access_token: access_token,
    });
  }
}

export default SessionClient;
