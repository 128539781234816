/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { IconButton } from "@jsluna/button";
import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";
import {
  AwardWinning,
  Comment,
  Delete,
  Edit,
  GridView,
  ListView,
  Plus,
  Upload,
} from "@jsluna/icons";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";
import { TabLink, TabPanel, Tabs } from "@jsluna/tabs";

import peopleIcon from "../../assets_V2/people-fill.svg";
import {
  ButtonDropdown,
  ButtonDropdownItem,
} from "../../common/components/ButtonDropdown";
import { InlineGroup } from "../../common/components/InlineGroup";
import { PageHeader } from "../../common/components/PageHeader";
import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import MarketingEventClient from "../../services/MarketingEventClient";
import { Expired, PageSize } from "./Constants";
import DashboardTable from "./DashboardTable";
import IterationsTable from "./IterationsTable";
import "./MarketingEvents.scss";
import Modals from "./Modals";
import UserGroupDialogModal from "./UserGroupDialogModal";

const marketingEventClient = new MarketingEventClient();

const MarketingEventsDashboard = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [marketingEventsList, setMarketingEventsList] = useState();
  const [marketingEventsIterationsList, setMarketingEventsIterationsList] =
    useState();
  const [loading, setLoading] = useState(true);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [openCommitConfirmDialog, setOpenCommitConfirmDialog] = useState(false);
  const [openIngestConfirmDialog, setOpenIngestConfirmDialog] = useState(false);
  const [openDuplicateConfirmDialog, setOpenDuplicateConfirmDialog] =
    useState(false);
  const [selectedRowMap, setSelectedRowMap] = useState(new Map());

  const [isCommitEnable, setIsCommitEnable] = useState(true);
  const [isDeleteEnable, setIsDeleteEnable] = useState(true);
  const [isIngestEnable, setIsIngestEnable] = useState(false);

  const [pageRefresh, setPageRefresh] = useState(false);
  const [actionConfirmedValue, setActionConfirmedValue] = useState(undefined);
  const [activeTab, setActiveTab] = useState(0);

  const numOfSelected = selectedRowMap && selectedRowMap.size;
  const isMEEditor = HasRequiredRoleAccess("marketing-events");

  useEffect(() => {
    loadMarketingEvents(1);
    loadMarketingEventsIterations(1);
  }, []);

  useEffect(() => {
    if (pageRefresh) {
      loadMarketingEvents(currentPage ? currentPage : 1).then(() =>
        setLoading(false)
      );
      loadMarketingEventsIterations(currentPage ? currentPage : 1).then(() =>
        setLoading(false)
      );
    }
    // eslint-disable-next-line
  }, [pageRefresh]);

  useEffect(() => {
    setPageRefresh(false);
    setIsDeleteEnable(true);
    setIsCommitEnable(true);
    setIsIngestEnable(false);

    if (selectedRowMap.size > 0) {
      selectedRowMap.forEach((value) => {
        if (
          value &&
          value.data &&
          value.data.status &&
          value.data.status.toUpperCase() === "CREATED"
        ) {
          setIsCommitEnable(false);
          setIsDeleteEnable(false);
        }

        if (
          (value &&
            value.data &&
            value.data.status &&
            value.data.status.toUpperCase() === "LIVE") ||
          value.data.status === "COMMITTED"
        ) {
          setIsDeleteEnable(true);
          setIsCommitEnable(true);
        }

        if (
          value && value.data && value.data.ingestionStatus
            ? value.data.ingestionStatus.toUpperCase() === "NOT_STARTED"
            : !value.data.hasOwnProperty("ingestionStatus")
        ) {
          setIsIngestEnable(false);
        }

        if (
          value?.data?.ingestionState &&
          value.data.ingestionState.toUpperCase() === "DONE"
        ) {
          setIsIngestEnable(true);
        }
      });
    }
  }, [selectedRowMap]);

  useEffect(() => {
    if (activeTab === 0 || 1) {
      setSelectedRowMap(new Map());
    }
    // eslint-disable-next-line
  }, [activeTab]);

  const loadMarketingEvents = async (page) => {
    setLoading(true);
    await marketingEventClient
      .fetchMarketingEvents(page - 1, PageSize, Expired)
      .then(({ data }) => {
        setMarketingEventsList(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const loadMarketingEventsIterations = async (page) => {
    setLoading(true);
    await marketingEventClient
      .fetchMarketingEventsIterations(page - 1, PageSize, Expired)
      .then(({ data }) => {
        setMarketingEventsIterationsList(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const onPaginationChange = (page, e) => {
    e.preventDefault();
    setCurrentPage(page);
    loadMarketingEvents(page);
  };

  const onIterationPaginationChange = (page, e) => {
    e.preventDefault();
    setCurrentPage(page);
    loadMarketingEventsIterations(page);
  };

  const handleSelectRow = (selected, row) => {
    numOfSelected > 0 && selectedRowMap.clear();
    if (selected) {
      if (numOfSelected === 0) {
        setSelectedRowMap(
          new Map().set(row.id, {
            data: row,
            selected,
          })
        );
      }
      setSelectedRowMap((prev) =>
        new Map(prev).set(row.id, {
          data: row,
          selected,
        })
      );
    } else {
      if (numOfSelected === 1) setSelectedRowMap(new Map());

      setSelectedRowMap((prev) => {
        const newState = new Map(prev);
        newState.delete(row.id);
        return newState;
      });
    }
  };

  const getMarketingEventName = () => {
    let marketingEventName = undefined;

    selectedRowMap.forEach((value) => {
      marketingEventName = value && value.data.id;
    });

    return marketingEventName;
  };

  const deleteMarketingEvent = async (eventName) => {
    await marketingEventClient
      .deleteMarketingEvent(eventName)
      .then((res) => {
        if (res.status === 200) {
          toast.success(`Marketing event ${eventName} deleted successfully`);
          setPageRefresh(true);
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        toast.error(err.response.statusText);
      });
  };

  const deleteMarketingEventIteration = async (eventName) => {
    await marketingEventClient
      .deleteMarketingEventIteration(eventName)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            `Marketing event iteration ${eventName} deleted successfully`
          );
          setPageRefresh(true);
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        toast.error(err.response.statusText);
      });
  };

  const commitMarketingEvent = async (eventName) => {
    await marketingEventClient
      .commitMarketingEvent(eventName)
      .then((res) => {
        if (res.status === 200) {
          toast.success(`Marketing event ${eventName} committed successfully`);
          setPageRefresh(true);
        }
      })
      .catch((err) => {
        toast.error(err.response.statusText);
      });
  };

  // const ingestMarketingEvent = async (eventID) => {
  //   await marketingEventClient
  //     .ingestMarketingEvent(eventID)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         toast.success(`Marketing event ${eventID} ingested successfully`);
  //         setPageRefresh(true);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.statusText);
  //     });
  // };

  const ingestMarketingEventIteration = async (iterationId) => {
    await marketingEventClient
      .ingestMarketingEventIteration(iterationId)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            `Marketing event iteration ${iterationId} ingested successfully`
          );
          setPageRefresh(true);
        }
      })
      .catch((err) => {
        toast.error(err.response.statusText);
      });
  };

  const duplicateMarketingEvent = (eventName) => {
    let eventID = eventName;
    navigate(`/marketing-events/edit/new`, {
      state: {
        editMarketingEvent: {
          enableEdit: false,
          isDuplicate: true,
          rowData: marketingEventsList.content.filter((data) =>
            data.id.includes(eventID)
          ),
        },
      },
    });
  };

  const selectActions = (value) => {
    const selectedValue = value;
    setActionConfirmedValue(value);
    if (selectedValue) {
      if (selectedValue === "delete") {
        setOpenDeleteConfirmDialog(true);
      }
      if (selectedValue === "commit") {
        setOpenCommitConfirmDialog(true);
      }
      if (selectedValue === "ingest") {
        setOpenIngestConfirmDialog(true);
      }
      if (selectedValue === "duplicate") {
        setOpenDuplicateConfirmDialog(true);
      }
    } else {
      toast.error("error occured while selecting value");
    }
  };

  const confirmAction = async () => {
    const marketingEventName = getMarketingEventName();
    if (actionConfirmedValue === "delete" && activeTab === 1) {
      await deleteMarketingEvent(marketingEventName);
    }
    if (actionConfirmedValue === "delete" && activeTab === 0) {
      await deleteMarketingEventIteration(marketingEventName);
    }
    if (actionConfirmedValue === "commit") {
      await commitMarketingEvent(marketingEventName);
    }
    // if (actionConfirmedValue === "ingest" && activeTab === 1) {
    //   await ingestMarketingEvent(marketingEventName);
    // }
    if (actionConfirmedValue === "ingest" && activeTab === 0) {
      await ingestMarketingEventIteration(marketingEventName);
    }
    if (actionConfirmedValue === "duplicate") {
      duplicateMarketingEvent(marketingEventName);
    }

    setOpenDeleteConfirmDialog(false);
    setOpenCommitConfirmDialog(false);
    setOpenIngestConfirmDialog(false);
    setOpenDuplicateConfirmDialog(false);
    setActionConfirmedValue(undefined);
    numOfSelected > 0 && setSelectedRowMap(new Map());
  };

  const routeToUserGroup = () => {
    navigate("/marketing-events/user-group");
  };

  const routeToUiOptions = () => {
    navigate("/marketing-events/ui-options");
  };

  const routeToUiContents = () => {
    navigate("/marketing-events/ui-contents");
  };

  const routeToIteraions = () => {
    navigate("/marketing-events/iterations");
  };

  const resetValues = () => {
    setOpenDeleteConfirmDialog(false);
    setOpenCommitConfirmDialog(false);
    setOpenIngestConfirmDialog(false);
    setOpenDuplicateConfirmDialog(false);
    setActionConfirmedValue(undefined);
  };

  return (
    <>
      <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
        <GridWrapper matrix>
          <GridItem size="1/1">
            <PageHeader>
              <AwardWinning />
              Marketing Events
            </PageHeader>
          </GridItem>
          <GridItem size="4/5">
            <InlineGroup>
              <IconButton
                variant="filled"
                element="button"
                label="Create Iterations"
                onClick={() => routeToIteraions()}
                disabled={!isMEEditor}
              >
                <Plus />
              </IconButton>
              <IconButton
                variant="filled"
                element="button"
                label="Create Marketing Event"
                onClick={() => navigate("edit/new")}
                disabled={!isMEEditor}
              >
                <Plus />
              </IconButton>
              <IconButton
                variant="outlined"
                element="button"
                label="Create User Group"
                onClick={() => routeToUserGroup()}
                disabled={!isMEEditor}
              >
                <img className="eyeIcon" src={peopleIcon} alt="people Icon" />
              </IconButton>
              <IconButton
                variant="outlined"
                element="button"
                label="Create UI Option"
                onClick={() => routeToUiOptions()}
                disabled={!isMEEditor}
              >
                <GridView />
              </IconButton>
              <IconButton
                variant="outlined"
                element="button"
                label="Create UI Content"
                onClick={() => routeToUiContents()}
                disabled={!isMEEditor}
              >
                <ListView />
              </IconButton>
            </InlineGroup>
          </GridItem>
          <GridItem size="1/5">
            <InlineGroup alignEnd>
              <ButtonDropdown
                label={"Event Actions"}
                variant="outlined"
                style={{ float: "right" }}
                disabled={!isMEEditor || numOfSelected === 0}
              >
                {activeTab === 1 && isCommitEnable && (
                  <ButtonDropdownItem onClick={() => selectActions("commit")}>
                    <Comment />
                    &nbsp;&nbsp; Commit
                  </ButtonDropdownItem>
                )}
                {!isIngestEnable && activeTab === 0 && (
                  <ButtonDropdownItem onClick={() => selectActions("ingest")}>
                    <Upload />
                    &nbsp;&nbsp; Ingest
                  </ButtonDropdownItem>
                )}
                {activeTab === 1 && (
                  <ButtonDropdownItem
                    onClick={() => selectActions("duplicate")}
                  >
                    <Edit />
                    &nbsp;&nbsp; Duplicate
                  </ButtonDropdownItem>
                )}
                {isDeleteEnable && (
                  <ButtonDropdownItem onClick={() => selectActions("delete")}>
                    <Delete />
                    &nbsp;&nbsp; Delete
                  </ButtonDropdownItem>
                )}
              </ButtonDropdown>
            </InlineGroup>
          </GridItem>

          <GridItem size={{ xs: "1/1" }}>
            <Tabs type="fill">
              <TabLink active={activeTab === 0} onClick={() => setActiveTab(0)}>
                Iterations Dashboard
              </TabLink>
              <TabLink active={activeTab === 1} onClick={() => setActiveTab(1)}>
                Marketing Events Dashboard
              </TabLink>
            </Tabs>
            <TabPanel>
              {activeTab === 0 && (
                <Card style={{ padding: "1.5rem 0" }}>
                  <IterationsTable
                    marketingEventsIterationsList={
                      marketingEventsIterationsList
                    }
                    selectedRowMap={selectedRowMap}
                    handleSelectRow={handleSelectRow}
                    onPaginationChange={onIterationPaginationChange}
                    currentPage={currentPage}
                  />
                </Card>
              )}
              {activeTab === 1 && (
                <Card style={{ padding: "1.5rem 0" }}>
                  <DashboardTable
                    marketingEventsList={marketingEventsList}
                    selectedRowMap={selectedRowMap}
                    handleSelectRow={handleSelectRow}
                    onPaginationChange={onPaginationChange}
                    currentPage={currentPage}
                  />
                </Card>
              )}
            </TabPanel>
          </GridItem>
        </GridWrapper>
      </Card>
      {(openDeleteConfirmDialog ||
        openCommitConfirmDialog ||
        openIngestConfirmDialog ||
        openDuplicateConfirmDialog) && (
        <Modals
          showModal={
            openDeleteConfirmDialog ||
            openCommitConfirmDialog ||
            openIngestConfirmDialog ||
            openDuplicateConfirmDialog
          }
          className="custom-modal"
          modalTitle={
            openDeleteConfirmDialog
              ? "Are you sure to Delete?"
              : openCommitConfirmDialog
              ? "Are you sure to Commit?"
              : openIngestConfirmDialog
              ? "Are you sure to Ingest?"
              : "Are you sure to Duplicate?"
          }
          modalItems={[
            <TabPanel>
              <UserGroupDialogModal
                openDeleteConfirmDialog={openDeleteConfirmDialog}
                openCommitConfirmDialog={openCommitConfirmDialog}
                openIngestConfirmDialog={openIngestConfirmDialog}
                openDuplicateConfirmDialog={openDuplicateConfirmDialog}
                confirmAction={confirmAction}
                resetValues={resetValues}
              />
            </TabPanel>,
          ]}
        />
      )}

      {loading && (
        <ProgressIndicator page loading preventFocus>
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      )}
    </>
  );
};

export default MarketingEventsDashboard;
