import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { TabLink, TabPanel, Tabs } from "@jsluna/tabs";

import FoodOfferAllocateView from "./FoodOfferAllocateView";
import GolOccOfferAllocateView from "./GolOccOfferAllocateView";
import NectarPricesOfferAllocateView from "./NectarPricesOfferAllocateView";

const AllocateOfferView = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("food");
  const [externalHandle, setExternalHandle] = useState("");
  // eslint-disable-next-line
  const [walletId, setWalletId] = useState("");

  useEffect(() => {
    if (location.state && location.state.CsData) {
      const EH = location.state.CsData.externalHandle;
      const WI = location.state.CsData.walletId;
      setExternalHandle(EH);
      setWalletId(WI);
    }
  }, [location.state]);

  const panes = [
    { name: "Food", key: "food" },
    { name: "Nectar Prices", key: "nectarprices" },
    { name: "GOL OCC", key: "gol-occ" },
  ];

  return (
    <Card style={{ width: "100%" }}>
      <GridWrapper>
        <GridItem>
          <div>
            <Tabs type="pill">
              {panes.map((tab) => (
                <TabLink
                  key={tab.key}
                  onClick={() => setActiveItem(tab.key)}
                  active={activeItem === tab.key}
                >
                  {tab.name}
                </TabLink>
              ))}
            </Tabs>
            <TabPanel>
              <Card
                style={{
                  border: "1px solid #d8d8d8",
                  borderRadius: "3px",
                  margin: "1rem 0",
                }}
              >
                {activeItem === "food" && (
                  <FoodOfferAllocateView eHandle={externalHandle} />
                )}
                {activeItem === "nectarprices" && (
                  <NectarPricesOfferAllocateView eHandle={externalHandle} />
                )}
                {activeItem === "gol-occ" && <GolOccOfferAllocateView />}
              </Card>
            </TabPanel>
          </div>
        </GridItem>
      </GridWrapper>
    </Card>
  );
};

export default AllocateOfferView;
