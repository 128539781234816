import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-widgets/dist/css/react-widgets.css";
import "semantic-ui-css/semantic.min.css";

import Router from "./Router";
import { loginRequest, msalConfig } from "./config";
import { PaginationProvider } from "./contexts/PaginationContext";
import { SessionProvider } from "./contexts/SessionContext";
import "./localisation";
import "./main.scss";

let ReactGA = require("react-ga");
ReactGA.initialize("UA-100171857-1");

function logPageView() {
  let currentLocation = window.location.pathname + window.location.search;

  ReactGA.set({ page: currentLocation });
  ReactGA.pageview(currentLocation);
}
// Create a root for the application
const root = ReactDOM.createRoot(document.getElementById("root"));

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <SessionProvider>
        <PaginationProvider>
          <Router onUpdate={logPageView} />
        </PaginationProvider>
      </SessionProvider>
    </MsalAuthenticationTemplate>
  </MsalProvider>
);
