import React from "react";

import { IconButton, TextButton } from "@jsluna/button";
import { TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Plus } from "@jsluna/icons";
import { Accordion, AccordionItem } from "@jsluna/react";

import { stringifyNumber } from "./Constants";

const EventOptions = ({
  addOption,
  removeOption,
  handleAddOptionVal,
  optionArrVal,
}) => {
  return (
    <GridWrapper>
      <GridItem size="1/4">
        <IconButton
          size="small"
          variant="outlined"
          circle={false}
          element="button"
          label="Add Options"
          onClick={() => addOption()}
        >
          <Plus />
        </IconButton>
      </GridItem>
      <GridItem>
        {optionArrVal &&
          optionArrVal.length > 0 &&
          optionArrVal.map((option, index) => (
            <div className="ln-u-margin-top*3">
              <Accordion titleElement="h3">
                <AccordionItem
                  defaultOpen
                  id={`accordion-item-${stringifyNumber(index)}`}
                  title={`${stringifyNumber(index)} option`}
                  data-testid={`${stringifyNumber(index)}-item`}
                  titleElement="filled"
                  className="add-task-accordion-item"
                >
                  <GridWrapper>
                    <GridItem size={"1/2"}>
                      <TextInputField
                        name="optionName"
                        label="Redemption Name"
                        placeholder="option name"
                        value={option.optionName}
                        onChange={(e) => handleAddOptionVal(e, index)}
                      />
                    </GridItem>
                    <GridItem size={"1/2"}>
                      <TextInputField
                        name="redemptionPointsValue"
                        label="Points to redeem"
                        placeholder="eg:- 1000"
                        value={option.redemptionPointsValue}
                        onChange={(e) => handleAddOptionVal(e, index)}
                        type="number"
                        min={0}
                      />
                    </GridItem>
                    <GridItem size={"1/2"}>
                      <TextInputField
                        name="pointsValue"
                        label="Points to value"
                        placeholder="eg:- 1000"
                        value={option.pointsValue}
                        onChange={(e) => handleAddOptionVal(e, index)}
                        type="number"
                        min={0}
                      />
                    </GridItem>
                    <GridItem className="padding-left-none" size={"1"}>
                      <TextButton onClick={() => removeOption(index)}>
                        Remove Option
                      </TextButton>
                    </GridItem>
                  </GridWrapper>
                </AccordionItem>
              </Accordion>
            </div>
          ))}
      </GridItem>
    </GridWrapper>
  );
};

export default EventOptions;
