const bulkAsyncOperation = async (createPromise, data) => {
  const responses = await Promise.all(
    data.map(async (item) => {
      try {
        await createPromise(item);
        return "succeeded";
      } catch (err) {
        console.error(err);
        return "failed";
      }
    })
  );
  return {
    total: responses.length,
    failed: responses.filter((n) => n === "failed").length,
  };
};

export default bulkAsyncOperation;
