import React, { useState } from "react";

import { SelectField, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { CoreLogicType } from "./Constants";

const EventDetails = ({ handleChange, eventDetails, editScreen }) => {
  const [eventIdSelectedError, setEventIdSelectedError] = useState(false);

  const [
    eventMaxRedeemablePointsSelectedError,
    setEventMaxRedeemablePointsSelectedError,
  ] = useState(false);
  const [
    eventVoucherExpiryInDaysSelectedError,
    setEventVoucherExpiryInDaysSelectedError,
  ] = useState(false);

  const onBlurEventId = (event) => {
    setEventIdSelectedError(false);
    if (event.target.value.trim() === "") setEventIdSelectedError(true);
  };

  const onBlurTextField = (event, name) => {
    if (name === "maxRedeemablePoints") {
      setEventMaxRedeemablePointsSelectedError(false);
      if (event.target.value.trim() === "")
        setEventMaxRedeemablePointsSelectedError(true);
    } else if (name === "voucherExpiryInDays") {
      setEventVoucherExpiryInDaysSelectedError(false);
      if (event.target.value.trim() === "")
        setEventVoucherExpiryInDaysSelectedError(true);
    }
  };

  return (
    <GridWrapper className="ln-u-margin-top*3">
      <GridItem size="1/2">
        <TextInputField
          name="id"
          label="Event id"
          placeholder="eg:- CFC-2023"
          value={eventDetails.id}
          onChange={handleChange}
          onBlur={(e) => onBlurEventId(e)}
          error={eventIdSelectedError && !eventDetails.id && "Required"}
          disabled={editScreen}
        />
      </GridItem>
      <GridItem size="1/2">
        <TextInputField
          name="description"
          label="Description"
          value={eventDetails.description}
          onChange={handleChange}
        />
      </GridItem>
      <GridItem size="1/2">
        <TextInputField
          name="costCentre"
          label="Cost centre"
          placeholder="Eg: 123"
          value={eventDetails.costCentre}
          onChange={handleChange}
        />
      </GridItem>
      <GridItem size={"1/2"}>
        <TextInputField
          name="summaryDuration"
          label="Summary Duration In Days"
          placeholder="eg:- 30"
          type="number"
          min={1}
          value={eventDetails.summaryDuration}
          onChange={handleChange}
        />
      </GridItem>
      <GridItem size="1/2">
        <SelectField
          name="coreLogicType"
          label="Event type"
          options={CoreLogicType}
          value={eventDetails.coreLogicType}
          onChange={handleChange}
          disabled={editScreen}
        />
      </GridItem>
      <GridItem size="1/2">
        <TextInputField
          name="minSupportedAppVersion"
          label="Min App Version"
          value={eventDetails.minSupportedAppVersion}
          onChange={handleChange}
          placeholder="1.0"
        />
      </GridItem>
      <React.Fragment>
        <GridItem size="1/2">
          <TextInputField
            name="offerCode"
            label="Offer Code"
            value={eventDetails.offerCode}
            onChange={handleChange}
            placeholder="FREQUENCY_DRIVER / FRUIT_AND_VEG"
          />
        </GridItem>
        <GridItem size="1/2">
          <TextInputField
            name="priority"
            label="Priority"
            type="number"
            value={eventDetails.priority}
            onChange={handleChange}
          />
        </GridItem>
      </React.Fragment>
      {eventDetails.coreLogicType === "REDEMPTION" ? (
        <React.Fragment>
          <GridItem size="1/2">
            <TextInputField
              name="maxRedeemablePoints"
              label="Maximun redeemable points"
              placeholder="eg:- 5000/10000"
              value={eventDetails.maxRedeemablePoints}
              onChange={handleChange}
              onBlur={(e) => onBlurTextField(e, "maxRedeemablePoints")}
              error={
                eventMaxRedeemablePointsSelectedError &&
                !eventDetails.maxRedeemablePoints &&
                "Required"
              }
              disabled={editScreen}
            />
          </GridItem>
          <GridItem size="1/2">
            <TextInputField
              name="voucherExpiryInDays"
              label="Voucher expiry in days"
              placeholder="eg:- 10/30"
              value={eventDetails.voucherExpiryInDays}
              onChange={handleChange}
              onBlur={(e) => onBlurTextField(e, "voucherExpiryInDays")}
              error={
                eventVoucherExpiryInDaysSelectedError &&
                !eventDetails.voucherExpiryInDays &&
                "Required"
              }
              disabled={editScreen}
            />
          </GridItem>
        </React.Fragment>
      ) : (
        eventDetails.coreLogicType === "INSTANT_REWARD" && (
          <GridItem size="1/2">
            <TextInputField
              name="instantRewardPoints"
              label="Instant reward points"
              placeholder="100"
              value={eventDetails.instantRewardPoints}
              onChange={handleChange}
            />
          </GridItem>
        )
      )}
    </GridWrapper>
  );
};

export default EventDetails;
