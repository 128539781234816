/* eslint-disable array-callback-return */
import React from "react";

import { Accordion, AccordionItem } from "@jsluna/accordion";
import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { InfoCircle } from "@jsluna/icons";

import { PageHeader } from "../../common/components/PageHeader";
import { faqs } from "./Constants";

const FaqView = () => {
  const { faq } = faqs;
  return (
    <>
      <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
        <GridWrapper matrix>
          <GridItem size="1/1">
            <PageHeader>
              <InfoCircle />
              FAQ for Management Portal
            </PageHeader>
          </GridItem>
        </GridWrapper>
        <GridWrapper
          matrix
          className="ln-u-margin-top*4"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <GridItem size="3/4">
            <Accordion titleElement="h3">
              {faq.map((item, index) => (
                <AccordionItem
                  id={`accordion-item-${index}`}
                  title={item.question}
                  data-testid={`${index}-item`}
                  key={index}
                >
                  <p
                    data-testid={`${index}-item-body`}
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </AccordionItem>
              ))}
            </Accordion>
          </GridItem>
        </GridWrapper>
      </Card>
    </>
  );
};

export default FaqView;
