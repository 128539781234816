import PropTypes from "prop-types";
import React, { Component } from "react";
import { Header, Image, Table } from "semantic-ui-react";

import CustomerClient from "../../services/CustomerClient";
import { LoadingState } from "../../services/http";

const customerClient = new CustomerClient();

class ExpiredOffersView extends Component {
  static propTypes = {
    customerId: PropTypes.string.isRequired,
  };

  state = {
    offers: [],
    loadingState: LoadingState.PENDING,
  };

  constructor() {
    super();

    this.getExpiredOffers = this.getExpiredOffers.bind(this);
  }

  componentDidMount() {
    this.getExpiredOffers();
  }

  getExpiredOffers() {
    customerClient
      .getExpiredOrUsedOffers(this.props.customerId)
      .then(({ data }) => {
        this.setState({
          offers: data.offers,
          loadingState: LoadingState.SUCCESS,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loadingState: LoadingState.FAILED,
        });
      });
  }

  static formattedDate(dateString) {
    let date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  render() {
    let content;

    switch (this.state.loadingState) {
      case LoadingState.SUCCESS:
        content =
          this.state.offers.length > 0 ? (
            <Table style={{ width: "100%" }} basic="very" celled collapsing>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Offer</Table.HeaderCell>
                  <Table.HeaderCell>Points</Table.HeaderCell>
                  <Table.HeaderCell>Valid From</Table.HeaderCell>
                  <Table.HeaderCell>Expired</Table.HeaderCell>
                  <Table.HeaderCell>State</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {this.state.offers.map((offer, key) => {
                  return (
                    <Table.Row key={key}>
                      <Table.Cell>
                        <Header as="h4">
                          <Image
                            src={offer.imgUrl}
                            shape="rounded"
                            size="mini"
                          />
                          <Header.Content>
                            {offer.title}
                            <Header.Subheader>
                              {offer.description}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>{offer.points}</Table.Cell>
                      <Table.Cell>
                        {ExpiredOffersView.formattedDate(offer.validFrom)}
                      </Table.Cell>
                      <Table.Cell>
                        {ExpiredOffersView.formattedDate(offer.expires)}
                      </Table.Cell>
                      <Table.Cell>{offer.state}</Table.Cell>
                      <Table.Cell>{offer.status}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          ) : (
            <span>No expired offers.</span>
          );
        break;
      case LoadingState.PENDING:
        content = "Loading expired offers...";
        break;
      default:
        content = "Failed to load expired offers.";
        break;
    }

    return <div>{content}</div>;
  }
}

export default ExpiredOffersView;
