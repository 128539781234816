export const validCampaignTypes = [
  { label: "Money Off", value: "MONEY_OFF" },
  { label: "Product", value: "PRODUCT" },
  { label: "Basket", value: "BASKET" },
  { label: "Points Multiplier", value: "POINTS_MULTIPLIER" },
  { label: "Sku-Limited Spend", value: "SKU_LIMITED_SPEND" },
  { label: "Mass Volume", value: "MASS_VOLUME" },
  { label: "Nectar Price", value: "NECTAR_PRICE" },
  { label: "Trigger", value: "TRIGGER" },
  { label: "Marketing Event", value: "MARKETING_EVENT" },
  { label: "Incentive", value: "INCENTIVE" },
  { label: "Sku Limited Spend", value: "SKU_LIMITED_SPEND" },
  { label: "Lifecycle", value: "LIFECYCLE" },
  { label: "Milestone", value: "MILESTONE" },
  { label: "Quest", value: "QUEST" },
];
